import { mocked } from '@/configs';
import { Constructor } from '@/type-utils';
import { RecommendationContext } from '@/configs/recommendations';
import Service from '../../../Service';
import MockService, {
  MockState,
  ServiceMock
} from '../../../isomorphic/MockService';

import type { DYService } from './DYService';
import { IDYReportConfiguration } from './schema/report/IDYReportConfiguration';
import { IDYChoiceConfiguration } from './schema/choice/IDYChoiceConfiguration';
import { IDYChooseVariationsResponse } from './schema/response/IDYChooseVariationsResponse';

export const MOCK_DIRECT_PRODUCT_LIST = {
  choices: [
    {
      id: 32144322,
      payload: {
        type: 'CUSTOM_JSON',
        data: {
          decisionID: 'homepage',
          props: {
            title: 'New Arrivals',
            productList: '1091409-CHRC,1125294-TDH,1112823-NAT,1091409-CHRC'
          }
        }
      }
    }
  ]
};

const PRODUCT_LIST = [
  {
    id: 32144322,
    payload: {
      type: 'CUSTOM_JSON',
      data: {
        productSku: '1091409-CHRC'
      }
    }
  },
  {
    id: 32144322,
    payload: {
      type: 'CUSTOM_JSON',
      data: {
        productSku: '1125294-TDH'
      }
    }
  },
  {
    id: 32312344,
    payload: {
      type: 'CUSTOM_JSON',
      data: {
        productSku: '1112823-NAT'
      }
    }
  },
  {
    id: 32312344,
    payload: {
      type: 'CUSTOM_JSON',
      data: {
        productSku: '1091409-CHRC'
      }
    }
  }
];

const initialState = {
  choices: [
    {
      id: 484687,
      name: 'decision-testExperience-2',
      type: 'DECISION',
      variations: [
        {
          id: 21831162,
          payload: {
            type: 'CUSTOM_JSON',
            data: {
              hello: 1
            }
          }
        }
      ],
      decisionId: 'decision-123'
    },
    {
      id: 843293,
      name: RecommendationContext.NotFoundPage,
      type: 'DECISION',
      variations: PRODUCT_LIST,
      decisionId: 'decision-123'
    },
    {
      id: 843294,
      name: RecommendationContext.HomePage,
      type: 'DECISION',
      variations: PRODUCT_LIST,
      decisionId: 'decision-1234'
    },
    {
      id: 843294,
      name: RecommendationContext.PDP,
      type: 'DECISION',
      variations: PRODUCT_LIST,
      decisionId: 'decision-1234'
    }
  ]
};

/**
 * Mock for DYService.
 */
export default class DYServiceMock extends ServiceMock<DYService> {
  protected _state: MockState;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): DYService {
    return MockService.getMockOf(this.service) as unknown as DYService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<DYService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    MockService.mockService(
      mocked.DYService,
      service,
      {
        chooseVariations: this.chooseVariations.bind(this),
        sendReport: this.sendReport.bind(this)
      },
      {},
      this.state
    );
  }

  /** @inheritdoc */
  public async chooseVariations(
    body: IDYChoiceConfiguration
  ): Promise<IDYChooseVariationsResponse> {
    const mockState =
      this.state.getState() as unknown as IDYChooseVariationsResponse;

    return Promise.resolve(mockState);
  }

  /** @inheritdoc */
  public async sendReport<R extends keyof IDYReportConfiguration>(
    report: R,
    reportBody: IDYReportConfiguration[R]
  ): Promise<void> {
    // Do nothing.
  }
}
