import { Constructor, Nullable } from '@/type-utils';
import { mocked } from '@/configs';

import Service from '@/services/Service';
import { UserModel } from '@/services/models/User';
import { MOCK_AUTHENTICATED_USER } from '@/services/models/User/mocks';

import {
  AuthenticatedUserModel,
  IAuthenticatedUserTokens,
  ILoginCredentials,
  ISignUpCredentials
} from '@/services/models/User/AuthenticatedUser';

import MockService, {
  MockState,
  ServiceMock
} from '@/services/isomorphic/MockService';

import type { UserService } from './UserService';

const initialState = {};

/** Mock implementation of the {@link UserService}. */
export default class UserServiceMock extends ServiceMock<UserService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): UserService {
    return MockService.getMockOf(this.service) as unknown as UserService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<UserService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.UserService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        isLoggedIn: async (): Promise<boolean> => {
          return true;
        },

        getCurrentUser: async (): Promise<Nullable<UserModel>> => {
          return AuthenticatedUserModel.from(MOCK_AUTHENTICATED_USER);
        },

        saveUserToSession: async (user: UserModel): Promise<void> => {},

        removeUserFromSession: async (): Promise<void> => {},

        signUp: async (credentials: ISignUpCredentials): Promise<void> => {},

        logIn: async (credentials: ILoginCredentials): Promise<UserModel> => {
          return UserModel.from(MOCK_AUTHENTICATED_USER);
        },

        refreshSession: async (
          email: string,
          refreshToken: string
        ): Promise<IAuthenticatedUserTokens> => {
          return MOCK_AUTHENTICATED_USER.tokens;
        },

        confirmUser: async (
          email: string,
          confirmationCode: string
        ): Promise<void> => {},

        resendConfirmationCode: async (email: string): Promise<void> => {},

        requestPasswordResetCode: async (email: string): Promise<void> => {},

        resetPassword: async (
          email: string,
          verificationCode: string,
          newPassword: string
        ): Promise<void> => {},

        signOut: async (): Promise<void> => {}
      },
      {},
      this.state
    );
  }
}
