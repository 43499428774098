import type { Site } from '@/constructs/Site';
import { InvalidArgumentError } from '@/utils/errors';
import type { Country } from '@/constructs/Country';
import { EndOfChainError } from './EndOfChainError';
import type { IHandler } from './IHandler';
import SiteLocaleHandler from './SiteLocaleHandler';

/** Represents options which modify the behavior of how {@link ChainOfResponsibility} handlers are executed. */
export interface IChainOfResponsibilityOptions {
  /**
   * Determines whether an error should be thrown if all handlers are executed but the
   * `requestData` wasn't fully processed.
   *
   * This is the default behavior as it's generally expected that _something_ will handle
   * the request. However, there are cases where this is not the desired behavior and
   * handling the request is completely optional. In these cases, `mustHandle` may be set
   * to `false` to prevent an error from being thrown.
   */
  mustHandle: boolean;

  /**
   * Determines whether to run each handler as a side-effect, in sequence.
   *
   * Running a handler as side-effect means that each handler is not expected to pass the
   * value onto the next handler or to call the `next` function at all. Instead, each handler
   * is executed sequentially, regardless of whether it's synchronous or asynchronous, and
   * and expected to only produce side effects.
   */
  runAllHandlersAsSideEffects?: boolean | undefined;
}

/** This subclass is used in a `handle` overload to narrow the return type. */
export interface IChainOfResponsibilityNoHandlersAsSideEffectsOption<
  MustHandle extends boolean
> extends IChainOfResponsibilityOptions {
  /** @inheritdoc */
  mustHandle: MustHandle;

  /** @inheritdoc */
  runAllHandlersAsSideEffects?: false | undefined;
}

/** This subclass is used in a `handle` overload to narrow the return type. */
export interface IChainOfResponsibilityHandlersAsSideEffectsOption
  extends IChainOfResponsibilityOptions {
  /** @inheritdoc */
  mustHandle: false;

  /** @inheritdoc */
  runAllHandlersAsSideEffects: true;
}

/**
 * Represents an implementation of the "Chain of Responsibility" design pattern.
 * This pattern is commonly used for tasks like event handling and request processing
 * where multiple objects can potentially handle a request. Unlike a naive approach to
 * event handling, "Chain of Responsibility" does not require explicitly specifying
 * which handler will handle the request. Each handler in the chain independently
 * decides whether to process the request, or pass it to the next handler in the
 * chain, creating a sequential flow of responsibility.
 *
 * @see {@link https://refactoring.guru/design-patterns/chain-of-responsibility Chain of Responsibility on Refactoring.Guru}
 */
export class ChainOfResponsibility<T, ReturnValue = void> {
  private handlers: Array<IHandler<T, ReturnValue>> = [];

  /**
   * Adds the given handler to the end of the chain.
   * @param handler - A handler for some expected data.
   * @example
   * // only register the handler for 'SANUK-US'
   * new ChainOfResponsibility<InteractionDetails, Promise<void>>().addHandler(new GTMUserInteractionHandler());
   */
  public addHandler(handler: IHandler<T, ReturnValue>): void {
    this.handlers.push(handler);
    // return this; // method chaining requires a lot more thought to statically optimize
  }

  /**
   * Like {@link addHandler}, but it registers the handler
   * to run only if the brand and locale match.
   *
   * **Note**: the first argument `site` must be a `Site` literal like
   * `Site.Ahnu` or `Site.Sanuk`, or else this will error during static optimization.
   * @param site - The site that should get this handler.
   * @param country - The country in which this handler should execute.
   * @param handler - A handler for some expected data.
   * @example
   * // only register the handler for 'SANUK-US'
   * new ChainOfResponsibility<InteractionDetails, Promise<void>>()
   *  .addSiteLocaleHandler(Site.Sanuk, Country.US, new CoveoUserInteractionHandler());
   */
  public addSiteLocaleHandler(
    site: Site,
    country: Country | 'default',
    handler: IHandler<T, ReturnValue>
  ): void {
    this.addHandler(new SiteLocaleHandler(site, country, handler));
    // return this; // method chaining requires a lot more thought to statically optimize
  }

  /**
   * Handles the given `requestData` via the registered handlers.
   * @param requestData - The request data to pass to the handlers.
   * @returns The value returned by processing the data.
   * @throws {@link EndOfChainError} Occurs if the `mustHandle` option is `true`
   * and the registered handlers execute without completely processing the data.
   * @example
   * declare const cor: ChainOfResponsibility<number, string>;
   * cor.handle(5); // string
   */
  public handle(
    requestData: T
  ): ReturnValue extends Promise<infer R> ? Promise<R> : ReturnValue;

  /**
   * Handles the given `requestData` via the registered handlers.
   *
   * This overload alters the behavior to run each handler as a side-effect,
   * in sequence. As a result, when this completes, the return value is always
   * `void`, or resolves to `void` if the handlers are `async`.
   * @param requestData - The request data to pass to the handlers.
   * @param options - Options which modify the behavior of how handlers are executed.
   * In this case, `{ mustHandle: false, runAllHandlersAsSideEffects: true }`.
   * @example
   * declare const cor: ChainOfResponsibility<number, string>;
   * cor.handle(5, { mustHandle: false, runAllHandlersAsSideEffects: true }); // void
   */
  public handle(
    requestData: T,
    options: IChainOfResponsibilityHandlersAsSideEffectsOption
  ): ReturnValue extends Promise<infer R> ? Promise<void> : void;

  /**
   * Handles the given `requestData` via the registered handlers.
   *
   * This overload alters the behavior of handlers by controlling whether
   * an unhandled "request" throws an {@link EndOfChainError}. Setting the `mustHandle`
   * option to `false` will return `undefined` instead of throwing an error.
   * @param requestData - The request data to pass to the handlers.
   * @param options - Options which modify the behavior of how handlers are executed.
   * In this case, `{ mustHandle: false, runAllHandlersAsSideEffects?: false | undefined }`.
   * @returns The value returned by processing the data, or `undefined` if `mustHandle` is
   * `false` and the "request" is unhandled.
   * @throws {@link EndOfChainError} Occurs if the `mustHandle` option is `true`
   * and the registered handlers execute without completely processing the data.
   * @example
   * declare const cor: ChainOfResponsibility<number, string>;
   * cor.handle(5, { mustHandle: false }); // string | undefined
   */
  public handle<MustHandle extends boolean>(
    requestData: T,
    options: IChainOfResponsibilityNoHandlersAsSideEffectsOption<MustHandle>
  ): ReturnValue extends Promise<infer R>
    ? MustHandle extends true
      ? Promise<R>
      : Promise<R | undefined>
    : MustHandle extends true
      ? ReturnValue
      : ReturnValue | undefined;

  /**
   * Handles the given `requestData` via the registered handlers.
   *
   * This overload represents the catch-all signature for this method.
   * It is used when the type of `options` cannot be narrowed further,
   * and the behavior of how handlers are executed cannot be totally inferred.
   *
   * **Note**: The `mustHandle` and `runAllHandlersAsSideEffects` options must not both be `true`.
   * @param requestData - The request data to pass to the handlers.
   * @param options - Options which modify the behavior of how handlers are executed.
   * @returns The value returned by processing the data, or `undefined` if `mustHandle` is
   * `false` and the "request" is unhandled or `runAllHandlersAsSideEffects` is `true`.
   * @throws {@link InvalidArgumentError} Occurs if both `mustHandle` and
   * `runAllHandlersAsSideEffects` options are `true`.
   * @throws {@link EndOfChainError} Occurs if the `mustHandle` option is `true`
   * and the registered handlers execute without completely processing the data.
   * @example
   * declare const cor: ChainOfResponsibility<number, string>;
   * cor.handle(5, { mustHandle: someBool, runAllHandlersAsSideEffects: someOtherBool }); // string | undefined
   * cor.handle(5, { mustHandle: true, runAllHandlersAsSideEffects: true }); // InvalidArgumentError
   */
  public handle<const Options extends IChainOfResponsibilityOptions>(
    requestData: Options['mustHandle'] extends true
      ? Options['runAllHandlersAsSideEffects'] extends true
        ? never // a clever trick to disallow both options to be `true` if they are statically known.
        : T
      : T,
    options: Options
  ): ReturnValue extends Promise<infer R>
    ? Options['mustHandle'] extends true
      ? Promise<R>
      : Promise<R | undefined>
    : Options['mustHandle'] extends true
      ? ReturnValue
      : ReturnValue | undefined;

  /**
   * Handles the given `requestData` via the registered handlers.
   * @param requestData - The request data to pass to the handlers.
   * @param options - Options which modify the behavior of how handlers are executed.
   * @returns The value returned by processing the data, or `undefined` if `mustHandle` is
   * `false` and the "request" is unhandled or `runAllHandlersAsSideEffects` is `true`.
   * @throws {@link InvalidArgumentError} Occurs if both `mustHandle` and
   * `runAllHandlersAsSideEffects` options are `true`.
   * @throws {@link EndOfChainError} Occurs if the `mustHandle` option is `true`
   * and the registered handlers execute without completely processing the data.
   */
  public handle(
    requestData: T,
    options?: IChainOfResponsibilityOptions
  ): ReturnValue extends Promise<infer R>
    ? Promise<R | undefined | void>
    : ReturnValue | undefined | void {
    const { mustHandle, runAllHandlersAsSideEffects } = options ?? {
      mustHandle: true,
      runAllHandlersAsSideEffects: false
    };

    // We can't require that a single handler handles the "request" if we are
    // running them all as side-effects. Thus we throw an error.
    if (runAllHandlersAsSideEffects && mustHandle) {
      throw new InvalidArgumentError(
        '`runAllHandlersAsSideEffects` and `mustHandle` cannot be simultaneously true.'
      );
    }

    if (runAllHandlersAsSideEffects) {
      return this._handleAsSideEffects(requestData);
    }

    return this._handleClassic(requestData, mustHandle);
  }

  /**
   * This `handle` implementation runs each registered handler as if it was a side-effect.
   * Each handler is executed sequentially, regardless of whether it's synchronous or
   * asynchronous, and their return value is ignored (undefined).
   * @param requestData - The request data to pass to handlers.
   * @returns A promise that resolves to `undefined` if the handlers are `async`. `undefined` otherwise.
   */
  private _handleAsSideEffects(
    requestData: T
  ): ReturnValue extends Promise<infer R> ? Promise<void> : void {
    /**
     * This is used as the `next` function passed to handlers. We make it a no-op to ensure all
     * handlers are ran sequentially in the chain, regardless of whether they are async or not.
     */
    const noop = (): any => {};

    /** The result of the last `handler.handle` call or a promise that resolves to the completion of it. */
    let lastResult: unknown;
    for (const handler of this.handlers) {
      if (lastResult instanceof Promise) {
        // It important we still call handlers in a chain, meaning if they are async,
        // we should not execute the next handler until the previous one completes.
        lastResult = lastResult.then(() => handler.handle(requestData, noop));
      } else {
        // If `handle` is synchronous, this will simply run and store the result.
        // However, if `handle` is async, we get a promise back, which is stored instead
        // and will cause this loop to remain in the first branch of this conditional.
        lastResult = handler.handle(requestData, noop);
      }
    }

    return (
      lastResult instanceof Promise
        ? lastResult.then(noop) // ensures the promise resolves to undefined
        : undefined
    ) as ReturnValue extends Promise<infer R> ? Promise<void> : void;
  }

  /**
   * This `handle` method implements the classic algorithm for {@link https://refactoring.guru/design-patterns/chain-of-responsibility Chain of Responsibility}.
   * That is, each handler is either responsible for fully processing the request, or
   * calling `next` to let another handler process it further.
   * @param requestData - The request data to pass to handlers.
   * @param mustHandle - Whether an error should be thrown if all handlers are executed but
   * the `requestData` wasn't marked as processed.
   * @returns The value returned by processing the data, or `undefined` if `mustHandle` is
   * `false` and the "request" is unhandled.
   * @throws {@link EndOfChainError} Occurs if the `mustHandle` option is `true`
   * and the registered handlers execute without completely processing the data.
   */
  private _handleClassic<MustHandle extends boolean>(
    requestData: T,
    mustHandle: MustHandle
  ): ReturnValue extends Promise<infer R>
    ? MustHandle extends true
      ? Promise<R>
      : Promise<R | undefined>
    : MustHandle extends true
      ? ReturnValue
      : ReturnValue | undefined {
    let handlerIndex = 0;

    /**
     * This `next` function is responsible for iterating through the handlers
     * during a "classic request". It closes over `handlerIndex` per request,
     * so that every time `next` is invoked, this request's `handlerIndex` is
     * incremented, even if multiple "requests" are running simultaneously.
     * @param requestData - The request data to pass to handlers.
     * @returns The value returned by processing the data, or `undefined` if `mustHandle` is
     * `false` and the "request" is unhandled.
     * @throws {@link EndOfChainError} Occurs if the `mustHandle` option is `true`
     * and the registered handlers execute without completely processing the data.
     */
    const next = (requestData: T): ReturnValue => {
      const handler = this.handlers[handlerIndex++];

      // check if we're at the end of the chain
      if (handler === undefined) {
        if (mustHandle) {
          throw new EndOfChainError(
            'The request data was not handled by any registered handlers.'
          );
        }
        return undefined as ReturnValue; // necessary to ensure `next` has a consistent signature.
      }

      // if not, call the next handler
      return handler.handle(requestData, next);
    };

    // Initiate processing of data
    return next(requestData) as ReturnValue extends Promise<infer R>
      ? Promise<R>
      : ReturnValue;
  }
}
