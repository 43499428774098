import type { CouponRejectionReason } from '../../../../models/Cart/Coupon';

/**
 * Enumerates the valid rejection reasons for a Talon.One Coupon.
 * For the first-party rejection enum, see {@link CouponRejectionReason}.
 * @see {@link https://docs.talon.one/docs/dev/integration-api/api-effects#rejectcoupon Talon.One Coupon Rejection Reasons}
 */
enum TalonOneCouponRejectionReason {
  CouponExpired = 'CouponExpired',
  CouponLimitReached = 'CouponLimitReached',
  CouponNotFound = 'CouponNotFound',
  CouponPartOfNotRunningCampaign = 'CouponPartOfNotRunningCampaign',
  CouponRecipientDoesNotMatch = 'CouponRecipientDoesNotMatch',
  CouponRejectedByCondition = 'CouponRejectedByCondition',
  CouponStartDateInFuture = 'CouponStartDateInFuture',
  EffectCouldNotBeApplied = 'EffectCouldNotBeApplied',
  ProfileLimitReached = 'ProfileLimitReached',
  CouponPartOfNotTriggeredCampaign = 'CouponPartOfNotTriggeredCampaign',
  CouponReservationRequired = 'CouponReservationRequired',
  ProfileRequired = 'ProfileRequired'
}

export default TalonOneCouponRejectionReason;
