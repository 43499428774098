import { mocked } from '@/configs';
import { IGiftCardBase } from '@/services/models/GiftCard';
import { IInitialProduct } from '@/services/serverless/integrations/AWS/AWSCartService';
import { Constructor, DTO } from '@/type-utils';
import { CartModel, ICart } from '../../models/Cart';
import { ILineItem } from '../../models/Cart/LineItem';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { CartService } from './CartService';
import { MOCK_CARTMODEL, MOCK_CART_ITEMS } from './mock';

const initialState = {};

/**
 * Mock for the CartService.
 */
export default class CartServiceMock extends ServiceMock<CartService> {
  // TODO: This type is incorrect and needs updating before use.
  private testDTO = MOCK_CARTMODEL as DTO<ICart>;
  private testItemDTOs: Array<Partial<DTO<ILineItem>>> = MOCK_CART_ITEMS;

  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): CartService {
    return MockService.getMockOf(this.service) as unknown as CartService;
  }

  /**
   * The constructor to initialize the mocks.
   *  @param service - The service for this mock, used to remove a circular dependency.
   */
  public constructor(private service: Constructor<CartService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(this.service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Constructor<CartService>): void {
    const mockEnabled: boolean = mocked.CartService;
    MockService.mockService(
      mockEnabled,
      service,
      {
        getNewCart: async (): Promise<CartModel> => {
          return Promise.resolve(CartModel.from(this.testDTO));
        },

        getNewAdHocCart: async (): Promise<CartModel> => {
          return Promise.resolve(CartModel.from(this.testDTO));
        },

        getCart: async (cartID: string): Promise<CartModel> => {
          return Promise.resolve(CartModel.from(this.testDTO));
        },

        getCartFromSession: async (): Promise<CartModel> => {
          return Promise.resolve(CartModel.from(this.testDTO));
        },

        addItem: async (
          item: IInitialProduct,
          cartID?: string
        ): Promise<string> => {
          return Promise.resolve(item.pid);
        },

        removeItem: async (itemID: string, cartID?: string): Promise<void> => {
          return Promise.resolve();
        },

        updateItem: async (
          itemID: string,
          quantity: number,
          cartID?: string
        ): Promise<void> => {
          return Promise.resolve();
        },

        replaceItem: async (
          itemID: string,
          newItem: IInitialProduct,
          cartID: string
        ) => {
          return Promise.resolve(itemID);
        },

        mergeCarts: async (
          sourceCartID: string,
          destinationCartID: string
        ): Promise<CartModel> => {
          return Promise.resolve(CartModel.from(this.testDTO));
        },

        addCoupon: async (
          couponCode: string,
          cartID: string
        ): Promise<string> => {
          return Promise.resolve(couponCode);
        },

        removeCoupon: async (
          couponID: string,
          cartID?: string
        ): Promise<void> => {
          return Promise.resolve();
        },

        addGiftCard: async (
          giftCard: IGiftCardBase,
          cartID?: string
        ): Promise<string> => {
          return Promise.resolve(giftCard.giftCardNumber);
        },

        removeGiftCard: async (
          cardID: string,
          cartID?: string
        ): Promise<void> => {
          return Promise.resolve();
        },

        addPromotion: async (
          promotionName: string,
          cartID?: string
        ): Promise<string> => {
          return Promise.resolve(promotionName);
        },

        removePromotion: async (
          promotionID: string,
          cartID?: string
        ): Promise<void> => {
          return Promise.resolve();
        }
      },
      {},
      this.state
    );
  }
}
