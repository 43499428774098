import { EnvironmentService } from '../EnvironmentService';

import { ClientReviewsService } from './ClientReviewsService';
import { ServerReviewsService } from './ServerReviewsService';

/**
 * Isomorphic `ReviewsService`. Is either a `ClientReviewsService`
 * or a `ServerReviewsService`, depending on where it's used.
 *
 * Used by models so that every service usage doesn't have to be wrapped
 * in if statements.
 */
const ReviewsService = (typeof window !== "undefined")
  ? ClientReviewsService
  : ServerReviewsService;

export default new ReviewsService();
