import { ReviewsService } from '../../isomorphic/ReviewsService';
import { DTO } from '@/type-utils';

import Model from '../Model';
import { IReview } from './IReview';

/**
 * Represents a single review.
 */
export default class ReviewModel
  extends Model<DTO<IReview>>
  implements IReview
{
  /** @inheritdoc */
  public readonly id: number;

  /** @inheritdoc */
  public readonly title: string;

  /** @inheritdoc */
  public readonly content: string;

  /** @inheritdoc */
  public readonly author: string;

  /** @inheritdoc */
  public readonly stars: IReview['stars'];

  /** @inheritdoc */
  public readonly createdAt: Date;

  /** @inheritdoc */
  public readonly sentiment: number | null;

  /** @inheritdoc */
  private _votesUp: number;

  /** @inheritdoc */
  private _votesDown: number;

  /** @inheritdoc */
  public get votesUp(): number {
    return this._votesUp;
  }

  /** @inheritdoc */
  public set votesUp(n: number) {
    this._votesUp = n;
  }

  /** @inheritdoc */
  public get votesDown(): number {
    return this._votesDown;
  }

  /** @inheritdoc */
  public set votesDown(n: number) {
    this._votesDown = n;
  }

  /**
   * @inheritdoc
   *
   * @param review - The review `DTO`.
   */
  public constructor(review: DTO<IReview>) {
    super(review);

    this.id = review.id;
    this.title = review.title;
    this.content = review.content;
    this.author = review.author;
    this.stars = review.stars;
    this._votesUp = review.votesUp;
    this._votesDown = review.votesDown;
    this.createdAt = new Date(review.createdAt);
    this.sentiment = review.sentiment;
  }

  /**
   * Upvote the review.
   * Returns the number of new upvotes, however only by incrementing
   * the local number. If there is another concurrent user voting
   * on this review, the count will be out of sync until a refresh occurs.
   *
   * @returns New inaccurate number of upvotes.
   */
  public async upvote(): Promise<number> {
    await ReviewsService.voteOnReview(this.id, 'up');
    this.votesUp += 1;
    return this.votesUp;
  }

  /**
   * Undo an upvote on the review.
   * See {@link upvote} for details on voting inaccuracy.
   *
   * @returns New inaccurate number of upvotes.
   */
  public async undoUpvote(): Promise<number> {
    await ReviewsService.voteOnReview(this.id, 'up', true);
    this.votesUp -= 1;
    return this.votesUp;
  }

  /**
   * Downvote the review.
   * See {@link upvote} for details on voting inaccuracy.
   *
   * @returns New inaccurate number of downvotes.
   */
  public async downvote(): Promise<number> {
    await ReviewsService.voteOnReview(this.id, 'down');
    this.votesDown += 1;
    return this.votesDown;
  }

  /**
   * Undo a downvote on the review.
   * See {@link upvote} for details on voting inaccuracy.
   *
   * @returns New inaccurate number of downvotes.
   */
  public async undoDownvote(): Promise<number> {
    await ReviewsService.voteOnReview(this.id, 'down', true);
    this.votesDown -= 1;
    return this.votesDown;
  }

  /** @inheritdoc */
  public toDTO(): DTO<IReview> {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
      author: this.author,
      stars: this.stars,
      votesUp: this.votesUp,
      votesDown: this.votesDown,
      createdAt: this.createdAt.toISOString(),
      sentiment: this.sentiment
    };
  }
}
