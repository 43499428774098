import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { RejectedCouponError } from './RejectedCouponError';

export const { CouponNotActiveError } = errorFactory(
  'CouponNotActiveError',
  RejectedCouponError
);

/** Error for a coupon that is not currently active. */
export type CouponNotActiveError = InstanceTypeOf<typeof CouponNotActiveError>;
