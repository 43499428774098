import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { UnableToRetrieveCountryInventoryStatusError } = errorFactory(
  'UnableToRetrieveCountryInventoryStatusError'
);

/** Error to use when inventory status for a country is not available. */
export type UnableToRetrieveCountryInventoryStatusError = InstanceTypeOf<
  typeof UnableToRetrieveCountryInventoryStatusError
>;
