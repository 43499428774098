import type PromotionsService from '../../../isomorphic/PromotionsService';

/**
 * Enumerates valid discount modes.
 * @see {@link PromotionsService.getDiscountAmount}
 */
enum DiscountMode {
  /**
   * The specified value will be discounted from the price this discount is
   * being applied to.
   */
  Fixed,

  /**
   * The specified value (0 - 100) represents a percentage that will be
   * discounted from the price this discount is being applied to.
   */
  Percentage,

  /**
   * The specified value will replace the price this discount is being
   * applied to.
   */
  Override
}

export default DiscountMode;
