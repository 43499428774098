import IProfile from '../IProfile';

export default {
  accountID: 'HKDEAQVCBPLYWM2023',
  firstName: 'User',
  lastName: 'McUserface',
  email: 'user.mcuserface@deckers.com',
  phoneNumber: '+14325551212',
  birthday: null
} as IProfile;
