import { DTO, Nullable } from '@/type-utils';
import { removeUndefined } from '@/utils/object-utils';

import { IPrice } from '.';
import { MoneyModel } from '../Money';

import Model from '../Model';

/** Represents a product's price. */
export default class PriceModel extends Model<DTO<IPrice>> implements IPrice {
  /** @inheritDoc */
  public readonly retailPrice: MoneyModel;

  /** @inheritDoc */
  public readonly currentPrice: Nullable<MoneyModel>;

  /** @inheritDoc */
  public readonly minPrice: Nullable<MoneyModel>;

  /** @inheritDoc */
  public readonly maxPrice: Nullable<MoneyModel>;

  /** @inheritDoc */
  public readonly isDiscounted: boolean = false;

  /** @inheritdoc */
  public constructor(dto: DTO<IPrice>) {
    super(dto);

    this.retailPrice = MoneyModel.from(dto.retailPrice);

    if (dto.currentPrice) this.currentPrice = MoneyModel.from(dto.currentPrice);
    if (dto.minPrice) this.minPrice = MoneyModel.from(dto.minPrice);
    if (dto.maxPrice) this.maxPrice = MoneyModel.from(dto.maxPrice);
    if (dto.isDiscounted) this.isDiscounted = dto.isDiscounted;
  }

  /** @inheritdoc */
  public toDTO(): DTO<IPrice> {
    return removeUndefined({
      currentPrice: this.currentPrice?.toDTO(),
      retailPrice: this.retailPrice.toDTO(),
      minPrice: this.minPrice?.toDTO(),
      maxPrice: this.maxPrice?.toDTO(),
      isDiscounted: this.isDiscounted
    }) as DTO<IPrice>;
  }
}
