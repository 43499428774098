/** Enumerates valid promotion targets. */
enum PromotionTarget {
  /** The promotion will apply to the entire cart. */
  Cart,

  /** The promotion will apply to a single line item. */
  LineItem,

  /** The promotion will apply to a single shipment. */
  Shipment
}

export default PromotionTarget;
