import { FunctionComponent } from 'react';

import { classes, normalizeCSSLength } from '@/next-utils/css-utils/scss-utils';
import { AnyAcceptableCSSLength } from '@/type-utils/css';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import IStylable from '../../traits/IStylable';
import { Icon, IconTypes } from '../Icon';

import S from './styles.module.scss';

export interface ISpinnerProps extends IStylable {
  /** Spinner height and width. Will take priority over the `height` and `width` styles if specified. */
  size?: AnyAcceptableCSSLength;

  /** Spinner color. Will take priority over the `color` style if both are specified. */
  color?: string;

  /** Style properties to apply directly to the icon.  */
  iconProperties?: IStylable;

  /**
   * If this Spinner should self-center.
   *
   * This is done by rendering it in a wrapper that grows to all its available size
   * and centers all of its contents.
   */
  autoCenter?: boolean;

  /**
   * Use for page spinners where you want it to be centered and take up space on the page.
   * It is used on the cart or the login page, where the whole page needs a spinner.
   */
  page?: boolean;
}

/** General Spinner component to indicate loading and/or background actions. */
export const Spinner: FunctionComponent<ISpinnerProps> = ({
  className,
  id,
  style,
  size,
  color,
  iconProperties = {},
  autoCenter = false,
  page = false
}) => {
  // Spinner icon type depends on brand
  const SpinnerIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.CircleNotch,
      AHNU: IconTypes.CircleNotchLight
    }),
    []
  );

  const spinnerContent = (
    <div
      id={id}
      style={{
        ...style
      }}
      className={classes(className, S.spinner, {
        [S.page]: page
      })}
    >
      <Icon
        className={classes(S.icon, iconProperties.className)}
        id={iconProperties.id}
        style={{
          ...iconProperties.style,
          ...(size && {
            height: normalizeCSSLength(size),
            width: normalizeCSSLength(size)
          })
        }}
        color={color}
        icon={SpinnerIconType}
      />
    </div>
  );

  return autoCenter ? (
    <div className={S.centerWrapper}>{spinnerContent}</div>
  ) : (
    spinnerContent
  );
};
