import type { DTO, Nullable } from '@/type-utils';
import Model from '../Model';
import type IProduct from './IProduct';
import type IProductGroup from './IProductGroup';
import type IProductVariationGroup from './IProductVariationGroup';
import type { ProductVariationGroupType } from './IProductVariationGroup';
import ProductGroupModel from './ProductGroupModel';

/**
 * Represents a group of product groups that vary along some product variation.
 */
class ProductVariationGroupModel
  extends Model<DTO<IProductVariationGroup>>
  implements IProductVariationGroup
{
  /** @inheritdoc */
  public readonly type: ProductVariationGroupType;

  /** @inheritdoc */
  public readonly groups: Array<ProductGroupModel>;

  /**
   * Creates a new ProductGroupModel.
   * @param dto - The DTO to create the model from.
   */
  public constructor(
    dto: DTO<IProductVariationGroup> | IProductVariationGroup
  ) {
    super(dto as DTO<IProductVariationGroup>);
    this.type = dto.type;
    this.groups = dto.groups.map((group) => ProductGroupModel.from(group));
  }

  /**
   * Gets a group by its ID.
   * @param id - The ID of the group to get.
   * @returns The group with the specified ID, or null if no group was found.
   * @example
   * const group = variationGroup.getGroupById('rayon');
   */
  public getProductGroupById(id: string): Nullable<ProductGroupModel> {
    return this.groups.find((group) => group.id === id);
  }

  /**
   * Checks if the variation group contains the specified product group.
   * @param [group] - The product group to check for.
   * @returns `true` if the variation group contains the product group, otherwise `false`.
   */
  public hasProductGroup(group: string | IProductGroup): boolean {
    const groupId = typeof group === 'string' ? group : group.id;

    return this.groups.some((g) => g.id === groupId);
  }

  /**
   * Checks if the variation group "includes" the specified product or a variant.
   *
   * **Note:** This method does not imply a product variant can be found nested within {@link groups},
   * since product groups only contain base products.
   *
   * @param product - The product or {@link IProduct.styleNumber styleNumber} to check for.
   * @returns `true` if the variation group "includes" the product or a variant, otherwise `false`.
   */
  // TODO: A future enhancement could be to allow any SKU to be passed in, rather than just the style number.
  public hasProductVariant(product: string | IProduct): boolean {
    return this.groups.some((g) => g.hasProductVariant(product));
  }

  /**
   * Gets the unique product group that contains the specified product variant, if one exists.
   *
   * @param product - The product or {@link IProduct.styleNumber styleNumber} to check for.
   * @returns The unique product group that contains the product variant, or `undefined` if no such group exists.
   */
  public getUniqueGroupForProductVariant(
    product: string | IProduct
  ): ProductGroupModel | undefined {
    const matchingGroups = this.groups.filter((g) =>
      g.hasProductVariant(product)
    );

    if (matchingGroups.length === 0) {
      // No groups contain the product variant.
      return undefined;
    }

    if (matchingGroups.length > 1) {
      // Multiple groups contain the product variant,
      // so we cannot determine a unique group.
      return undefined;
    }

    return matchingGroups[0];
  }

  /** @inheritdoc */
  public override toDTO(): DTO<IProductVariationGroup> {
    return {
      type: this.type,
      groups: this.groups.map((group) => group.toDTO()) as Array<IProductGroup>
    };
  }
}

export default ProductVariationGroupModel;
