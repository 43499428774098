import { DTO, Nullable } from '@/type-utils';
import Model from '../../Model';

import type IProfile from './IProfile';

/** Represents a Profile, a collection of a customer's personal information. */
export default class ProfileModel
  extends Model<DTO<IProfile>>
  implements IProfile
{
  /** @inheritdoc */
  public readonly accountID: string;

  /** @inheritdoc */
  public readonly firstName: string;

  /** @inheritdoc */
  public readonly lastName: string;

  /** @inheritdoc */
  public readonly email: string;

  /** @inheritdoc */
  public readonly birthday: Nullable<Date>;

  /** @inheritdoc */
  public readonly phoneNumber?: Nullable<string>;

  /** @inheritdoc */
  public constructor(dto: DTO<IProfile>) {
    super(dto);

    this.accountID = dto.accountID;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.email = dto.email;
    this.birthday = dto.birthday ? new Date(dto.birthday) : null;
    this.phoneNumber = dto.phoneNumber;
  }

  /** @inheritDoc */
  public toDTO(): DTO<IProfile> {
    return {
      accountID: this.accountID,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      birthday: this.birthday,
      phoneNumber: this.phoneNumber
    } as DTO<IProfile>;
  }
}
