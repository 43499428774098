import { mocked } from '@/configs';
import MockService, { MockState, ServiceMock } from '../MockService';
import { Constructor } from '@/type-utils';
import type {
  FraudDecisionType,
  IFraudAccountEventParams,
  IFraudDecision,
  IFraudMetadata,
  IFraudOrderStatusEventParams,
  IFraudOrderValidationParams,
  IFraudService
} from '../../serverless/ServerFraudService/schemas';
import type { FraudService } from './FraudService';

/**
 * The mock state object for flexible mocking of the FraudService.
 */
interface IFraudServiceMockState {
  /**
   * The decision that should be returned by `validateOrder()`.
   */
  fraudDecision: FraudDecisionType;
}

/**
 * Mock for FraudService.
 */
export default class FraudServiceMock extends ServiceMock<
  IFraudService<IFraudMetadata>
> {
  /** @inheritdoc */
  protected _state: MockState<IFraudServiceMockState>;

  /** @inheritdoc */
  public get state(): MockState<IFraudServiceMockState> {
    return this._state;
  }

  /** @inheritdoc */
  public constructor(private service: Constructor<FraudService>) {
    super();
    this._state = new MockState({
      fraudDecision: 'ACCEPTED' as FraudDecisionType
    });
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  public getMock(): IFraudService<IFraudMetadata> {
    return MockService.getMockOf(this.service) as IFraudService<IFraudMetadata>;
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Constructor<FraudService>): void {
    const mockEnabled: boolean = mocked.FraudService;
    MockService.mockService(
      mockEnabled,
      service,
      {
        validateOrder: async (
          params: IFraudOrderValidationParams
        ): Promise<IFraudDecision<IFraudMetadata>> => {
          return { type: this.state.getState().fraudDecision };
        },
        sendAccountEvent: async (
          params: IFraudAccountEventParams
        ): Promise<void> => {},

        sendOrderStatusEvent: async (
          params: IFraudOrderStatusEventParams
        ): Promise<void> => {}
      },
      {},
      this.state
    );
  }
}
