import axios from 'axios';

import { DTO } from '@/type-utils';
import { NotImplementedError } from '@/utils/errors';

import ServerAccountService from '@/services/serverless/ServerAccountService';
import Service from '../../Service';
import {
  AccountModel,
  IAccount,
  IProfile,
  ProfileModel
} from '../../models/Account';

import { EnvironmentService } from '../EnvironmentService';

import AccountServiceMock from './AccountServiceMock';
import CSRFTokenService from '../CSRFTokenService';

/** Abstracts {@link IAccount Account}-related operations. */
export class AccountService extends Service {
  private client = axios.create({
    baseURL: '/api/account'
  });

  /**
   * Updates a customer account with the data existing in the provided
   * {@link IAccount} object.
   *
   * @param account - An account with updated information.
   * @throws An {@link UnableToUpdateUserAccountError} when the account could not be
   * updated. See the `cause` property for more details about what specifically went wrong.
   */
  public async updateAccount(account: IAccount): Promise<void> {
    throw new NotImplementedError(
      'Updating whole accounts at once has not been implemented yet.'
    );
  }

  /**
   * Updates the provided customer {@link IProfile profile}.
   *
   * NOTE: This will only update the Profile. The rest of the Account will be unaffected.
   *
   * @param profile - An {@link IProfile} object with the new profile info.
   */
  public async updateProfile(profile: IProfile): Promise<void> {
    const { accountID } = profile;
    const csrfHeaders = CSRFTokenService.getHeaders();

    await this.client.put(
      `${accountID}/profile`,
      {
        profile: ProfileModel.toDTO(profile)
      },
      {
        headers: csrfHeaders
      }
    );
  }

  /**
   * Reset user's password.
   *
   * The main difference between `forgotPasswordReset` and `changePassword` is that the user doesn't need to be
   * authenticated to change password. They only need to specify the confirmation code and a new password.
   * @param requestToken - A unique request token (directly related to the submitted email address) that gets generated by AWS and then appended
   * to a password reset url sent to the user's email. This authenticates the user and allows them to change their password.
   * @param newPassword - The new password.
   * @param confirmationCode - The confirmation code used for authentication.
   */
  public async forgotPasswordReset(
    requestToken: string,
    newPassword: string,
    confirmationCode: string
  ): Promise<void> {
    const csrfHeaders = CSRFTokenService.getHeaders();

    await this.client.post(
      `/forgot/reset`,
      {
        requestToken,
        newPassword,
        confirmationCode
      },
      {
        headers: csrfHeaders
      }
    );
  }

  /**
   * Changes user password.
   *
   * @param accountID - The ID of the customer's {@link IAccount Account}.
   * @param newPassword - The new password.
   * @param previousPassword - The previous password.
   * @param accessToken - A valid access token to authenticate the request.
   */
  public async changePassword(
    previousPassword: string,
    newPassword: string,
    accountID: string,
    accessToken: string
  ): Promise<void> {
    const csrfHeaders = CSRFTokenService.getHeaders();

    await this.client.put(
      `${accountID}/change-password`,
      {
        previousPassword,
        newPassword,
        accessToken
      },
      {
        headers: csrfHeaders
      }
    );
  }

  /**
   * Forgot password.
   *
   * Initiates a password reset flow and sends a verification code to the
   * user's email address.
   *
   * @param email - The user's email address.
   */
  public async forgotPassword(email: string): Promise<void> {
    const csrfHeaders = CSRFTokenService.getHeaders();

    await this.client.post(
      `/forgot/request`,
      {
        email
      },
      {
        headers: csrfHeaders
      }
    );
  }

  /**
   * Retrieves a User's account.
   *
   * @param accountID - The user's identifier.
   * @param cognitoUser - The user authenticated by cognito.
   * @param accessToken - A valid access token to authenticate the request.
   *
   * @returns The user's account in {@link AccountModel} form.
   */
  public async getUserAccount(
    accountID: string,
    accessToken: string
  ): Promise<AccountModel> {
    if ((typeof window === "undefined")) {
      const dto = await ServerAccountService.getUserAccount(
        accountID,
        accessToken
      );

      return AccountModel.from(dto);
    }

    const csrfHeaders = CSRFTokenService.getHeaders();

    const res = await this.client.post<DTO<IAccount>>(
      `/${accountID}`,
      {
        accessToken
      },
      {
        headers: csrfHeaders
      }
    );

    return AccountModel.from(res.data);
  }

  /**
   * Deletes personal information.
   *
   * @param accountID - The ID of the customer's {@link IAccount Account}.
   * @param accessToken - A valid access token to authenticate the request.
   * @param refreshToken - A valid refresh token to supply to the delete request.
   */
  public async deleteAccount(
    accountID: string,
    accessToken: string,
    refreshToken: string
  ): Promise<void> {
    const csrfHeaders = CSRFTokenService.getHeaders();

    await this.client.post(
      `${accountID}/delete`,
      {
        accessToken,
        refreshToken
      },
      {
        headers: csrfHeaders
      }
    );
  }
}

export default AccountService.withMock(
  new AccountServiceMock(AccountService)
) as unknown as AccountService;
