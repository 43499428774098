import { EventHandler, SyntheticEvent } from 'react';

import UserInteractionService, { InteractionDetails } from '@/services/isomorphic/UserInteractionService';

/**
 * Pass in click and key press events in order to return a new
 * events with standardized functionality.
 */
export interface IUseInteraction {
  /** The click event passed by the interactable component. */
  onClickEvent: EventHandler<SyntheticEvent>;
  /** The key press event passed by the interactable component. */
  onKeyPressEvent: EventHandler<SyntheticEvent>;
}

/**
 * A hook for returning the onClickEvent and onKeyPressEvent for components that
 * inherit the IInteractable trait. This supplies the logic for handling the interaction event
 * and the relationship between the click and keyboard event.
 * @param onClick - The onClick event, which will be converted into the full events.
 * @param onKeyPress - The onKeyPress event that will be converted to the full event,
 * which might contain an override from the click event.
 * @param interactionDetails - The interaction event that should be used to
 * make an action if it exists.
 * @returns An object with two action callbacks, one for the click and one
 * for the keyboard press on Enter.
 */
export const useInteraction = (
  onClick?: EventHandler<SyntheticEvent>,
  onKeyPress?: EventHandler<SyntheticEvent>,
  interactionDetails?: InteractionDetails
): IUseInteraction => {
  const onClickEvent = (event: SyntheticEvent<Element, Event>): void => {
    if (onClick) onClick(event);
    if (interactionDetails)
      UserInteractionService.makeAction(interactionDetails);
  };

  const onKeyPressEvent = (
    event: SyntheticEvent<Element, Event> | KeyboardEvent
  ): void => {
    if ((event as KeyboardEvent).key === 'enter') {
      if (onKeyPress) {
        onKeyPress(event as SyntheticEvent<Element, Event>);
      } else {
        onClickEvent(event as SyntheticEvent<Element, Event>);
      }
      if (interactionDetails)
        UserInteractionService.makeAction(interactionDetails);
    }
  };

  return { onClickEvent, onKeyPressEvent };
};
