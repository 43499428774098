/**
 * 3-letter ISO-4217 format currency codes supported by the site.
 *
 * @see {@link https://www.iso.org/iso-4217-currency-codes.html ISO 4217} for more info.
 */
enum Currency {
  /**
   * Represents the United States Dollar as an ISO-4217 currency code.
   */
  USD = 'USD',

  /**
   * Represents the Canadian Dollar as an ISO-4217 currency code.
   */
  CAD = 'CAD'
}

export default Currency;
