import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { ENDLESS_AISLE_COOKIE_NAME } from '@/configs/env/public';
import CookieService from '@/services/isomorphic/CookieService';

import { DTO } from '@/type-utils';

import Model from '../Model';

import type IUser from './IUser';
import UserType from './UserType';

/**
 * Represents a User: an identity that a customer takes when interacting with the site.
 *
 * Not to be confused with an {@link AccountModel Account}, which holds said
 * customer's profile info, saved addresses, and payment methods.
 */
export default abstract class UserModel<DTOType extends DTO<IUser> = DTO<IUser>>
  extends Model<DTOType>
  implements IUser
{
  /** @inheritdoc */
  public readonly uuid: string;

  /** @inheritdoc */
  public readonly type: UserType;

  /**
   * Gets whether this user is currently logged in.
   * @returns A boolean representing the logged in status.
   */
  public get isLoggedIn(): boolean {
    return this.type === UserType.Authenticated;
  }

  /**
   * The agent type of the user. This is determined by the cookie value set by
   * visiting the api endpoint `/api/set-agent`. This needed to be split out
   * from the `type` property because the user can be both authorized and an
   * agent.
   * @returns The agent type of the user, or `null` if the user is not an agent.
   */
  public get agent():
    | UserType.CallCenterRepresentative
    | UserType.RetailAssociate
    | null {
    const cookie = CookieService.tryGet(ENDLESS_AISLE_COOKIE_NAME);
    switch (true) {
      case cookie?.value === 'Contact%20Center':
        return UserType.CallCenterRepresentative;
      case !Number.isNaN(cookie?.value):
        return UserType.RetailAssociate;
      default:
        return null;
    }
  }

  /**
   * Is this user currently authenticated. This currently does not include othere types of user agents.
   * @returns `true` if the user is authenticated, `false` otherwise.
   */
  public get isAuthenticated(): boolean {
    return this.type === UserType.Authenticated;
  }

  /** @inheritdoc */
  public constructor(user: DTOType) {
    super(user);

    if (!user.uuid) {
      throw new InvalidArgumentError(`Creating a UserModel requires a UUID.`);
    }

    if (user.type == null) {
      throw new InvalidArgumentError(
        `Creating a UserModel requires a user type.`
      );
    }

    this.uuid = user.uuid;
    this.type = user.type;
  }

  /**
   * Whether this user is a Customer Service Agent. This is determined by the
   * cookie value set by visiting the api endpoint `/api/set-agent`, when the
   * storeId is set to 'Contact Center'.
   * @returns `true` if the user is a Customer Service Agent, `false` otherwise.
   */
  public isCCR(): boolean {
    return this.agent === UserType.CallCenterRepresentative;
  }

  /**
   * Whether this user is a Retail Associate. This is determined by the cookie
   * value set by visiting the api endpoint `/api/set-agent`, when the storeId
   * is set to a number.
   * @returns `true` if the user is a Retail Associate, `false` otherwise.
   */
  public isRSA(): boolean {
    return this.agent === UserType.RetailAssociate;
  }

  /** @inheritdoc */
  public toDTO(): DTOType {
    return {
      uuid: this.uuid,
      type: this.type
    } as DTOType;
  }
}
