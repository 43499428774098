import { IHandler } from '@/services/utils/chain-of-responsibility';
import {
  EventType,
  InteractionDetails
} from '../UserInteractionService/IInteractionDetails';
import PersonalizationService from './PersonalizationService';

/** An {@link IHandler} for Personalization analytics. */
export default class PersonalizationUserInteractionHandler
  implements IHandler<InteractionDetails, Promise<void>>
{
  /** @inheritdoc */
  public async handle(
    requestData: InteractionDetails,
    next: (requestData: InteractionDetails) => Promise<void>
  ): Promise<void> {
    switch (requestData.action) {
      case EventType.PageView:
      case EventType.ProductUpdateStyle:
        return PersonalizationService.sendPageContext(requestData);
      case EventType.ProductAdd:
      case EventType.OrderSuccess:
      case EventType.ProductView:
      case EventType.Scroll:
        return PersonalizationService.sendEvent(requestData);
      default:
        return next(requestData);
    }
  }
}
