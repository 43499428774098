import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { InvalidArgumentError } from '@/utils/errors';

export const { CannotShipToPOBoxError } = errorFactory(
  'CannotShipToPOBoxError',
  InvalidArgumentError
);

/**
 * Error to use when the customer enters a PO Box as their shipping address
 * and shipping to PO Boxes is disabled.
 */
export type CannotShipToPOBoxError = InstanceTypeOf<
  typeof CannotShipToPOBoxError
>;
