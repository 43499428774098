import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';

export const { InvalidCredentialsError } = errorFactory(
  'InvalidCredentialsError',
  {
    mapsToHTTPError: 'ForbiddenError'
  } as IHTTPErrorMetaData
);

/** Error to use to indicate that a supplied of credentials is incorrect. */
export type InvalidCredentialsError = InstanceTypeOf<
  typeof InvalidCredentialsError
>;
