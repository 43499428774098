import { JSONObject } from '@/type-utils';

/**
 * Extracts and decodes the payload from a JSON Web Token.
 * @see [Introduction to JSON Web Tokens](https://jwt.io/introduction) for more on JWT Structure.
 *
 * @param token - The encoded JWT.
 * @returns The decoded payload, typed to the specified generic type.
 */
export const decodeJWTPayload = <T = JSONObject<true>>(token: string): T => {
  // TODO: Regex check the token here.

  const [header, payload, signature] = token.split('.').map((part) => {
    const buffer = Buffer.from(part, 'base64');
    return buffer.toString();
  });

  return JSON.parse(payload) as T;
};
