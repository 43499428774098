import Service from '../../../Service';
import { mocked } from '@/configs';
import { Constructor } from '@/type-utils';
import MockService, { MockState, ServiceMock } from '../../MockService';
import type { GTMService } from './GTMService';

const initialState = {};

/**
 * Mock implementation of the GTMService class. This shouldn't do anything when mocked.
 */
export default class GTMServiceMock extends ServiceMock<GTMService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): GTMService {
    return MockService.getMockOf(this.service) as unknown as GTMService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<GTMService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.GTMService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        makeGTMAction: () => {}
      },
      {},
      this.state
    );
  }
}
