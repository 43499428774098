import type { DTO, Nullable } from '@/type-utils';
import Model from '../Model';
import type IProduct from './IProduct';
import type IProductGroup from './IProductGroup';
import type { ProductVariationGroupType } from './IProductVariationGroup';
import ProductModel from './ProductModel';

/**
 * Represents a group of products within some type of product variation.
 */
class ProductGroupModel
  extends Model<DTO<IProductGroup>>
  implements IProductGroup
{
  /** @inheritdoc */
  public readonly id: string;

  /** @inheritdoc */
  public readonly type: ProductVariationGroupType;

  /** @inheritdoc */
  public readonly products: Array<ProductModel>;

  /**
   * Creates a new ProductGroupModel.
   * @param dto - The DTO to create the model from.
   */
  public constructor(dto: DTO<IProductGroup> | IProductGroup) {
    super(dto as DTO<IProductGroup>);
    this.id = dto.id;
    this.type = dto.type;
    this.products = dto.products.map((product) => ProductModel.from(product));
  }

  /**
   * Gets a base product by its style number.
   * @param styleNumber - The style number of the product to get.
   * @returns The product with the specified style number, or null if no product was found.
   */
  public getBaseProduct(styleNumber: string): Nullable<ProductModel> {
    return this.products.find((product) => product.styleNumber === styleNumber);
  }

  /**
   * Checks if the group "includes" the specified product or a variant.
   *
   * **Note:** This method does not imply a product variant can be found within {@link products},
   * since `products` only contains base products.
   *
   * @param product - The product or {@link IProduct.styleNumber styleNumber} to check for.
   * @returns `true` if the group "includes" the product or a variant, otherwise `false`.
   */
  // TODO: A future enhancement could be to allow any SKU to be passed in, rather than just the style number.
  public hasProductVariant(product: string | IProduct): boolean {
    const styleNumber =
      typeof product === 'string' ? product : product.styleNumber;

    return this.products.some((p) => p.styleNumber === styleNumber);
  }

  /** @inheritdoc */
  public override toDTO(): DTO<IProductGroup> {
    return {
      id: this.id,
      type: this.type,
      products: this.products.map((product) => product.toDTO())
    };
  }
}

export default ProductGroupModel;
