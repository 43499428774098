import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { RejectedCouponError } from './RejectedCouponError';

export const { CouponNotApplicableError } = errorFactory(
  'CouponNotApplicableError',
  RejectedCouponError
);

/** Error for an coupon that's not applicable to a cart. */
export type CouponNotApplicableError = InstanceTypeOf<
  typeof CouponNotApplicableError
>;
