import { CSRF_TOKEN_COOKIE, CSRF_TOKEN_HEADER } from '@/configs/env/public';
import Service from '../../Service';
import CookieService from '../CookieService';
import { Nullable } from '@/type-utils';

/**
 * `CSRFTokenService` is used to normalize operations around our CSRF tokens. For
 * instance, getting the necessary headers to make a request to a route that is protected
 * by CSRF protection. Our approach to CSRF protection is the "Double Submit Cookie"
 * method. This requires being able to read the CSRF token from the cookie and then
 * sending it back in the header of the request. This service abstracts away the
 * details of how we get the token and how we send it back.
 */
export class CSRFTokenService extends Service {
  /**
   * Retrieves the correct HTTP header for submitting a CSRF token with a request. Our
   * CSRF protection uses the "Double Submit" pattern wherein the token is submitted
   * both in a cookie and in a header. This function returns the correct header per our
   * configuration and retrieves the token from the cookie, to submit it with a request.
   *
   * @returns A object including `x-csrf-token` header. The name of the header may be
   * different depending on the environment's configuration. This object can be used
   * directly or it can be spread into another object of headers.
   *
   * @see {@link https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie OWASP}
   */
  public getHeaders(): Record<string, Nullable<string>> {
    const csrfToken = CookieService.tryGet(CSRF_TOKEN_COOKIE);

    return {
      [CSRF_TOKEN_HEADER]: csrfToken?.value
    };
  }
}

export default new CSRFTokenService();
