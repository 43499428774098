import { MoneyModel } from '@/services/models/Money';
import type { IHandler } from '@/services/utils/chain-of-responsibility';
import type { IIntermediateCalculationState, ITotals } from '..';

/**
 * A handler for calculating the discount from line items in the cart.
 */
export default class CalculateLineItemsDiscountHandler
  implements IHandler<IIntermediateCalculationState, Promise<ITotals>>
{
  /**
   * Calculates the discount from line items in the cart and updates `totals`.
   * @param requestData - The request data to pass to process.
   * @param next - A function to pass the "request" to the next handler in some chain.
   * @returns A promise resolving to the result of processing the request.
   */
  public async handle(
    requestData: IIntermediateCalculationState,
    next: (requestData: IIntermediateCalculationState) => Promise<ITotals>
  ): Promise<ITotals> {
    const { cartModel, totals } = requestData;

    if (cartModel.items.length === 0) return next(requestData);

    const discounts = cartModel.items.map(({ subtotal, netTotal }) => {
      // Subtract netTotal and subtotal to get discount amount.
      return MoneyModel.subtract(subtotal, netTotal);
    });

    const discountTotal = MoneyModel.add(0, ...discounts);

    // Round the discount total to the nearest valid currency amount, in case it's not already.
    const roundedDiscountTotal = discountTotal.toFixed();

    totals.linesDiscount.addAmount(roundedDiscountTotal);
    totals.discount.addAmount(roundedDiscountTotal);
    totals.total.subtractAmount(roundedDiscountTotal);

    return next(requestData);
  }
}
