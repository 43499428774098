import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { ReviewsServiceError } from './ReviewsServiceError';

export const { UnableToRetrieveProductRatingsError } = errorFactory(
  'UnableToRetrieveProductRatingsError',
  ReviewsServiceError
);

/**
 * Error to use when the product ratings cannot be retrieved for an unknown reason.
 */
export type UnableToRetrieveProductRatingsError = InstanceTypeOf<
  typeof UnableToRetrieveProductRatingsError
>;
