import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '../error-utils';

export const { InvalidOperationError } = errorFactory('InvalidOperationError');

/**
 * Error indicating when something has been requested
 * that doesn't make any sense.
 *
 * Example: Asking to sign out, when the user is not even signed in.
 * Usually will be used so that the API handler can determine which HTTP
 * code to send (400 in this case).
 *
 * This is different than the `InvalidArgumentError`,
 * since this error describes how the request itself is illogical.
 * This is also different than the `ForbiddenActionError`, since
 * the forbidden action might be a request that makes sense, but
 * the user just doesn't have the correct authorization (HTTP 403).
 */
export type InvalidOperationError = InstanceTypeOf<
  typeof InvalidOperationError
>;
