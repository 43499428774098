import { mocked } from '@/configs';
import type { CartModel } from '@/services/models/Cart';
import type { ILineItem } from '@/services/models/Cart/LineItem';
import { Currency, MoneyModel } from '@/services/models/Money';
import type { Constructor } from '@/type-utils';
import type { ITotals } from '.';
import MockService, { MockState, ServiceMock } from '../MockService';
import type { CartCalculationService } from './CartCalculationService';

/**
 * The mock state object for flexible mocking of the CartCalculationService.
 */
interface ICartCalculationServiceMockState {
  /**
   * The totals that should be returned by `calculateCartTotals()`.
   */
  totals?: ITotals;

  /**
   * The line item subtotals that should be returned by `getLineItemSubtotal()`.
   */
  lineItemSubtotals?: Record<string, MoneyModel>;

  /**
   * The line item net totals that should be returned by `getLineItemNetTotal()`.
   */
  lineItemNetTotals?: Record<string, MoneyModel>;
}

/**
 * Mock for {@link CartCalculationService}.
 */
export default class CartCalculationServiceMock extends ServiceMock<CartCalculationService> {
  /** @inheritdoc */
  protected _state: MockState<ICartCalculationServiceMockState>;

  /** @inheritdoc */
  public get state(): MockState<ICartCalculationServiceMockState> {
    return this._state;
  }

  /** @inheritdoc */
  public constructor(private service: Constructor<CartCalculationService>) {
    super();
    this._state = new MockState({});
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  public getMock(): CartCalculationService {
    return MockService.getMockOf(this.service) as CartCalculationService;
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<CartCalculationService>
  ): void {
    const mockEnabled: boolean = mocked.CartCalculationService;
    MockService.mockService(
      mockEnabled,
      service,
      {
        calculateCartTotals: async (cart: CartModel) => {
          return (
            this.state.getState().totals ?? {
              cartDiscount: MoneyModel.fromAmount(0),
              linesDiscount: MoneyModel.fromAmount(0),
              discount: MoneyModel.fromAmount(0),
              shippingCost: MoneyModel.fromAmount(0),
              tax: {
                uuid: cart.uuid,
                total: MoneyModel.fromAmount(0),
                lines: [
                  // This relates to the line item model coming from the `CartService`
                  {
                    uuid: '0012ea21-0af8-4a95-9457-10aa6adb823b',
                    tax: MoneyModel.fromAmount(0),
                    rate: 0
                  },
                  // This is the shipping method.
                  {
                    uuid: 't9ahyc85zyxa2vxixvzc9aug',
                    tax: MoneyModel.fromAmount(0),
                    rate: 0
                  }
                ]
              },
              total: MoneyModel.fromAmount(0)
            }
          );
        },

        getLineItemNetTotal: (lineItem: ILineItem): MoneyModel => {
          return (
            this.state.getState().lineItemNetTotals?.[lineItem.uuid] ??
            MoneyModel.fromAmount(0)
          );
        },

        getLineItemSubtotal: (lineItem: ILineItem): MoneyModel => {
          return (
            this.state.getState().lineItemSubtotals?.[lineItem.uuid] ??
            MoneyModel.fromAmount(0)
          );
        }
      },
      {},
      this.state
    );
  }
}
