import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { CartQuantityExceededError } = errorFactory(
  'CartQuantityExceededError'
);

/** Generic error to use when some cart-related quantity has exceeded its configured value. */
export type CartQuantityExceededError = InstanceTypeOf<
  typeof CartQuantityExceededError
>;
