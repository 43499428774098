/** Enumerates possible rejection reasons for a coupon. */
enum CouponRejectionReason {
  // Start with `1` since `0` is falsy and rejection reasons can be `undefined`.
  CouponExpired = 1,
  CouponLimitReached,
  CouponNotFound,
  CouponNotActive,
  CouponNotApplicable
}

export default CouponRejectionReason;
