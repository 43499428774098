/**
 * Enumerates valid product availability states.
 *
 * Used to get the Availability Text that represents a product's current
 * availibility state.
 */
enum ProductAvailabilityState {
  /** The product is in stock (available to sell inventory is greater than one). */
  InStock,

  /** The product is available for backorder. */
  Backorder,

  /** The product is available for preorder. */
  Preorder,

  /** The product is out of stock. */
  OutOfStock,

  /**
   * The product availability is pending.
   * This value is used when the product inventory is still loading.
   */
  Pending,

  /**
   * The product is unavailable for purchase, not necessarily
   * because it is out of stock.
   */
  Unavailable,

  /**
   * It is unknown whether a product is available or not.
   *
   * This is an appropriate value for a variety of cases, such as when
   * there was an error retrieving the product's availability, or when
   * the current product doesn't represent a purchasable (complete) variant.
   * This is different from {@link ProductAvailabilityState.Pending Pending} which represents
   * the state of the inventory data being loaded.
   */
  Unknown
}

export default ProductAvailabilityState;
