import Service from '@/services/Service';
import type { CartModel } from '@/services/models/Cart';
import type { ILineItem } from '@/services/models/Cart/LineItem';
import { PromotionType } from '@/services/models/Cart/Promotion';
import type { ILineItemDiscount } from '@/services/models/Cart/Promotion/LineItem';
import { MoneyModel } from '@/services/models/Money';
import { ChainOfResponsibility } from '@/services/utils/chain-of-responsibility';
import type { DTO } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import type { IIntermediateCalculationState, ITotals } from '.';
import PromotionsService from '../PromotionsService/PromotionsService';
import CalculateCartPromotionsHandler from './CalculateCartPromotionsHandler';
import CalculateCartReturnHandler from './CalculateCartReturnHandler';
import CalculateCartShippingCostHandler from './CalculateCartShippingCostHandler';
import CalculateCartTaxHandler from './CalculateCartTaxHandler';
import CalculateLineItemsDiscountHandler from './CalculateLineItemsDiscountHandler';
import CartCalculationServiceMock from './CartCalculationServiceMock';

/**
 * A service for calculating the various totals (e.g. `shippingCost`, `discount`, etc.) for a cart.
 * This service is also responsible for making rounding adjustments to the totals, to ensure that
 * the cart total is always equal to the sum of the other totals.
 *
 * **Note**: a successful call to `calculateCartTotals()` does not guarantee that the cart is in
 * a valid, purchasable state. The result is only a best-estimate to display to the user, based on
 * the information currently available on the cart. Once all data is set on the cart, i.e. by the
 * time we reach the review step of checkout, only then are the cart totals "finalized".
 */
export class CartCalculationService extends Service {
  private calculationCOR = new ChainOfResponsibility<
    IIntermediateCalculationState,
    Promise<ITotals>
  >();

  /**
   * Creates a new instance of `CartCalculationService`.
   */
  public constructor() {
    super();

    /**
     * The order of these handlers is deliberate. Changing this order or adding/removing handlers must
     * be done with great care, or the final totals may be incorrect. For instance, the `CalculateCartTaxHandler`
     * **must** be called after all discounts have been calculated, because the tax calculation depends on
     * the promotions applied to the cart.
     */
    this.calculationCOR.addHandler(new CalculateCartShippingCostHandler());
    this.calculationCOR.addHandler(new CalculateLineItemsDiscountHandler());
    this.calculationCOR.addHandler(new CalculateCartPromotionsHandler());
    this.calculationCOR.addHandler(new CalculateCartTaxHandler());
    this.calculationCOR.addHandler(new CalculateCartReturnHandler());
  }

  /**
   * Calculates the totals for the given cart model.
   * @param cartModel - The cart model to calculate the totals for.
   * @returns A promise resolving to the calculated totals.
   */
  public async calculateCartTotals(cartModel: CartModel): Promise<ITotals> {
    const defaultTotals = {
      linesDiscount: MoneyModel.fromAmount(0),
      cartDiscount: MoneyModel.fromAmount(0),
      discount: MoneyModel.fromAmount(0),
      shippingCost: MoneyModel.fromAmount(0),
      tax: {
        uuid: cartModel.uuid,
        total: MoneyModel.fromAmount(0),
        lines: []
      },
      total: cartModel.subtotal.copy()
    };

    // If the cart is empty, return the default totals.
    if (cartModel.items.length === 0) {
      return defaultTotals;
    }

    return this.calculationCOR.handle(
      {
        cartModel,
        totals: defaultTotals
      },
      { mustHandle: true }
    );
  }

  /**
   * Calculate a line item's subtotal. That is, the unit
   * price of the item multiplied by the quantity.
   *
   * `subtotal = unit price * quantity`.
   *
   * For the total after promotions, use {@link getLineItemNetTotal}.
   *
   * @param item - The line item to calculate the subtotal for.
   * @returns The subtotal in {@link IMoney} form.
   */
  public getLineItemSubtotal(
    item: Pick<DTO<ILineItem>, 'unitPrice' | 'quantity'>
  ): MoneyModel {
    const {
      unitPrice: { currentPrice, retailPrice },
      quantity
    } = item;

    const priceToUse = currentPrice ?? retailPrice;

    const subtotal = MoneyModel.multiply(priceToUse, quantity);

    // Round the subtotal to the nearest valid currency amount, in case it's not already.
    const roundedSubtotal = subtotal.toFixed();

    return MoneyModel.from(roundedSubtotal);
  }

  /**
   * Calculate the total of a line item (after promotions).
   *
   * @param lineItem - The line item to calculate the total for.
   * @returns The total in {@link MoneyModel} form.
   * @throws An {@link InvalidStateError} if the line item contains an invalid promotion.
   */
  public getLineItemNetTotal(
    lineItem: Pick<
      DTO<ILineItem>,
      'unitPrice' | 'quantity' | 'subtotal' | 'promotions'
    >
  ): MoneyModel {
    const { promotions, subtotal } = lineItem;

    if (promotions.length === 0) {
      return MoneyModel.from(subtotal);
    }

    // Calculate each discount amount
    const discounts = promotions.map((promotion) => {
      switch (promotion.type) {
        case PromotionType.LineItemDiscount: {
          return PromotionsService.getLineItemDiscountAmount(
            lineItem,
            promotion as ILineItemDiscount
          );
        }
        default: {
          throw new InvalidStateError(
            `Cannot apply line item promotion: Promotion type "${promotion.type}" is invalid.`
          );
        }
      }
    });

    const discountTotal = MoneyModel.add(0, ...discounts);

    // Round the discount to the nearest valid currency amount, in case it's not already.
    const roundedDiscountTotal = discountTotal.toFixed();

    // Do not let the discount total exceed the line item subtotal.
    const boundedDiscountTotal = MoneyModel.min(
      MoneyModel.from(roundedDiscountTotal),
      MoneyModel.from(subtotal)
    );

    const netTotal = MoneyModel.subtract(subtotal, boundedDiscountTotal);

    return netTotal;
  }
}

export default CartCalculationService.withMock(
  new CartCalculationServiceMock(CartCalculationService)
) as CartCalculationService;
