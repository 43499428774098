import { PageType } from '../../../models/Page';
import { DYPageType } from './DYPageType';

/**
 * Maps our PageType values to the DYPageType values.
 */
export const DYPageTypeMap: Record<PageType, DYPageType> = {
  account: DYPageType.Other,
  cart: DYPageType.Cart,
  category: DYPageType.Category,
  checkout: DYPageType.Other,
  generic: DYPageType.Other,
  home: DYPageType.HomePage,
  'landing-page': DYPageType.Other,
  returns: DYPageType.Other,
  product: DYPageType.Product,
  orderconfirmation: DYPageType.Other,
  'not-found': DYPageType.Other,
  unavailable: DYPageType.Other,
  search: DYPageType.Category,
  unknown: DYPageType.Other
};
