import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { ResourceNotFoundError } from '@/utils/errors';

export const { ShippingMethodNotFoundError } = errorFactory(
  'ShippingMethodNotFoundError',
  ResourceNotFoundError
);

/** Error to use when a requested shipping method is not found. */
export type ShippingMethodNotFoundError = InstanceTypeOf<
  typeof ShippingMethodNotFoundError
>;
