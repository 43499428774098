import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { UnableToCreateResourceError } = errorFactory(
  'UnableToCreateResourceError',
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/** Error to use when a resource could not be created. */
export type UnableToCreateResourceError = InstanceTypeOf<
  typeof UnableToCreateResourceError
>;
