/* eslint-disable local-rules/warn-against-moneymodel-asunsafenumber
-- Talon.One expects all monetary values to be numbers. Hence, we need to use
`MoneyModel.asUnsafeNumber` to convert them from our internal representation.
*/
import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { DTO, Nullable } from '@/type-utils';
import {
  InvalidArgumentError,
  InvalidStateError,
  NotImplementedError
} from '@/utils/errors';
import type { ICart } from '../../../models/Cart';
import { CouponRejectionReason } from '../../../models/Cart/Coupon';
import { ILineItem, LineItemType } from '../../../models/Cart/LineItem';
import {
  ICartDiscount,
  ICartPromotion
} from '../../../models/Cart/Promotion/Cart';
import { MoneyModel } from '../../../models/Money';

import {
  DiscountMode,
  ICartPromotionUpdates,
  IPromotion,
  PromotionTarget,
  PromotionType
} from '../../../models/Cart/Promotion';

import {
  ILineItemDiscount,
  ILineItemPromotion
} from '../../../models/Cart/Promotion/LineItem';

import Service from '../../../Service';
import ConfigurationService from '../../ConfigurationService';

import siteCached from '../../../utils/siteCached';
import ICustomerSessionCartItem from './CustomerSession/ICustomerSessionCartItem';
import ICustomerSessionResponse from './CustomerSession/ICustomerSessionResponse';
import IRequestCustomerSession from './CustomerSession/IRequestCustomerSession';

import { ICouponStatus, TalonOneCouponRejectionReason } from './Coupons';
import EffectProps from './Effects/EffectProps';
import EffectType from './Effects/EffectType';
import IEffect from './Effects/IEffect';

import LoggerService from '../../LoggerService';
import ITalonOneCoupon from './Coupons/ITalonOneCoupon';
import TalonOneServiceMock from './TalonOneServiceMock';

import type { ResponseContentItems } from './CustomerSession';
import { CustomerSessionClosureFailedError } from './errors/CustomerSessionClosureFailedError';

/**
 * Talon.One integration service.
 * @see {@link https://docs.talon.one/docs/dev/getting-started/overview Integrating with Talon.One}
 */
export class TalonOneService extends Service {
  /**
   * An AxiosInstance for hitting the Talon.One API.
   * @returns An AxiosInstance.
   */
  @siteCached
  private get client(): AxiosInstance {
    const config = ConfigurationService.getConfig('talonOne');

    const apiKey = config.getSetting('apiKey').value;

    return axios.create({
      baseURL: config.getSetting('baseURL').value,
      headers: {
        Authorization: `ApiKey-v1 ${apiKey}`
      }
    });
  }

  /**
   * The API name of the _additional cost_ that will contain shipping
   * charges.
   *
   * In our Talon integration, shipping charges applied to an order are sent
   * as an additional cost with this name. This way we can easily apply
   * effects to shipping costs only.
   *
   * **NOTE:** The current value of `example` is temporary and will change
   * soon. This value represents the additional cost configured in an
   * walkthrough with the Talon team. So update it when the final additional
   * cost is configured! Suggested name: `shippingCosts`.
   *
   * @returns The above.
   *
   * @see https://docs.talon.one/docs/product/account/dev-tools/managing-additional-costs
   */
  private get shippingAdditionalCostAPIName(): string {
    return ConfigurationService.getConfig('talonOne').getSetting(
      'shippingAdditionalCostAPIName'
    ).value;
  }

  /**
   * The API name of the _custom attribute_ that will contain the code of
   * the shipping method being used for a customer session.
   *
   * @see https://docs.talon.one/docs/dev/concepts/attributes#custom-attributes
   *
   * For our use case, some promotion rules have to factor in the shipping
   * method in use. That's why we configured this custom attribute, in which
   * we send the code of the shipping method selected (e.g. `STG`) for a
   * given customer session.
   *
   * @returns The above.
   */
  private get shippingCodeAttributeAPIName(): string {
    return ConfigurationService.getConfig('talonOne').getSetting(
      'shippingCodeAttributeAPIName'
    ).value;
  }

  /**
   * Retrieves a [Customer Session](https://docs.talon.one/docs/dev/concepts/entities#customer-session).
   * @param customerSessionId - The identifier of the customer session to retrieve.
   * @returns An {@link ICustomerSessionResponse}.
   */
  public async getCustomerSession(
    customerSessionId: string
  ): Promise<ICustomerSessionResponse> {
    const res: AxiosResponse<ICustomerSessionResponse> = await this.client.get(
      `v2/customer_sessions/${customerSessionId}`
    );

    return res.data;
  }

  /**
   * Updates the specified [Customer Session](https://docs.talon.one/docs/dev/concepts/entities#customer-session).
   *
   * @param customerSessionId - The customer session to update.
   * @param customerSession - Customer session data.
   * @param [dry] - If `true`, the changes will not be persisted in Talon.one.
   * [Learn more about dry requests](https://docs.talon.one/docs/dev/integration-api/dry-requests).
   * @param includeCustomerSession - If the actual customer session should be included in the response. Defaults to `false`.
   *
   * @returns The response, in {@link ICustomerSession} form.
   */
  public async updateCustomerSession(
    customerSessionId: string,
    customerSession: IRequestCustomerSession,
    dry: boolean = false,
    includeCustomerSession: boolean = false
  ): Promise<ICustomerSessionResponse> {
    const responseContent: Array<ResponseContentItems> = ['coupons'];

    if (includeCustomerSession) responseContent.push('customerSession');

    const res: AxiosResponse<ICustomerSessionResponse> = await this.client.put(
      `v2/customer_sessions/${customerSessionId}`,
      {
        customerSession,

        // Only include `responseContent` if not empty.
        ...(responseContent.length > 0 && { responseContent })
      },
      {
        params: {
          // Include the dry param only if true.
          ...(dry && { dry })
        }
      }
    );

    return res.data;
  }

  /**
   * Closes the specified customer session.
   *
   * Customer sessions must be closed when their respective orders are placed.
   * Customer sessions can be reopened if necessary (e.g. an order needs to be
   * edited after placing it).
   *
   * @see [Customer Sessions | Talon.one docs](https://docs.talon.one/docs/dev/concepts/entities/customer-sessions)
   *
   * @param customerSessionId - The unique identifier of the customer session
   * to close.
   *
   * @returns The response, in {@link ICustomerSession} form.
   */
  public async closeCustomerSession(
    customerSessionId: string
  ): Promise<ICustomerSessionResponse> {
    try {
      const res: AxiosResponse<ICustomerSessionResponse> =
        await this.client.put(`v2/customer_sessions/${customerSessionId}`, {
          customerSession: {
            state: 'closed'
          }
        });

      return res.data;
    } catch (error) {
      throw new CustomerSessionClosureFailedError(
        'An unexpected error ocurred when closing a Talon.one customer session.',
        { cause: error }
      );
    }
  }

  /**
   * Transforms an {@link ILineItem} into an {@link ICustomerSessionCartItem}.
   *
   * @param item - The {@link ILineItem} to transform.
   * @param options - Transformation options.
   *
   * @returns An equivalent {@link ICustomerSessionCartItem}.
   */
  public lineItemToCustomerSessionItem(
    item: ILineItem,
    options?: {
      /**
       * Whether the retail price on this line item
       * should override the price stored in Talon.One.
       *
       * Defaults to `true`.
       */
      overridePrice: boolean;
    }
  ): ICustomerSessionCartItem {
    const { overridePrice = true } = options ?? {};

    return {
      name: item.name,
      sku: item.sku,
      quantity: item.quantity,

      // If this line item is a product, add its model ID and
      // category as custom attributes
      ...(item.type === LineItemType.Product && {
        attributes: {
          // This needs to be configured in TalonOne by an admin, see HP-1451.
          // modelID: (item as IProductLineItem).modelID,
          // primaryCategory: (item as IProductLineItem).primaryCategory
        }
      }),

      ...(overridePrice && {
        price: MoneyModel.asUnsafeNumber(item.unitPrice.retailPrice)
      })
    };
  }

  /**
   * Transforms an {@link ICart} DTO into an {@link IRequestCustomerSession}
   * that can be sent on a customer session update to Talon.one.
   *
   * @param cart - Cart DTO to use.
   * @param profileId - An optional profile ID to associate the customer
   * session with. Pass the current user's ID here whenever possible.
   *
   * @returns An equivalent {@link IRequestCustomerSession}.
   */
  public cartToCustomerSession(
    cart: DTO<ICart>,
    profileId?: string
  ): IRequestCustomerSession {
    /**
     *  State will default to `open` if not provided anyway so include it
     *  explicitly.
     *
     * @see {@link closeCustomerSession} for the method that actually closes sessions.
     */
    const state = 'open';
    const shippingMethodCode = cart.selectedShippingMethod?.id;

    if (!shippingMethodCode) {
      LoggerService.warn(
        `Cart ${cart.uuid} does not have a shipping method code.` +
          ' Shipping method code will not be included in Talon request.'
      );
    }

    // Do NOT use cart.shippingCost here since it gets calculated until
    // AFTER promotions are revalidated (which is done with this method)
    const shippingCost = cart.selectedShippingMethod?.shippingCost;

    const customerSession: IRequestCustomerSession = {
      state,
      profileId,

      couponCodes: cart.coupons.map((c) => c.code),

      attributes: {
        ...(shippingMethodCode && {
          [this.shippingCodeAttributeAPIName]: shippingMethodCode
        })
      },

      additionalCosts: {
        [this.shippingAdditionalCostAPIName]: {
          price: shippingCost ? MoneyModel.asUnsafeNumber(shippingCost) : 0
        }
      },
      cartItems: cart.items.map((item, idx) => {
        const cartItem = this.lineItemToCustomerSessionItem(item);

        // Include the position in the customer session request for later reference.
        cartItem.position = idx;

        return cartItem;
      })
    };

    return customerSession;
  }

  /**
   * Given a Talon.one customer session response, extract all effects,
   * transform them into their first-party equivalents, and bundle them
   * up as one {@link ICartPromotionUpdates} object.
   *
   * @param response - The {@link ICustomerSessionResponse} to extract the data from.
   * @param [cartItems] - The cart items to reference. If unspecified, the method will
   * try to get them from the supplied response. In case they are also missing in
   * the response, it will throw an error.
   *
   * @returns An {@link ICartPromotionUpdates} object with all the updates to
   * apply to the cart in question and its line items.
   */
  public cartUpdatesFromCustomerSessionResponse(
    response: ICustomerSessionResponse,
    cartItems?: Array<ICustomerSessionCartItem>
  ): ICartPromotionUpdates {
    const { acceptedCoupons } =
      this.couponStatusFromCustomerSessionResponse(response);

    const cartPromotions = this.cartPromosFromCustomerSessionResponse(response);

    const lineItemPromotions = this.lineItemPromosFromCustomerSessionResponse(
      response,
      cartItems
    );

    return {
      coupons: acceptedCoupons,
      cartPromotions,
      lineItemPromotions
    };
  }

  /**
   * Given a Talon.one customer session response, extract effects that
   * are to be applied to the entire cart and turn them into {@link ICartPromotion}
   * objects.
   *
   * @param response - The {@link ICustomerSessionResponse} to extract the data from.
   *
   * @returns The extracted data in `Partial<DTO<ICart>>` form.
   */
  public cartPromosFromCustomerSessionResponse(
    response: ICustomerSessionResponse
  ): Array<ICartPromotion> {
    const { effects } = response;

    if (!effects) return [];

    return (
      effects
        .map((effect) => this.effectToPromotion(effect, response))
        // Only add promotions that have the Cart as a target.
        .filter(
          (promotion) => promotion?.target === PromotionTarget.Cart
        ) as Array<ICartPromotion>
    );
  }

  /**
   * Given a Talon.one customer session response, extract effects that
   * are to be applied to line items and turn them into {@link ILineItemPromotion}
   * objects.
   *
   * @param response - The {@link ICustomerSessionResponse} to extract the data from.
   * @param [cartItems] - The cart items to reference. If unspecified, the method will
   * try to get them from the supplied response. In case they are also missing in
   * the response, it will throw an error.
   *
   * @returns The extracted data in a map, with the line item SKUs as keys and
   * the new promotion arrays as values.
   *
   * @throws An {@link InvalidStateError} if cart items are missing,
   * or if the response references a non-existing item.
   */
  public lineItemPromosFromCustomerSessionResponse(
    response: ICustomerSessionResponse,
    cartItems?: Array<ICustomerSessionCartItem>
  ): Record<string, Array<ILineItemPromotion>> {
    const { effects } = response;

    const customerSessionItems =
      cartItems ?? response.customerSession?.cartItems;

    if (!customerSessionItems)
      throw new InvalidStateError(
        'Cannot extract line item updates from customer session response: No `cartItems` to reference.'
      );

    const updates: Record<string, Array<ILineItemPromotion>> = {};

    for (const item of customerSessionItems) {
      updates[item.sku] = [];
    }

    for (const effect of effects) {
      switch (effect.effectType) {
        case EffectType.SetDiscountPerItem: {
          const { props } = effect as IEffect<EffectType.SetDiscountPerItem>;

          const itemMatch = customerSessionItems?.find(
            (item) => item.position === props.position
          );

          if (!itemMatch)
            throw new InvalidStateError(
              'Cannot parse effect: No cart item matches the specified position.'
            );

          updates[itemMatch.sku].push(
            this.effectToPromotion(effect, response) as ILineItemPromotion
          );

          break;
        }

        default: {
          // Ignore this effect
          break;
        }
      }
    }

    return updates;
  }

  /**
   * Determines the status of each entered coupon in a given {@link ICustomerSessionResponse}
   * and joins them in an {@link ICouponStatus} object.
   *
   * @param response - The {@link ICustomerSessionResponse} to extract the data from.
   * @returns An {@link ICouponStatus} object.
   */
  public couponStatusFromCustomerSessionResponse(
    response: ICustomerSessionResponse
  ): ICouponStatus {
    const { effects } = response;

    // Initialize the object with empty arrays.
    const couponStatus: ICouponStatus = {
      rejectedCoupons: [],
      acceptedCoupons: []
    };

    for (const effect of effects) {
      switch (effect.effectType) {
        case EffectType.RejectCoupon: {
          const {
            props: { value, rejectionReason }
          } = effect as IEffect<EffectType.RejectCoupon>;

          couponStatus.rejectedCoupons.push({
            couponCode: value,
            rejectionReason: this.getCouponRejectionReason(
              rejectionReason as TalonOneCouponRejectionReason
            )
          });

          break;
        }

        case EffectType.AcceptCoupon: {
          const {
            props: { value }
          } = effect as IEffect<EffectType.AcceptCoupon>;

          couponStatus.acceptedCoupons.push({
            code: value

            // Talon doesn't include coupon description on this effect.
          });
          break;
        }

        default: {
          // This effect is unrelated to coupons logic; ignore.
          break;
        }
      }
    }

    const acceptedCouponCodes = couponStatus.acceptedCoupons.map((c) => c.code);

    // Filter out coupons that were accepted from the rejected coupons array.
    //
    // This needs to be done since in a Talon Campaign with multiple coupon rules,
    // one rule might reject the coupon but another one might accept it.
    // If the code is accepted by at least one rule, don't include it as rejected.
    couponStatus.rejectedCoupons = couponStatus.rejectedCoupons.filter(
      (c) => !acceptedCouponCodes.includes(c.couponCode)
    );

    return couponStatus;
  }

  /**
   * Removes the item position from an effect name.
   * @param name - The effect name.
   * @returns The name without the item position at the end.
   */
  private sanitizeEffectName(name: string): string {
    const split = [...name];

    let endPos: number | null = null;

    for (let i = split.length - 1; i >= 0; i--) {
      if (split[i] === '#') {
        endPos = i;
        break;
      }
    }

    if (!endPos || endPos <= 0) {
      return name;
    }

    return split.slice(0, endPos).join('');
  }

  /**
   * Gets the specified coupon from a provided array of coupons.
   *
   * @param couponID - The ID of the coupon to retrieve.
   * @param coupons - The array of coupons to search on.
   *
   * @returns The requested coupon, or `undefined` if either a coupons
   * array is not provided or the requested coupon was not found in the array.
   */
  private getCouponCodeByID(
    couponID: number,
    coupons?: Array<ITalonOneCoupon>
  ): string | undefined {
    if (!coupons) {
      // If not coupons array was provided, return undefined
      return undefined;
    }

    const matchedCoupon = coupons.find((c) => c.id === couponID);

    return matchedCoupon?.value;
  }

  /**
   * Transforms an {@link IEffect} to an {@link IPromotion}.
   *
   * @param effect - The {@link IEffect} to transform.
   * @param response - The customer session response received from Talon.
   *
   * @returns An equivalent {@link IPromotion}.
   *
   * @throws An {@link InvalidArgumentError} if the supplied effect is invalid.
   * @throws An {@link NotImplementedError} if the supplied effect is not yet supported
   * by our first-party promotions logic.
   */
  public effectToPromotion<T extends EffectType>(
    effect: IEffect<T>,
    response: ICustomerSessionResponse
  ): IPromotion;

  /**
   * Transforms a "SetDiscount" {@link IEffect effect} to an {@link ICartDiscount}.
   *
   * @param effect - The effect to transform.
   * @param response - The customer session response received from Talon.
   *
   * @returns An equivalent {@link ICartDiscount}.
   *
   * @throws An {@link InvalidArgumentError} if the supplied effect is invalid.
   */
  public effectToPromotion(
    effect: IEffect<EffectType.SetDiscount>,
    response: ICustomerSessionResponse
  ): ICartDiscount;

  /**
   * Transforms a "SetDiscountPerItem" {@link IEffect effect} to an {@link ILineItemDiscount}.
   *
   * @param effect - The effect to transform.
   * @param response - The customer session response received from Talon.
   *
   * @returns An equivalent {@link ILineItemDiscount}.
   *
   * @throws An {@link InvalidArgumentError} if the supplied effect is invalid.
   */
  public effectToPromotion(
    effect: IEffect<EffectType.SetDiscountPerItem>,
    response: ICustomerSessionResponse
  ): ILineItemDiscount;

  /**
   * For "RejectCoupon" {@link IEffect effects}, returns `null`.
   *
   * @param effect - The effect to transform.
   * @param response - The customer session response received from Talon.
   *
   * @returns A `null` value.
   *
   * @throws An {@link InvalidArgumentError} if the supplied effect is invalid.
   * @throws An {@link NotImplementedError} if the supplied effect is not yet supported
   * by our first-party promotions logic.
   */
  public effectToPromotion(
    effect: IEffect<EffectType.RejectCoupon>,
    response: ICustomerSessionResponse
  ): null;

  /**
   * For "AcceptCoupon" {@link IEffect effects}, returns `null`.
   *
   * @param effect - The effect to transform.
   * @param response - The customer session response received from Talon.
   *
   * @returns A `null` value.
   *
   * @throws An {@link InvalidArgumentError} if the supplied effect is invalid.
   * @throws An {@link NotImplementedError} if the supplied effect is not yet supported
   * by our first-party promotions logic.
   */
  public effectToPromotion(
    effect: IEffect<EffectType.AcceptCoupon>,
    response: ICustomerSessionResponse
  ): null;

  /**
   * Transforms an {@link IEffect} to an {@link IPromotion}.
   *
   * @param effect - The {@link IEffect} to transform.
   * @param response - The customer session response received from Talon.
   *
   * @returns An equivalent {@link IPromotion}, or `null` if the supplied effect
   * is not supposed to be made into a promotion.
   *
   * @throws An {@link InvalidArgumentError} if the supplied effect is invalid.
   * @throws An {@link NotImplementedError} if the supplied effect is not yet supported
   * by our first-party promotions logic.
   */
  public effectToPromotion<T extends EffectType>(
    effect: IEffect<T>,
    response: ICustomerSessionResponse
  ): Nullable<IPromotion> {
    const {
      effectType,
      props,
      rulesetId,
      campaignId,
      ruleName,
      triggeredByCoupon
    } = effect;
    const { coupons } = response;

    const commonProperties: Partial<IPromotion> = {
      // The ruleset ID will be the promotion ID.
      id: rulesetId.toString(),
      campaignID: campaignId.toString(),
      name: ruleName,
      couponCode: triggeredByCoupon
        ? this.getCouponCodeByID(triggeredByCoupon, coupons)
        : undefined,
      couponID: triggeredByCoupon ? triggeredByCoupon.toString() : undefined
    };

    switch (effectType) {
      case EffectType.SetDiscount: {
        const { value, name } = props as EffectProps[EffectType.SetDiscount];

        const promotion = {
          ...commonProperties,

          // This effect is a discount applied to the entire cart.
          target: PromotionTarget.Cart,
          type: PromotionType.CartDiscount,

          label: this.sanitizeEffectName(name),
          visible: true,

          applyTo: 'total',
          mode: DiscountMode.Fixed,
          value
        } as ICartDiscount;

        return promotion;
      }

      case EffectType.SetDiscountPerItem: {
        const { value, name } =
          props as EffectProps[EffectType.SetDiscountPerItem];

        const promotion = {
          ...commonProperties,

          // This effect is a discount applied to a specific line item.
          target: PromotionTarget.LineItem,
          type: PromotionType.LineItemDiscount,

          label: this.sanitizeEffectName(name),
          visible: true,

          // Only using 'once' here since Talon sends multiple discounts to
          // account for item quantities greater than 1.
          apply: 'once',

          // Since Talon always gives the discount amount, just use `fixed` for now.
          mode: DiscountMode.Fixed,

          value
        } as ILineItemDiscount;

        return promotion;
      }

      case EffectType.SetDiscountPerAdditionalCost: {
        const { name, value, additionalCost } =
          props as EffectProps[EffectType.SetDiscountPerAdditionalCost];

        // If the name of the additional cost this discount is being applied to
        // matches the additional cost that handles shipping charges...
        if (additionalCost === this.shippingAdditionalCostAPIName) {
          // This is a shipping discount.

          const promotion = {
            ...commonProperties,

            // This effect is a discount applied to a cart...
            target: PromotionTarget.Cart,
            type: PromotionType.CartDiscount,

            label: name,
            visible: true,

            // ...but applied to shipping costs specifically.
            applyTo: 'shipping',
            mode: DiscountMode.Fixed,
            value
          } as ICartDiscount;

          return promotion;
        }

        throw new NotImplementedError(
          'Cannot transform Talon.One effect to Promotion:' +
            ' The "SetDiscountPerAdditionalCost" effect type is only' +
            ' supported for shipping discounts at the moment.'
        );
      }

      case EffectType.RejectCoupon:
      case EffectType.AcceptCoupon: {
        // Ignore these effects.
        return null;
      }

      default: {
        // The effect type is either unsupported or invalid.
        if (!Object.values(EffectType).includes(effect.effectType)) {
          // Effect type is invalid
          throw new InvalidArgumentError(
            `Cannot transform Talon.One effect to Promotion: Invalid effect type "${effectType}"`
          );
        }

        // Effect type is not implemented.
        LoggerService.warn(
          new NotImplementedError(
            `Cannot transform Talon.One effect to Promotion: Effect type "${effectType}" is not yet supported.`
          )
        );

        return null;
      }
    }
  }

  /**
   * Given a Talon.one coupon rejection reason, retrieve the matching first-party
   * coupon rejection reason.
   *
   * @param talonRejectionReason - Talon.one rejection reason.
   * @returns The matching first party {@link CouponRejectionReason}.
   *
   * @throws An {@link InvalidArgumentError} if the supplied Talon.one reason is invalid.
   * @throws A {@link NotImplementedError} if the supplied Talon.one reason doesn't yet have a mapped
   * first-party reason.
   */
  public getCouponRejectionReason(
    talonRejectionReason: TalonOneCouponRejectionReason
  ): CouponRejectionReason {
    switch (talonRejectionReason) {
      case TalonOneCouponRejectionReason.CouponExpired:
        return CouponRejectionReason.CouponExpired;

      case TalonOneCouponRejectionReason.CouponLimitReached:
        return CouponRejectionReason.CouponLimitReached;

      case TalonOneCouponRejectionReason.CouponNotFound:
        return CouponRejectionReason.CouponNotFound;

      case TalonOneCouponRejectionReason.CouponRecipientDoesNotMatch:
      case TalonOneCouponRejectionReason.CouponRejectedByCondition:
      case TalonOneCouponRejectionReason.EffectCouldNotBeApplied:
      case TalonOneCouponRejectionReason.ProfileLimitReached:
      case TalonOneCouponRejectionReason.CouponPartOfNotTriggeredCampaign:
      case TalonOneCouponRejectionReason.CouponReservationRequired:
      case TalonOneCouponRejectionReason.ProfileRequired:
        return CouponRejectionReason.CouponNotApplicable;

      case TalonOneCouponRejectionReason.CouponPartOfNotRunningCampaign:
      case TalonOneCouponRejectionReason.CouponStartDateInFuture:
        return CouponRejectionReason.CouponNotActive;

      default: {
        if (
          Object.values(TalonOneCouponRejectionReason).includes(
            talonRejectionReason
          )
        ) {
          // This is a valid Talon rejection reason, but it isn't mapped to
          // a first party rejection reason.
          throw new NotImplementedError(
            `No first-party coupon rejection reason matches Talon.one reason "${talonRejectionReason}".`
          );
        }

        throw new InvalidArgumentError(
          `Cannot get coupon rejection reason: Invalid Talon.one reason "${talonRejectionReason}" provided.`
        );
      }
    }
  }
}

export default TalonOneService.withMock(
  new TalonOneServiceMock()
) as unknown as TalonOneService;
