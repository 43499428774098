import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { UnableToCreateResourceError } from '@/utils/errors';

export const { UnableToCreateUserAccountError } = errorFactory(
  'UnableToCreateUserAccountError',
  UnableToCreateResourceError,
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/** Error to use when a user account could not be created. */
export type UnableToCreateUserAccountError = InstanceTypeOf<
  typeof UnableToCreateUserAccountError
>;
