import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

import { InvalidStateError, ResourceNotFoundError } from '@/utils/errors';
import Service from '../../../Service';
import ConfigurationService from '../../ConfigurationService';

import type {
  IAuthenticatedUserTokens,
  ILoginCredentials
} from '../../../models/User/AuthenticatedUser';
import siteCached from '../../../utils/siteCached';
import { InvalidCredentialsError } from './InvalidCredentialsError';

/**
 * Integration service for Amazon Cognito.
 * @see {@link https://docs.aws.amazon.com/cognito/latest/developerguide/what-is-amazon-cognito.html Amazon Cognito}.
 */
class CognitoService extends Service {
  /**
   * Gets a valid Cognito User Pool for the current locale and site.
   * @returns A valid Cognito User Pool for the current locale and site.
   */
  @siteCached
  private get userPool(): CognitoUserPool {
    const config = ConfigurationService.getConfig('cognito');

    const UserPoolId = config.getSetting('userPoolID.endUserPool').value;
    const ClientId = config.getSetting('clientID.endUserClient').value;

    return new CognitoUserPool({
      UserPoolId,
      ClientId
    });
  }

  /**
   * Transforms a {@link CognitoUserSession} to a first-party {@link IAuthenticatedUserTokens} object.
   * @param cognitoSession - The {@link CognitoUserSession Cognito User Session} to transform.
   * @returns An equivalent {@link IAuthenticatedUserTokens}.
   */
  private transformUserSession(
    cognitoSession: CognitoUserSession
  ): IAuthenticatedUserTokens {
    return {
      accessToken: cognitoSession.getAccessToken().getJwtToken(),
      idToken: cognitoSession.getIdToken().getJwtToken(),
      refreshToken: cognitoSession.getRefreshToken().getToken()
    };
  }

  /**
   * Creates a {@link CognitoUser} object from a username and the configured user pool.
   * @param username - Username.
   * @returns A valid {@link CognitoUser} object.
   */
  private getCognitoUser(username: string): CognitoUser {
    return new CognitoUser({
      Username: username,
      Pool: this.userPool
    });
  }

  /**
   * Confirms a registered user's email with the received confirmation code.
   * @param username - Username to confirm.
   * @param confirmationCode - The confirmation code sent to the user's email.
   */
  public async confirmRegistration(
    username: string,
    confirmationCode: string
  ): Promise<void> {
    const cognitoUser = this.getCognitoUser(username);

    const result = await new Promise<string>((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });

    if (result !== 'SUCCESS') {
      throw new InvalidStateError(
        `Received unexpected result from Cognito during user confirmation: ${result}`
      );
    }
  }

  /**
   * Sends a new confirmation code to a user's email.
   * @param username - User to resend the confirmation code to.
   */
  public async resendConfirmationCode(username: string): Promise<void> {
    const cognitoUser = this.getCognitoUser(username);

    await new Promise<string>((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  /**
   * Gets the cognito account ID for the user logging in.
   * @param cognitoUser - The cognito user to fetch the user attributes.
   * @returns - The account ID for the given cognito user.
   */
  private async getAccountId(cognitoUser: CognitoUser): Promise<string> {
    return new Promise((resolve, reject) => {
      // Callback to fetch the cognito user attributes.
      cognitoUser.getUserAttributes((err, attributes): void => {
        if (err) {
          reject(err);
        }
        if (!attributes) {
          reject(
            new ResourceNotFoundError(
              'No attributes were found for the given Cognito user.'
            )
          );
          return;
        }
        // Get the 'custom:accountId' cognito custom user attribute.
        const accountIdCustomAttribute = attributes.find(
          (attribute: { getName: () => string }) =>
            attribute.getName() === 'custom:accountId'
        );
        // Use the 'custom:accountId' value.
        if (accountIdCustomAttribute) {
          resolve(accountIdCustomAttribute.getValue());
        } else {
          reject(
            new ResourceNotFoundError(
              'User attribute `custom:accountId` was missing from Cognito user.'
            )
          );
        }
      });
    });
  }

  /**
   * Logs a user in.
   * @param credentials - The user's login credentials.
   * @returns A new {@link IAuthenticatedUserTokens} representing the user's newly opened session.
   */
  public async logIn(
    credentials: ILoginCredentials
  ): Promise<IAuthenticatedUserTokens & { accountID: string }> {
    const { email, password } = credentials;
    const cognitoUser = this.getCognitoUser(email);

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    try {
      const userSession = await new Promise<CognitoUserSession>(
        (resolve, reject) => {
          cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
              resolve(result);
            },

            onFailure: reject
          });
        }
      );
      const accountID = await this.getAccountId(cognitoUser);
      const transformedUserSession = this.transformUserSession(userSession);
      return { ...transformedUserSession, accountID };
    } catch (err) {
      if ((err as Error).message === 'Incorrect username or password.') {
        throw new InvalidCredentialsError('Invalid username or password.');
      }

      throw err;
    }
  }

  /**
   * Refreshes a user session with a refresh token.
   * @param username - The user's username.
   * @param refreshToken - A refresh token from the user's current {@link session IAuthenticatedUserTokens}.
   * @returns The new, refreshed session.
   */
  public async refreshTokens(
    username: string,
    refreshToken: string
  ): Promise<IAuthenticatedUserTokens> {
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    const cognitoUser = this.getCognitoUser(username);

    const userSession = await new Promise<CognitoUserSession>(
      (resolve, reject) => {
        cognitoUser.refreshSession(token, (error, session) => {
          if (error) {
            reject(error);
          } else {
            resolve(session);
          }
        });
      }
    );

    return this.transformUserSession(userSession);
  }

  /**
   * Initiates a password reset flow and sends a verification code to the
   * user's email address.
   *
   * This method must be called to generate the verification code `resetPassword`
   * requires.
   *
   * @param username - The user's username.
   */
  public async requestPasswordResetCode(username: string): Promise<void> {
    const cognitoUser = this.getCognitoUser(username);

    const response = await new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }

  /**
   * Resets a user's password with a verification code. Verification codes can
   * be generated by calling `requestPasswordResetCode`.
   *
   * @param username - The user's username.
   * @param verificationCode - The verification code sent to the user's email address.
   * @param newPassword - The new password.
   */
  public async resetPassword(
    username: string,
    verificationCode: string,
    newPassword: string
  ): Promise<void> {
    const cognitoUser = this.getCognitoUser(username);

    const response = await new Promise<string>((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: resolve,
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }

  /**
   * Signs a user out and invalidates the active sessions.
   * @param username - The user's username.
   */
  public async signOut(username: string): Promise<void> {
    const cognitoUser = this.getCognitoUser(username);

    await new Promise<void>((resolve, reject) => {
      cognitoUser.signOut(resolve);
    });
  }
}

// TODO: Mock this.
export default new CognitoService();
