'use client';

// This must be a Client Component since it receives callbacks from other
// components.

import { FunctionComponent, SyntheticEvent, EventHandler } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { InvalidArgumentError } from '@/utils/errors';
import IStylable from '../../traits/IStylable';

import { IconTypes, icons } from './IconTypes';
import { IconAnimations } from './IconAnimations';

import S from './styles.module.scss';

/**
 * The size prop to be turned into the size class.
 */
export enum IconSizeProp {
  SizeXXS,
  SizeXS,
  SizeSM,
  Size1X,
  Size2X,
  Size3X,
  SizeHeavy,
  SizeLG,
  SizeXL
}

/**
 * Props for customizing the Icon Component.
 */
export interface IIconProps extends IStylable {
  /** The icon following the icons available in the IconTypes. */
  icon: IconTypes;

  /** Size of the Icon based on FontAwesome sizes. */
  size?: IconSizeProp;

  /**
   * The color of the Icon itself. This will override any color
   * set by the {@see IIconProps.style}.
   */
  color?: string;

  /** Title for the icon container if it is a button. */
  title?: string;

  /** Label for screen readers on the button. */
  ariaLabel?: string;

  /**
   * Click event.
   */
  onClick?: EventHandler<SyntheticEvent>;

  /** Key press event. */
  onKeyPress?: EventHandler<SyntheticEvent>;

  /** Animation enum of css classes. */
  animation?: IconAnimations;
}

/**
 * Gets the size class for the given size prop.
 * @param size - The passed in size prop using the `IconSizeProp` enum.
 * @returns - The class to be used on the icon wrapper.
 * @throws - If an invalid size is used, should not build.
 */
const getSizeFromProp = (size: IconSizeProp): string => {
  switch (size) {
    case IconSizeProp.SizeXXS:
      return S['size-xxs'];
    case IconSizeProp.SizeXS:
      return S['size-xs'];
    case IconSizeProp.SizeSM:
      return S['size-sm'];
    case IconSizeProp.Size1X:
      return S['size-1x'];
    case IconSizeProp.Size2X:
      return S['size-2x'];
    case IconSizeProp.Size3X:
      return S['size-3x'];
    case IconSizeProp.SizeHeavy:
      return S['size-heavy'];
    case IconSizeProp.SizeLG:
      return S['size-lg'];
    case IconSizeProp.SizeXL:
      return S['size-xl'];
    default:
      throw new InvalidArgumentError(`Size ${size} is an invalid icon size.`);
  }
};

/**
 * Darkens the background when opening a modal or a drawer.
 */
export const Icon: FunctionComponent<IIconProps> = ({
  icon,
  size = IconSizeProp.Size1X,
  className,
  id,
  color,
  title,
  ariaLabel,
  style,
  onClick,
  onKeyPress,
  animation
}) => {
  const iconClass = classes(className ?? '', (animation && S[animation]) ?? '');
  const sizeClass = getSizeFromProp(size);

  const styleColor = color ? { color } : {};
  const styleWithColor = { ...style, ...styleColor };

  return (
    <>
      {onClick ? (
        // Not using the `Button` component here since it would
        // case a dependency cycle.
        <div
          id={id}
          role="button"
          tabIndex={0}
          title={title}
          aria-label={ariaLabel}
          style={styleWithColor}
          onClick={onClick ?? undefined}
          onKeyPress={onKeyPress ?? onClick ?? undefined}
          className={classes(iconClass, S.wrapper, S.interactive, sizeClass)}
        >
          {icons[icon]}
        </div>
      ) : (
        <div
          style={styleWithColor}
          className={classes(iconClass, S.wrapper, sizeClass)}
        >
          {icons[icon]}
        </div>
      )}
    </>
  );
};

export { IconTypes } from './IconTypes';
export { IconAnimations } from './IconAnimations';
