import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { UnableToCreateUserAccountError } from './UnableToCreateUserAccountError';

export const { UserAccountAlreadyExistsError } = errorFactory(
  'UserAccountAlreadyExistsError',
  UnableToCreateUserAccountError,
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/**
 * Error to use when a user account could not be created because the account with the
 * specified email address already exists.
 */
export type UserAccountAlreadyExistsError = InstanceTypeOf<
  typeof UserAccountAlreadyExistsError
>;
