import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { InvalidStateError } from '@/utils/errors';
import Service from '../../../Service';
import SessionService from '../../SessionService';
import ConfigurationService from '../../ConfigurationService';
import { EnvironmentService } from '../../EnvironmentService';
import I18NService from '../../I18NService';
import { PageService } from '../../PageService';
import LoggerService from '../../LoggerService';

import CoveoAPIService from '../../../serverless/integrations/CoveoAPIService';

import type {
  IInteractionProductDetails,
  IInteractionSearchDetails
} from '../../UserInteractionService';

import type { IClickAnalyticsPayload } from './IClickAnalyticsPayload';
import type ICoveoAnalyticsQuery from './ICoveoAnalyticsQuery';
import type { ICoveoAnalyticsResponse } from './ICoveoAnalyticsResponse';
import type { ISearchAnalyticsPayload } from './ISearchAnalyticsPayload';

import CoveoAnalyticsServiceMock from './CoveoAnalyticsServiceMock';

/**
 * The CoveoAnalyticsService handles taking client side click and search events
 * and sending them through to the api route to be handled by the CoveoAPIService.
 * @see https://docs.coveo.com/en/1373/build-a-search-ui/log-usage-analytics-events
 */
class CoveoAnalyticsService extends Service {
  private client: AxiosInstance;

  /** Initializes the service. */
  public constructor() {
    super();

    this.client = axios.create({
      baseURL: '/api/coveo'
    });
  }

  /**
   * Executes a Coveo search query.
   * @param params - Search query parameters.
   * @returns The response in an {@link ICoveoSearchResponse} object.
   */
  private async query(
    params: ICoveoAnalyticsQuery
  ): Promise<ICoveoAnalyticsResponse> {
    if ((typeof window === "undefined")) {
      CoveoAPIService.analyticsQuery(params);
    }

    // If not, forward the request to the server.
    const data: AxiosResponse<ICoveoAnalyticsResponse> = await this.client.post(
      'analytics',
      params
    );

    return data;
  }

  /**
   * Prepares the payload for the click event
   * and sends off the query to the api route.
   * This is usually made from the interaction hook after the
   * user clicks a product tile on a category or search.
   * @param interactionDetails - InteractionDetails with product data.
   * @returns The CoveoResponse or an Error.
   */
  public async clickEvent(
    interactionDetails: IInteractionProductDetails
  ): Promise<ICoveoAnalyticsResponse | Error> {
    const coveoConfig = ConfigurationService.getConfig('coveo');
    const { product } = interactionDetails;
    const { page } = PageService;

    try {
      const productListMetadata = page?.productListMetadata;
      if (!productListMetadata) {
        throw new InvalidStateError(
          `The page does not contain productListMetaData, which is required to make a Coveo click Analytics event.`
        );
      }

      const sessionId = (await SessionService.currentSession).id;

      const searchData = productListMetadata.coveoData[product.sku] ?? null;
      if (!searchData) {
        throw new InvalidStateError(
          `SearchData is not found for the product: ${product.sku} and is required to make a Coveo click Analytics event.`
        );
      }

      const payload: IClickAnalyticsPayload = {
        actionCause: 'documentOpen',
        // TODO: Tie these next two properties to the account rather than session.
        clientId: sessionId,
        anonymous: true,
        documentPosition: searchData.documentPosition,
        documentTitle: searchData.title,
        documentUri: searchData.uri,
        documentUriHash: searchData.uriHash,
        documentUrl: searchData.uri,
        language: I18NService.currentLocale.language,
        originLevel1: coveoConfig.getSetting('searchHub').value,
        originLevel2: coveoConfig.getSetting('searchHub').value,
        searchQueryUid: searchData.searchUid,
        sourceName: searchData.sourceName,
        userAgent: window.navigator.userAgent,
        customData: {
          contentIDKey: 'permanentid',
          contentIDValue: searchData.permanentId,
          name: searchData.title
        }
      };

      const queryParams: ICoveoAnalyticsQuery = {
        endpoint: '/click',
        payload
      };

      return await this.query(queryParams);
    } catch (error) {
      if (error instanceof InvalidStateError) {
        LoggerService.error(error.message);
      }

      return error as Error;
    }
  }

  /**
   * Prepares the search payload and sends it off for the api service.
   * Most of the payload is a aquired from the search response. The main call
   * is made from the q route on the server-side.
   * @param interactionDetails - The search details contains the search data
   * object assembled from the CoveoSearch response.
   * @returns The CoveoResponse or an Error.
   */
  public async searchEvent(
    interactionDetails: IInteractionSearchDetails
  ): Promise<ICoveoAnalyticsResponse | Error> {
    const coveoConfig = ConfigurationService.getConfig('coveo');

    const { searchData } = interactionDetails;
    const sessionId = (await SessionService.currentSession).id;

    try {
      if (!searchData) {
        throw new InvalidStateError(
          `SearchData is not found and is needed to make a search analytics event`
        );
      }
      const payload: ISearchAnalyticsPayload = {
        actionCause: 'seachboxSubmit',
        // TODO: Tie these next two properties to the account rather than session.
        clientId: sessionId,
        anonymous: true,
        language: I18NService.currentLocale.language,
        originLevel1: coveoConfig.getSetting('searchHub').value,
        originLevel2: coveoConfig.getSetting('searchHub').value,
        searchQueryUid: searchData.searchQueryUid,
        userAgent: window.navigator.userAgent,
        queryText: searchData.queryText,
        responseTime: searchData.responseTime
      };

      const queryParams: ICoveoAnalyticsQuery = {
        endpoint: '/search',
        payload
      };

      return await this.query(queryParams);
    } catch (error) {
      if (error instanceof InvalidStateError) {
        LoggerService.error(error.message);
      }

      return error as Error;
    }
  }
}

export default CoveoAnalyticsService.withMock(
  new CoveoAnalyticsServiceMock(CoveoAnalyticsService)
) as unknown as CoveoAnalyticsService;

export type { CoveoAnalyticsService as CoveoAnalyticsServiceType };
