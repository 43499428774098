import { CSSLength } from '@/type-utils/css';

/**
 * Returns whether the passed value is a valid CSS "length". The input should be
 * a string representing a CSS "length" value in the form of either a integral or
 * real number immediately followed by a valid CSS "length" unit.
 * @param value - A string value representing the CSS "length" value to validate.
 * @returns `true` if the provided value is a valid CSS "length".
 * @example ```ts
 * isValidCSSLength('100px'); // true
 * isValidCSSLength('100 px'); // false
 * isValidCSSLength('100ffs'); // false
 * ```
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/length
 */
export const isValidCSSLength = (value: string): value is CSSLength =>
  // The regular expression is essentially: any integral or real number followed by a
  // CSS "length" unit (which are listed in the group) with no whitespace.
  /^\d+(\.\d*)?(cap|ic|lh|rlh|em|ex|%|px|cm|mm|in|pt|Q|pc|ch|rem|vh|vw|vmin|vmax|svw|lvw|dvw|svh|lvh|dvh|svmax|lvmax|dvmax|svmin|lvmin|dvmin|vb|vi|svb|lvb|dvb|svi|lvi|dvi)$/.test(
    value
  );
