import { InvalidArgumentError, InvalidStateError } from '@/utils/errors';

import { DTO } from '@/type-utils';

import { IOrderLine } from '@/services/models/Order';
import { exhaustiveGuard } from '@/utils/function-utils';
import Service from '../../Service';
import type { CartModel, ICart } from '../../models/Cart';
import { CouponRejectionReason } from '../../models/Cart/Coupon';
import { ILineItem } from '../../models/Cart/LineItem';
import {
  DiscountMode,
  ICartPromotionUpdates
} from '../../models/Cart/Promotion';
import type { ILineItemDiscount } from '../../models/Cart/Promotion/LineItem';
import { IMoney, MoneyModel } from '../../models/Money';

import { msg } from '../I18NService';
import TalonOneService from '../integrations/TalonOneService';
import { CouponAlreadyAppliedError } from './CouponAlreadyAppliedError';
import { CouponExpiredError } from './CouponExpiredError';
import { CouponLimitReachedError } from './CouponLimitReachedError';
import { CouponNotActiveError } from './CouponNotActiveError';
import { CouponNotApplicableError } from './CouponNotApplicableError';
import { CouponNotFoundError } from './CouponNotFoundError';

import PromotionsServiceMock from './PromotionsServiceMock';
import { RejectedCouponError } from './RejectedCouponError';
import { cart_couponCodes_rejection_expired } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_expired";
import { cart_couponCodes_rejection_limitReached } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_limitReached";
import { cart_couponCodes_rejection_notFound } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_notFound";
import { cart_couponCodes_rejection_notActive } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_notActive";
import { cart_couponCodes_rejection_notApplicable } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_notApplicable";
import { cart_couponCodes_rejection_alreadyApplied } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_alreadyApplied";

/** Abstracts operations related to Promotions. */
export class PromotionsService extends Service {
  /**
   * Given a discount mode (present in discount-type promotions), calculate
   * the discount amount to apply.
   *
   * @param originalAmount - The original amount that the discount is being applied to.
   * @param discountValue - The discount value.
   * @param discountMode - The discount mode.
   *
   * @returns The calculated discount amount (number).
   *
   * @throws An {@link InvalidArgumentError} whenever the discount value or mode are invalid.
   */
  public getDiscountAmount(
    originalAmount: IMoney,
    discountValue: number,
    discountMode: DiscountMode
  ): IMoney {
    switch (discountMode) {
      case DiscountMode.Fixed: {
        // Discount value is a fixed amount.
        return MoneyModel.fromAmount(discountValue);
      }

      case DiscountMode.Percentage: {
        // Discount value is a percentage of the original amount.

        if (discountValue < 0 || discountValue > 100) {
          throw new InvalidArgumentError(
            `Cannot get amount after discount: Percentage type discounts must have a value between 0 and 100. Received: ${discountValue}`
          );
        }

        const moneyModel = MoneyModel.fromAmount(originalAmount);
        const discountAmount = moneyModel
          .multiplyBy(discountValue)
          .divideBy(100);

        return discountAmount;
      }

      case DiscountMode.Override: {
        // Discount value will take the place of the original amount.
        const moneyModel = MoneyModel.fromAmount(originalAmount);
        const discountAmount = moneyModel.subtractAmount(discountValue);
        return discountAmount;
      }
    }

    return exhaustiveGuard(
      discountMode,
      `Cannot get amount after discount: Invalid mode "${discountMode}" was supplied.`
    );
  }

  /**
   * Calculates the discount amount for a line item.
   *
   * @param lineItem - The line item to calculate the discount for.
   * @param discount - The discount to apply.
   *
   * @returns The discount amount.
   */
  public getLineItemDiscountAmount(
    lineItem: Pick<ILineItem, 'quantity' | 'subtotal' | 'unitPrice'>,
    discount: ILineItemDiscount
  ): IMoney {
    const { quantity, subtotal, unitPrice } = lineItem;
    const { apply, value, mode } = discount;

    // If this discount will be applied per unit...
    if (apply === 'forEach') {
      const initialAmount =
        // ...use the current price if available...
        unitPrice.currentPrice ??
        // ... and fall back to the retail price if current price is not present.
        unitPrice.retailPrice;

      const discountAmount = this.getDiscountAmount(initialAmount, value, mode);

      // Multiply the discount amount by the quantity of the line item.
      return MoneyModel.from(discountAmount).multiplyBy(quantity);
    }

    // If this discount is to be applied to the whole amount, just use the subtotal.
    const initialAmount = subtotal;

    return this.getDiscountAmount(initialAmount, value, mode);
  }

  /**
   * Calculates the discount amount for an {@link IOrderLine order line}.
   *
   * @param orderLine - The line to calculate the discount for.
   * @param discount - The discount to apply.
   *
   * @returns The discount amount.
   */
  public getOrderLineDiscountAmount(
    orderLine: Pick<IOrderLine, 'quantity' | 'netTotal' | 'unitPrice'>,
    discount: ILineItemDiscount
  ): IMoney {
    const { quantity, netTotal, unitPrice } = orderLine;
    const { apply, value, mode } = discount;

    // If this discount will be applied per unit...
    if (apply === 'forEach') {
      const discountAmount = this.getDiscountAmount(unitPrice, value, mode);

      // Multiply the discount amount by the quantity of the line item.
      return MoneyModel.from(discountAmount).multiplyBy(quantity);
    }

    // If this discount is to be applied to the whole amount, just use the subtotal.
    const initialAmount = netTotal;

    return this.getDiscountAmount(initialAmount, value, mode);
  }

  /**
   * Throws the appropriate error for a coupon rejection.
   * @param  rejectionReason - The reason this is being rejected.
   * @param couponCode - The code that was used when the error occurred.
   * @throws Always throws an error depending on the rejection reason.
   */
  private throwRejectedCouponError(
    rejectionReason: CouponRejectionReason,
    couponCode: string
  ): Error {
    switch (rejectionReason) {
      case CouponRejectionReason.CouponExpired: {
        throw new CouponExpiredError(
          `Coupon code "${couponCode}" rejected: ${rejectionReason}`
        );
      }
      case CouponRejectionReason.CouponLimitReached: {
        throw new CouponLimitReachedError(
          `Coupon code "${couponCode}" rejected: ${rejectionReason}`
        );
      }
      case CouponRejectionReason.CouponNotFound: {
        throw new CouponNotFoundError(
          `Coupon code "${couponCode}" rejected: ${rejectionReason}`
        );
      }
      case CouponRejectionReason.CouponNotActive: {
        throw new CouponNotActiveError(
          `Coupon code "${couponCode}" rejected: ${rejectionReason}`
        );
      }
      case CouponRejectionReason.CouponNotApplicable: {
        throw new CouponNotApplicableError(
          `Coupon code "${couponCode}" rejected: ${rejectionReason}`
        );
      }
      default:
        throw new InvalidArgumentError(
          `Cannot throw the appropriate rejection error: Unknown rejection reason "${rejectionReason}" specified.`
        );
    }
  }

  /**
   * Returns the localized message that describes the rejection reason.
   * @param  error - The reason this is being rejected.
   * @returns The localized message that describes this error.
   * @throws An {@link InvalidArgumentError} if the rejection reason of this error is invalid.
   */
  public getRejectionReasonMessage(error: RejectedCouponError): string {
    switch (true) {
      case error instanceof CouponExpiredError:
        return msg(cart_couponCodes_rejection_expired);
      case error instanceof CouponLimitReachedError:
        return msg(cart_couponCodes_rejection_limitReached);
      case error instanceof CouponNotFoundError:
        return msg(cart_couponCodes_rejection_notFound);
      case error instanceof CouponNotActiveError:
        return msg(cart_couponCodes_rejection_notActive);
      case error instanceof CouponNotApplicableError:
        return msg(cart_couponCodes_rejection_notApplicable);
      case error instanceof CouponAlreadyAppliedError:
        return msg(cart_couponCodes_rejection_alreadyApplied);
      default:
        throw new InvalidArgumentError(
          `Cannot get coupon rejection intl message: Unknown rejection error "${typeof error}" specified.`
        );
    }
  }

  /**
   * Given a Cart, retrieve its applicable promotions.
   *
   * @param cart - The cart to retrieve the promotions for.
   *
   * @returns A promise that will resolve with the updates
   * (promotions and coupon) to apply to the supplied cart.
   */
  public async getCartPromotions(
    cart: DTO<ICart>
  ): Promise<ICartPromotionUpdates> {
    const transformedCart = TalonOneService.cartToCustomerSession(
      cart,
      cart.ownerID
    );

    const response = await TalonOneService.updateCustomerSession(
      cart.uuid,
      transformedCart
    );

    return TalonOneService.cartUpdatesFromCustomerSessionResponse(
      response,
      transformedCart.cartItems
    );
  }

  /**
   * Apply the supplied coupon code to the given cart.
   *
   * **Note:** This method will not update the Cart Model, but
   * it will instead return the updates to apply. Make sure to
   * apply them to the model afterwards.
   *
   * @param cart - The {@link} CartModel to use.
   * @param couponCode - The coupon code to apply.
   *
   * @throws A {@link RejectedCouponError} if the coupon is rejected.
   *
   * @returns A promise that will resolve with the updates to
   * apply to the supplied cart model.
   */
  public async applyCoupon(
    cart: CartModel,
    couponCode: string
  ): Promise<ICartPromotionUpdates> {
    const transformedCart = TalonOneService.cartToCustomerSession(
      cart.toDTO(),
      cart.ownerID
    );

    // Inject coupon code into the transformed cart
    if (transformedCart.couponCodes.includes(couponCode)) {
      throw new CouponAlreadyAppliedError();
    }
    transformedCart.couponCodes.push(couponCode);

    const response = await TalonOneService.updateCustomerSession(
      cart.uuid,
      transformedCart
    );

    const { rejectedCoupons, acceptedCoupons } =
      TalonOneService.couponStatusFromCustomerSessionResponse(response);

    const rejectedCoupon = rejectedCoupons.find(
      (c) => c.couponCode.toLowerCase() === couponCode.toLowerCase()
    );

    if (rejectedCoupon) {
      this.throwRejectedCouponError(
        rejectedCoupon.rejectionReason,
        rejectedCoupon.couponCode
      );

      // Don't bother to update the cart if the coupon was invalid.
    }

    const acceptedCoupon = acceptedCoupons.find(
      (c) => c.code.toLowerCase() === couponCode.toLowerCase()
    );

    if (acceptedCoupon) {
      return TalonOneService.cartUpdatesFromCustomerSessionResponse(
        response,
        transformedCart.cartItems
      );
    }

    // If you made it here, it means that the coupon is neither accepted or rejected.
    throw new InvalidStateError(
      `Coupon error: Received neither an accept or reject response for coupon code "${couponCode}".`
    );
  }

  /**
   * Remove the specified coupon code from a given cart.
   *
   * **Note:** This method will not update the Cart Model, but
   * it will instead return the updates to apply. Make sure to
   * apply them to the model afterwards.
   *
   * @param cart - The {@link} CartModel to remove the coupon from.
   * @param couponCode - The coupon code to remove.
   *
   * @returns A promise that will resolve with the updates to
   * apply to the supplied cart model.
   */
  public async removeCoupon(
    cart: CartModel,
    couponCode: string
  ): Promise<ICartPromotionUpdates> {
    const transformedCart = TalonOneService.cartToCustomerSession(
      cart.toDTO(),
      cart.ownerID
    );

    const upperCasedCoupon = couponCode.toUpperCase();

    // Filter the coupon code from the transformed cart
    transformedCart.couponCodes = transformedCart.couponCodes.filter(
      (c) => c.toUpperCase() !== upperCasedCoupon
    );

    // Update the customer session
    const response = await TalonOneService.updateCustomerSession(
      cart.uuid,
      transformedCart
    );

    // Return updates
    return TalonOneService.cartUpdatesFromCustomerSessionResponse(
      response,
      transformedCart.cartItems
    );
  }

  /**
   * Given a set of {@link ICartPromotionUpdates Cart Promotion Updates}, apply them to the specified DTO.
   * This will also include coupons and the promotions of the cart's line items.
   *
   * @param updates - Promotion updates to apply.
   * @param dto - {@link ICart Cart DTO} To apply the updates to.
   *
   * @returns An updated cart DTO with the updates applied.
   */
  public applyPromotionUpdatesToCartDTO(
    updates: ICartPromotionUpdates,
    dto: DTO<ICart>
  ): DTO<ICart> {
    const { coupons, cartPromotions, lineItemPromotions } = updates;

    // The new items array will be...
    const newItems = lineItemPromotions
      ? // If promotions are present, a map of...
        dto.items.map((lineItem) => {
          // Each item with their promotions array overwritten.
          const newItemPromotions = lineItemPromotions[lineItem.sku] ?? []; // If no promotions were included for this item, include an empty array.

          return {
            ...lineItem,
            promotions: newItemPromotions
          };
        })
      : // If no promotions are present, just the dto's items again/
        dto.items;

    // Rebuild the DTO with...
    return {
      // All the previous data,
      ...dto,

      // The retrieved coupons, updated items, and cart promotions
      coupons,
      items: newItems,
      promotions: cartPromotions
    } as unknown as DTO<ICart>;
  }
}

export default PromotionsService.withMock(
  new PromotionsServiceMock(PromotionsService)
) as unknown as PromotionsService;
