/**
 * An enum representing possible SameSite values.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite.
 */
export enum SameSite {
  None = 'none',
  Lax = 'lax',
  Strict = 'strict'
}

/**
 * Describes a cookie, its name, value, and all of the possible attributes.
 * Used for either constructing a cookie, providing all of its attributes and then
 * setting the cookie, or used when parsing and reading cookies. When reading cookies
 * from the Cookie header, only the name and the value can be reconstructed.
 */
export interface ICookie {
  /** Key (name) of the cookie. */
  key: string;

  /** Value of the cookie. */
  value: string;

  /** Path of the cookie. */
  path?: string;

  /** Domain of the cookie. */
  domain?: string;

  /** Expiration date of the cookie. Use with caution as this depends on the client's time. */
  expires?: Date;

  /**
   * Max expiration date of the cookie in seconds.
   * This is more robust since it will work even if the time of the client is a little off from the server's time.
   * If `expires` and `maxAge` are both present, `maxAge` takes precedence.
   */
  maxAge?: number;

  /** Wether this cookie is secure (only transferred over https). */
  secure?: boolean;

  /**
   * Wether this cookie should be accessible to JavaScript.
   * `httpOnly: true` means JS can't access it via the `document.cookie` API.
   *
   * Creating `httpOnly` cookies in the client-side is not possible, so the `CookieService`
   * should throw an error if an attempt is made to set an `httpOnly` cookie on the client side.
   */
  httpOnly?: boolean;

  /**
   * What should this cookie do in a cross-site request context.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite}.
   */
  sameSite?: SameSite;

  /**
   * Returns the value of this cookie.
   * @returns The value of the cookie.
   */
  toString(): string;

  /**
   * Converts this string into a raw header representation format, for example:
   * <name>=<value>; Domain=<domain-value>; Secure; HttpOnly.
   *
   * @returns A string that can be used with `Set-Header` to set this cookie.
   */
  toRawString(): string;

  /**
   * Returns a number representation of the cookie value.
   * @throws When the cookie value is not numeric.
   * @returns A number.
   */
  asNumber(): number;

  /**
   * Returns a number representation of the cookie value.
   * @throws When the cookie value is not numeric.
   * @returns A number.
   */
  asBoolean(): boolean;
}

/**
 * Represents a cookie with only the key and value.
 * This is useful for representing cookies from an isomorphic context.
 */
export type ISimpleCookie = Pick<ICookie, 'key' | 'value'>;
