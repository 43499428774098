import { CardNetwork } from '@/constructs/CardNetwork';

import { MOCK_ADDRESS, IAddress } from '../../Address';
import { ICardPaymentMethod } from '../PaymentMethod/Cards';

export default {
  accountID: 'HKDEAQVCBPLYWM2023',
  uuid: 'a4eaf16b-0f29-6ab5-5887-eaf68fb8298d',
  network: CardNetwork.Visa,
  type: 0,
  token: 'token',
  maskedNumber: '52xxxxxxxxxx4389',
  expiration: '11/23',
  billingAddress: MOCK_ADDRESS as IAddress
} as ICardPaymentMethod;
