import type { DTO } from '@/type-utils';
import UserModel from '../UserModel';
import UserType from '../UserType';
import IAnonymousUser from './IAnonymousUser';

/** Represents an {@link IAnonymousUser Anonymous User}. */
export default class AnonymousUserModel
  extends UserModel<DTO<IAnonymousUser>>
  implements IAnonymousUser
{
  /** @inheritdoc */
  public override readonly type = UserType.Anonymous;
}
