import { Nullable } from '@/type-utils';
import type { StructuredDataInput } from '@/services/isomorphic/integrations/StructuredDataService/StructuredDataTypes';
import type { Robots } from './Robots';
import type { IProductListMetaData } from './IProductListMetaData';
import { IBreadcrumb } from '../Breadcrumb';

/**
 * The available page types upon loading a new page.
 */
export enum PageType {
  Home = 'home',
  Category = 'category',
  Search = 'search',
  LandingPage = 'landing-page',
  Product = 'product',
  Cart = 'cart',
  Checkout = 'checkout',
  OrderConfirmation = 'orderconfirmation',
  Account = 'account',
  Returns = 'returns',
  Generic = 'generic',
  NotFound = 'not-found',
  Unavailable = 'unavailable',
  Unknown = 'unknown'
}

/**
 * A representation of a Page, including meta data.
 */
export default interface IPage {
  /** The current page url. */
  url: string;

  /** The page type, represented by a string value. */
  pageType: PageType;

  /** The current page title. */
  title?: Nullable<string>;

  /** The current page description. */
  description?: Nullable<string>;

  /** The current page breadcrumbs. */
  breadcrumbs?: Array<IBreadcrumb>;

  /** The product metadata that comes from the product list service. */
  productListMetadata?: IProductListMetaData;

  /** The content slug used to get specific page content. */
  contentSlug?: string;

  /**
   *  The canonical URL for the page, which represents the single authoritative location for the page's content.
   *  - A value of `null` or `""` (empty string) indicates that there is no canonical URL for the page.
   *  - A value of `undefined` indicates that the page should use some sensible default, or none otherwise.
   */
  canonicalURL?: Nullable<string>;

  /** The page meta data, these will be added to their corresponding meta tag. */
  pageMetaData?: {
    /** An array of possible values that the robots meta tag can have.
     * @see https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag
     */
    robots?: Array<Robots>;

    /**
     * A string that represents the JSON-LD data that will be added to the page. This should be added via the {@link StructuredDataService}.
     * @see https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data
     */
    ldjson?: string;
  };
}
