import { DTO, type Nullable } from '@/type-utils';

import I18NService, { Country } from '../../isomorphic/I18NService';
import LoggerService from '../../isomorphic/LoggerService';
import Model from '../Model';
import type { IProduct } from '../Product';

import { UnableToRetrieveCountryInventoryStatusError } from './UnableToRetrieveCountryInventoryStatusError';

import IInventory from './IInventory';
import IInventoryStatus from './IInventoryStatus';
import ProductAvailabilityState from './ProductAvailabilityState';

/** Represents the inventory data of a {@link IProduct product}. */
export default class InventoryModel
  extends Model<DTO<IInventory>>
  implements IInventory
{
  /** @inheritdoc */
  public readonly upc: string;

  /** @inheritdoc */
  public readonly distributionCenterCodes: Array<string>;

  /** @inheritdoc */
  public readonly inventoryStatus: {
    [key in Country]?: IInventoryStatus;
  };

  /** @inheritdoc */
  public readonly transientErrors: Nullable<ReadonlyArray<string>>;

  /** @inheritdoc */
  public constructor(dto: DTO<IInventory>) {
    super(dto);

    this.upc = dto.upc;
    this.distributionCenterCodes = [...dto.distributionCenterCodes];
    this.inventoryStatus = dto.inventoryStatus;
    this.transientErrors = dto.transientErrors;
  }

  /**
   * Gets the most recent {@link IInventoryStatus inventory status} for the specified country.
   *
   * @param country - The Locale to get the inventory status for.
   * @returns The inventory status as an {@link IInventoryStatus} object.
   *
   * @throws An {@link UnableToRetrieveCountryInventoryStatusError} if the inventory status cannot be retrieved.
   */
  public getStatusForCountry(country: Country): IInventoryStatus {
    const status = this.inventoryStatus[country];

    if (!status) {
      throw new UnableToRetrieveCountryInventoryStatusError(
        `Inventory status for country "${country}" is not present for product with UPC "${this.upc}".`
      );
    }

    return status;
  }

  /**
   * Determines the product's current {@link ProductAvailabilityState availability state}
   * from its inventory.
   *
   * @returns A {@link ProductAvailabilityState} value.
   */
  public get availabilityState(): ProductAvailabilityState {
    try {
      const { allocation, upcomingAllocation, perpetual } = this.currentStatus;

      switch (true) {
        case allocation > 0 || perpetual: {
          return ProductAvailabilityState.InStock;
        }

        case upcomingAllocation > 0: {
          // TODO: Find how to tell the difference between launched and unlaunched products.
          // Unlaunched products will return Preorder instead.
          return ProductAvailabilityState.Backorder;
        }

        default: {
          return ProductAvailabilityState.OutOfStock;
        }
      }
    } catch (error) {
      // If inventory status is missing...
      if (error instanceof UnableToRetrieveCountryInventoryStatusError) {
        // Warn and return "Unavailable".
        LoggerService.warn(error);
        return ProductAvailabilityState.Unavailable;
      }

      throw error;
    }
  }

  /**
   * Inventory Status getter.
   * @returns The most recent {@link IInventoryStatus inventory status} for the current country.
   */
  public get currentStatus(): IInventoryStatus {
    const currentLocale = I18NService.currentLocale.country;
    return this.getStatusForCountry(currentLocale);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IInventory> {
    return {
      upc: this.upc,
      distributionCenterCodes: this.distributionCenterCodes,
      inventoryStatus: this.inventoryStatus,
      transientErrors: this.transientErrors
    };
  }
}
