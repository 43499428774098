import { MoneyModel } from '@/services/models/Money';
import type { IHandler } from '@/services/utils/chain-of-responsibility';
import { PromotionType } from '@/services/models/Cart/Promotion';
import { ICartDiscount } from '@/services/models/Cart/Promotion/Cart';
import type { IIntermediateCalculationState, ITotals } from '..';
import PromotionsService from '../../PromotionsService';

/**
 * A handler for calculating the shipping cost for a cart.
 */
export default class CalculateCartShippingCostHandler
  implements IHandler<IIntermediateCalculationState, Promise<ITotals>>
{
  /**
   * Calculates the shipping cost for a cart and updates `totals`.
   *
   * @param requestData - The request data to pass to process.
   * @param next - A function to pass the "request" to the next handler in some
   * chain.
   *
   * @returns A promise resolving to the result of processing the request.
   */
  public async handle(
    requestData: IIntermediateCalculationState,
    next: (requestData: IIntermediateCalculationState) => Promise<ITotals>
  ): Promise<ITotals> {
    const { cartModel, totals } = requestData;

    const initialShippingCost = cartModel.selectedShippingMethod
      ? cartModel.selectedShippingMethod.shippingCost
      : MoneyModel.fromAmount(0);

    /**
     * Promotions filtered by the following criteria:
     * - Is a cart discount.
     * - Is applied to shipping costs.
     */
    const shippingPromotions = cartModel.promotions.filter(
      (promotion) =>
        promotion.type === PromotionType.CartDiscount &&
        (promotion as ICartDiscount).applyTo === 'shipping'
    ) as Array<ICartDiscount>;

    const shippingDiscountAmounts = shippingPromotions.map(({ value, mode }) =>
      PromotionsService.getDiscountAmount(initialShippingCost, value, mode)
    );

    const totalDiscountAmount = MoneyModel.add(0, ...shippingDiscountAmounts);

    // Do not let the discount total exceed the total shipping costs.
    const boundedNetShippingCosts = MoneyModel.max(
      MoneyModel.subtract(initialShippingCost, totalDiscountAmount),
      MoneyModel.fromAmount(0)
    );

    // Round the shipping cost to the nearest valid currency amount, in case
    // it's not already.
    const roundedShippingCost = boundedNetShippingCosts.toFixed();

    totals.shippingCost.addAmount(roundedShippingCost);
    totals.total.addAmount(roundedShippingCost);

    return next(requestData);
  }
}
