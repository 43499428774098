import { PromotionType } from '@/services/models/Cart/Promotion';
import type { ICartDiscount } from '@/services/models/Cart/Promotion/Cart';
import { IMoney, MoneyModel } from '@/services/models/Money';
import type { IHandler } from '@/services/utils/chain-of-responsibility';
import {
  InvalidArgumentError,
  InvalidStateError,
  NotImplementedError
} from '@/utils/errors';
import { exhaustiveGuard } from '@/utils/function-utils';
import type { IIntermediateCalculationState, ITotals } from '..';
import PromotionsService from '../../PromotionsService';

/**
 * A handler for calculating the discount from cart promotions.
 */
export default class CalculateCartPromotionsHandler
  implements IHandler<IIntermediateCalculationState, Promise<ITotals>>
{
  /**
   * Calculates the discount from cart promotions and updates `totals`.
   * @param requestData - The request data to pass to process.
   * @param next - A function to pass the "request" to the next handler in some chain.
   * @returns A promise resolving to the result of processing the request.
   */
  public async handle(
    requestData: IIntermediateCalculationState,
    next: (requestData: IIntermediateCalculationState) => Promise<ITotals>
  ): Promise<ITotals> {
    const { cartModel, totals } = requestData;

    if (cartModel.promotions.length === 0) return next(requestData);

    const discounts = cartModel.promotions.map((promotion) => {
      switch (promotion.type) {
        case PromotionType.CartDiscount: {
          return getCartDiscount(promotion as ICartDiscount, totals);
        }
        default: {
          throw new InvalidStateError(
            `Cannot apply cart promotion: Promotion type "${promotion.type}" is invalid.`
          );
        }
      }
    });

    const discountTotal = MoneyModel.add(0, ...discounts);

    // Round the discount total to the nearest valid currency amount, in case it's not already.
    const roundedDiscountTotal = discountTotal.toFixed();

    // Do not let the discount total exceed the cart total.
    const boundedDiscountTotal = MoneyModel.min(
      MoneyModel.from(roundedDiscountTotal),
      totals.total
    );

    totals.cartDiscount.addAmount(boundedDiscountTotal);
    totals.discount.addAmount(boundedDiscountTotal);
    totals.total.subtractAmount(boundedDiscountTotal);

    return next(requestData);
  }
}

/**
 * Gets the discount amount for the given promotion.
 * @param promotion - The promotion to get the discount amount for.
 * @param totals - The current state of the totals.
 * @returns The discount amount.
 * @throws {InvalidArgumentError} - If the promotion applies to an invalid target price.
 */
function getCartDiscount(promotion: ICartDiscount, totals: ITotals): IMoney {
  const { applyTo, mode, value } = promotion;

  switch (applyTo) {
    case 'total': {
      return PromotionsService.getDiscountAmount(totals.total, value, mode);
    }

    case 'shipping': {
      // Ignore this type of discount as it will be handled by the shipping
      // costs step
      return MoneyModel.fromAmount(0);
    }
  }

  return exhaustiveGuard(
    applyTo,
    `Cannot apply cart discount: Target price (applyTo) "${applyTo}" is invalid.`
  );
}
