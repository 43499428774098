import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { MismatchingCurrenciesError } = errorFactory(
  'MismatchingCurrenciesError'
);

/**
 * An error type which occurs during monetary operations with mismatching currencies.
 * @example
 * MoneyModel.fromAmount(100, 'USD').add(MoneyModel.fromAmount(100, 'EUR')); // throws MismatchingCurrenciesError
 */
export type MismatchingCurrenciesError = InstanceTypeOf<
  typeof MismatchingCurrenciesError
>;
