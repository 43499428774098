import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { CartQuantityExceededError } from './CartQuantityExceededError';

export const { LineItemQuantityExceededError } = errorFactory(
  'LineItemQuantityExceededError',
  CartQuantityExceededError
);

/** Error to use when a line item has exceeded its configured value. */
export type LineItemQuantityExceededError = InstanceTypeOf<
  typeof LineItemQuantityExceededError
>;
