import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { RejectedCouponError } from './RejectedCouponError';

export const { CouponNotFoundError } = errorFactory(
  'CouponNotFoundError',
  RejectedCouponError
);

/** Error for an unknown coupon. */
export type CouponNotFoundError = InstanceTypeOf<typeof CouponNotFoundError>;
