/* eslint-disable local-rules/warn-against-moneymodel-asunsafenumber
-- GTM expects all monetary values to be numbers, so we need to use `MoneyModel.asUnsafeNumber`
to convert them from our internal representation. Moreover, all the amounts have already been
rounded properly by the `CartCalculationService`, so we don't need to worry about rounding here.
*/

import crypto from 'node:crypto';

import { DTO, Nullable } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';

import { CartModel } from '@/services/models/Cart';
import CartService from '../../CartService';
import CookieService from '../../CookieService';
import { EnvironmentService } from '../../EnvironmentService';
import I18NService from '../../I18NService';

import { Currency, MoneyModel, type IMoney } from '../../../models/Money';
import { PageModel } from '../../../models/Page';
import { ProductModel } from '../../../models/Product';
import {
  EventType,
  IInteractionPageDetails
} from '../../UserInteractionService/IInteractionDetails';

import {
  ILineItem,
  IProductLineItem,
  LineItemModel,
  ProductLineItemModel
} from '../../../models/Cart/LineItem';
import { CookieModel } from '../../../models/Cookie';
import { IPlacedOrder, type IOrderLine } from '../../../models/Order';
import { AuthenticatedUserModel } from '../../../models/User/AuthenticatedUser';
import UserService from '../../UserService';
import {
  IEcommerceAdd,
  IEcommerceCart,
  IEcommerceDetail,
  IEcommerceEvent,
  IEcommerceOrderPurchase,
  IEcommercePurchase,
  IEcommerceRemove,
  IEcommmerceProduct
} from './GTMEcommerceEvent';
import { GTMEventType } from './GTMEventType';
import type {
  IPageCustomerInteractionData,
  IPageInteractionData,
  IPageOrderInteractionData
} from './IPageInteractionData';
import ProductService from '../../ProductService';

/**
 * This class provides a series of 'helpers' to the GTMService. All
 * of these methods provide the ability to transform UserInteraction
 * data into GTM specific data.
 */
class GTMDataHandler {
  /**
   * This is the event name map between the UserInteraction event and the GTM Event.
   */
  private eventTypeMap = new Map<EventType, GTMEventType>([
    [EventType.NavigationLink as const, 'navigation_link'],
    [EventType.PageView, 'page_view'],
    [EventType.ProductAdd, 'product_add'],
    [EventType.ProductRemove, 'product_remove'],
    [EventType.ProductSizeChart, 'product_sizeChart'],
    [EventType.ProductUpdate, 'product_updateAddToCart'],
    [EventType.ProductUpdateVariation, 'pdp_variation_change'],
    [EventType.QuickviewShow, 'quickview_show'],
    [EventType.SearchShowMore, 'search_showMore'],
    [EventType.SearchSort, 'search_sort'],

    // Checkout
    [EventType.ShippingStart, 'checkout_shippingStart'],
    [EventType.ShippingError, 'checkout_shippingError'],
    [EventType.ShippingSubmit, 'checkout_shippingSubmit'],
    [EventType.ShippingSuccess, 'checkout_shippingSuccess'],
    [EventType.BillingStart, 'checkout_billingStart'],
    [EventType.BillingError, 'checkout_billingError'],
    [EventType.BillingSubmit, 'checkout_billingSubmit'],
    [EventType.BillingSuccess, 'checkout_billingSuccess'],
    [EventType.OrderPlace, 'order_place'],
    [EventType.OrderError, 'order_error'],
    [EventType.OrderSuccess, 'order_success'],

    // Account/Login
    [EventType.LoginError, 'login_error'],
    [EventType.LoginRegister, 'login_register'],
    [EventType.LoginSubmit, 'login_submit'],
    [EventType.LoginSuccess, 'login_success'],
    [EventType.UserSignout, 'user_signout'],
    [EventType.PasswordEdit, 'password_edit'],
    [EventType.ProfileEdit, 'profile_edit'],
    [EventType.SignupError, 'signup_error'],
    [EventType.SignupSuccess, 'signup_success'],
    [EventType.FormInput, 'form_input'],
    [EventType.FormSubmit, 'form_submit']
  ]);

  /**
   * This gets analytics data from the product model and returns the
   * ecommerce product which is used in GTM events that use the productModel.
   * @param productModel - The product model that will be transformed.
   * @returns The ecommerce product shapped for the ecommerce data used by
   * GTM.
   */
  private getEcommerceProductFromProduct(
    productModel: ProductModel
  ): IEcommmerceProduct {
    const roundedPrice = productModel.price
      ? MoneyModel.from(productModel.price.retailPrice).toFixed().amount
      : '';
    const ecommerceProduct: IEcommmerceProduct = {
      name: productModel.name,
      id: productModel.styleNumber,
      price: roundedPrice,
      site: EnvironmentService.brand,
      category: productModel.primaryCategory ?? 'none',
      variant: productModel.upc ?? productModel.sku,
      quantity: 1,
      image_url: productModel.primaryImage.src,
      original_price: roundedPrice,
      sku: productModel.sku,
      upc: productModel.upc ?? productModel.sku,
      url: productModel.fullUrl
    };

    return ecommerceProduct;
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the line item.
   * @param item - The line item that will be transformed.
   * @returns InteractionData for the line item.
   */
  private getEcommerceProductFromLineItem(
    item: ProductLineItemModel
  ): IEcommmerceProduct {
    const roundedPrice = MoneyModel.from(item.unitPrice.retailPrice).toFixed();
    const ecommerceProduct: IEcommmerceProduct = {
      name: item.name,
      id: item.styleNumber,
      price: item.netUnitPrice.toFixed().amount,
      site: EnvironmentService.brand,
      category: item.primaryCategory ?? 'none',
      variant: item.upc,
      quantity: item.quantity,
      image_url: item.image?.src as string,
      original_price: roundedPrice.amount,
      sku: item.sku,
      upc: item.upc ?? item.sku,
      url: item.url
    };

    return ecommerceProduct;
  }

  /**
   * Retrieves and combines promotion coupon codes from both the cart and its line items.
   * @param cart - Current customer cart.
   * @returns List of coupon codes applied to cart.
   */
  private getPromotionCodesFromCart(cart: Nullable<CartModel>): string {
    const cartCouponArray: Array<string | undefined> = cart?.promotions?.length
      ? cart.promotions.map((promo) => promo.couponCode)
      : [];
    const lineItemCouponCodeArray: Array<string> = [];
    cart?.items?.forEach((item) => {
      item.promotions.forEach((promotion) => {
        if (promotion.couponCode) {
          lineItemCouponCodeArray.push(promotion.couponCode);
        }
      });
    });

    const mergeCouponCodes = cartCouponArray.concat(lineItemCouponCodeArray);
    const promoCodes = [...new Set(mergeCouponCodes)].join(',');

    return promoCodes;
  }

  /**
   * Retrieves and combines promotion coupon codes from both the order and its line items.
   * @param order - Current customer order.
   * @returns A string of the list of coupon codes applied to order with comma delimiter.
   */
  private getPromotionCodesFromOrder(order: IPlacedOrder): string {
    const orderCouponArray: Array<string | undefined> = order?.promotions
      ?.length
      ? order.promotions.map((promo) => promo.couponCode)
      : [];

    const lineItemCouponCodeArray: Array<string> = [];
    order.lines?.forEach((line) => {
      line.promotions.forEach((promotion) => {
        if (promotion.couponCode) {
          lineItemCouponCodeArray.push(promotion.couponCode);
        }
      });
    });

    const mergeCouponCodes = orderCouponArray.concat(lineItemCouponCodeArray);
    const promoCodes = [...new Set(mergeCouponCodes)].join(',');

    return promoCodes;
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the line item. Duplicates product data for use with GTM,
   * ecommerce object is used directly by GA. This adds the line item data
   * in the confirmation object.
   * The products are the line items in customer's cart.
   * @param items - The line item that will be transformed.
   * @returns InteractionData for the line item.
   */
  public getGTMCartEcommerceData(
    items: Array<LineItemModel<DTO<ILineItem>>>
  ): IEcommerceEvent<IEcommerceCart> {
    const products: Array<IEcommmerceProduct> = [];
    items.forEach((item) => {
      const productLineItemModel = ProductLineItemModel.from(item);
      const ecommerceProduct: IEcommmerceProduct =
        this.getEcommerceProductFromLineItem(productLineItemModel);
      products.push(ecommerceProduct);
    });

    return {
      products,
      ecommerce: {
        currencyCode: Currency.USD,
        cart: {
          products
        }
      }
    };
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the product. Duplicates product data for use with GTM,
   * ecommerce object is used directly by GA.
   * @param productModel - The product model that will be transformed.
   * @returns InteractionData for the product.
   */
  private getGTMPageEcommerceData(
    productModel: ProductModel
  ): IEcommerceEvent<IEcommerceDetail> {
    const ecommerceProduct = this.getEcommerceProductFromProduct(productModel);

    return {
      products: [ecommerceProduct],
      ecommerce: {
        currencyCode: Currency.USD,
        detail: {
          products: [ecommerceProduct]
        }
      }
    };
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the product on the page. Duplicates product data for use with GTM,
   * ecommerce object is used directly by GA.
   * @param productModel - The product model that will be transformed.
   * @returns InteractionData for the page product.
   */
  public getGTMAddToCartEcommerceData(
    productModel: ProductModel
  ): IEcommerceEvent<IEcommerceAdd> {
    const ecommerceProduct = this.getEcommerceProductFromProduct(productModel);

    return {
      products: [ecommerceProduct],
      ecommerce: {
        currencyCode: Currency.USD,
        add: {
          products: [ecommerceProduct]
        }
      }
    };
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the line item. Duplicates product data for use with GTM,
   * ecommerce object is used directly by GA.
   * @param item - The line item that will be transformed.
   * @returns InteractionData for the line item.
   */
  public getGTMRemoveFromCartEcommerceData(
    item: IProductLineItem
  ): IEcommerceEvent<IEcommerceRemove> {
    const productLineItemModel = ProductLineItemModel.from(item);
    const ecommerceProduct: IEcommmerceProduct =
      this.getEcommerceProductFromLineItem(productLineItemModel);

    return {
      products: [ecommerceProduct],
      ecommerce: {
        currencyCode: Currency.USD,
        remove: {
          products: [ecommerceProduct]
        }
      }
    };
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the line item. Duplicates product data for use with GTM,
   * ecommerce object is used directly by GA. This adds the line item data
   * in the confirmation object.
   * The products are the line items used to place an order.
   * @param items - The line item that will be transformed.
   * @returns InteractionData for the line item.
   */
  public getGTMConfirmationEcommerceData(
    items: Array<IProductLineItem>
  ): IEcommerceEvent<IEcommercePurchase> {
    const products: Array<IEcommmerceProduct> = [];
    items.forEach((item) => {
      const productLineItemModel = ProductLineItemModel.from(item);
      const ecommerceProduct: IEcommmerceProduct =
        this.getEcommerceProductFromLineItem(productLineItemModel);
      products.push(ecommerceProduct);
    });

    return {
      products,
      ecommerce: {
        currencyCode: Currency.USD,
        purchase: {
          products
        }
      }
    };
  }

  /**
   * This gets analytics data to be used in a user interaction event that is
   * relevant to the line item. Duplicates product data for use with GTM,
   * ecommerce object is used directly by GA. This adds the line item data
   * in the confirmation object.
   * The products are the line items used to place an order.
   * @param orderID - The order ID.
   * @param lines - The line items that will be transformed.
   * @param revenue - The revenue of the order.
   * @returns InteractionData for the line item.
   */
  public async getGTMConfirmationEcommerceDataFromOrder(
    orderID: string,
    lines: ReadonlyArray<IOrderLine>,
    revenue: IMoney
  ): Promise<IEcommerceEvent<IEcommerceOrderPurchase>> {
    const products: Array<IEcommmerceProduct> = [];

    const productPromises = lines.map(async (line) => {
      const [id] = line.sku.split('-');

      const product = line.name.split(' ').join('-').toLowerCase();
      const url = `${EnvironmentService.baseURL}/p/${product}/${line.sku}`;

      const productModel = await ProductService.getProduct(line.sku);

      if (!productModel) {
        return;
      }

      const original_price = productModel.price?.retailPrice.toFixed()
        .amount as string;
      const price =
        productModel.price?.currentPrice?.toFixed().amount ?? original_price;

      products.push({
        id,
        name: line.name,
        price,
        quantity: line.quantity,
        sku: line.sku,
        upc: line.upc ?? line.sku,
        url,
        image_url: productModel.primaryImage.src,
        category: productModel.primaryCategory ?? 'none',
        site: EnvironmentService.brand,
        original_price,
        variant: line.upc
      });
    });

    await Promise.all(productPromises);

    return {
      products,
      ecommerce: {
        currencyCode: Currency.USD,
        purchase: {
          actionField: {
            id: orderID,
            revenue: MoneyModel.asUnsafeNumber(revenue)
          },
          products
        }
      }
    };
  }

  /**
   * Assembles customer data to be used to add to the dataLayer when the user is authenticated
   * and loads a new page.
   * @param currentUser - The current user data used to assemble the customer. This should
   * only be the Authenticated user.
   * @returns The page customer interaction data to be added to the page data.
   */
  private assemblePageCustomerData(
    currentUser: AuthenticatedUserModel
  ): IPageCustomerInteractionData {
    const customerData: IPageCustomerInteractionData = {};

    const { uuid, email, account } = currentUser;
    const { firstName, lastName } = account.profile;

    // If there is no value, these should not be added to the object.
    customerData.customer_loyalty_member = false;
    customerData.customer_id = uuid;
    customerData.customer_email = email;
    customerData.customer_first_name = firstName;
    customerData.customer_last_name = lastName;
    customerData.customer_hmail = crypto
      .createHash('sha256')
      .update(email)
      .digest('hex');
    customerData.customer_registered = true;

    return customerData;
  }

  /**
   * Assembles customer data to be used to add to the dataLayer for guest order.
   * @param order - The current order.
   * @returns The page customer interaction data to be added to the page data.
   */
  private assemblePageOrderCustomerData(
    order: IPlacedOrder
  ): IPageCustomerInteractionData {
    const customerData: IPageCustomerInteractionData = {};

    customerData.customer_email = order.customer.emailAddress;
    customerData.customer_first_name = order.billingAddress.firstName;
    customerData.customer_last_name = order.billingAddress.lastName;
    customerData.customer_city = order.billingAddress.city;
    customerData.customer_country = order.billingAddress.country;
    customerData.customer_postal_code = order.billingAddress.zipPostalCode;
    customerData.customer_state = order.billingAddress.stateProvince;
    customerData.customer_hmail = crypto
      .createHash('sha256')
      .update(order.customer.emailAddress)
      .digest('hex');

    return customerData;
  }

  /**
   * This assembles order data off the order to use in general page order data.
   * @param order - The order coming in from the order confirmation.
   * @returns Interaction data to be added to the larger page interaction data object.
   */
  private assemblePageOrderData(
    order: IPlacedOrder
  ): IPageOrderInteractionData {
    const orderData: IPageOrderInteractionData = {};

    const { city, stateProvince, country } = order.shippingAddress;

    const lineTotals = order.lines.map((line) => line.total);
    const lineTaxes = order.lines.map((line) => line.tax);

    const netTotalOfLines = MoneyModel.add(0, ...lineTotals).subtractAmount(
      ...lineTaxes
    );

    const promoCodes = this.getPromotionCodesFromOrder(order);

    const paymentMethod = 'CREDIT_CARD';
    const { shippingMethod } = order;

    orderData.order_currency_code = order.totals.total.currency;
    // This needs to be developed with order promotions.
    orderData.order_discount_amount = MoneyModel.asUnsafeNumber(
      order.totals.discount
    );
    orderData.order_promo_code = promoCodes;
    orderData.order_id = order.orderID?.displayValue;
    // HubBox is not yet a feature.
    orderData.order_ishubbox = false;
    orderData.order_merchandise_total =
      MoneyModel.asUnsafeNumber(netTotalOfLines);
    orderData.order_payment_type = paymentMethod;
    orderData.order_shipping_type = shippingMethod.id;
    orderData.order_shipping_amount = MoneyModel.asUnsafeNumber(
      order.totals.shipping
    );
    orderData.order_shipping_city = city;
    orderData.order_shipping_country = country;
    orderData.order_shipping_state = stateProvince;
    orderData.order_subtotal = MoneyModel.asUnsafeNumber(order.totals.subtotal);
    orderData.order_tax_amount = MoneyModel.asUnsafeNumber(order.totals.tax);
    orderData.order_total = MoneyModel.asUnsafeNumber(order.totals.total);
    orderData.order_type = 'Storefront';

    return orderData;
  }

  /**
   * Assembles the general page data that forms the base to be added to by other methods
   * that decorate with customer data or product data.
   * @param interactionDetails - The interaction detail used for the page event.
   * @returns The base page data used to be combined with other additions like customer data.
   */
  private async assembleGeneralPageData(
    interactionDetails: IInteractionPageDetails
  ): Promise<IPageInteractionData> {
    const { country, language } = I18NService.currentLocale;
    const { page } = interactionDetails;

    const pageModel = new PageModel(page);

    const cart = await CartService.getCartFromSession();
    if (cart) {
      await cart.revalidate();
    }

    const promoCodes = this.getPromotionCodesFromCart(cart);

    let pageViewData: IPageInteractionData = {
      site_name: (process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase()),
      country_code: country,
      language_code: language,
      currency_code: Currency.USD,
      page_type: pageModel.pageType,
      site_id: `${(process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase())}-${country}`,

      device_type: window.innerWidth > 768 ? 'desktop' : 'mobile',
      page_name: pageModel.title,

      // Browser
      'dom.title': pageModel.title,
      'dom.viewport_height': window.innerHeight,
      'dom.viewport_width': window.innerWidth,
      'dom.domain': window.location.hostname,
      'dom.pathname': window.location.pathname,
      'dom.query_string': window.location.search,
      'dom.hash': window.location.hash,
      'dom.referrer': document.referrer,

      // TODO: Whether the customer is registered
      customer_registered: false,

      site_country_page: `${(process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase())}-${country}-${country}-${pageModel.pageType}`,
      site_country: `${(process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase())}-${country}-${country}`,
      site_page: `${(process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase())}-${country}-${pageModel.pageType}`,
      lang_locale: `${language}-${country}`
    };

    // Assembles cart data.
    pageViewData.cart_total_items = cart?.items.length ?? 0;
    pageViewData.cart_total_value = cart
      ? MoneyModel.asUnsafeNumber(cart.total.value)
      : undefined;
    pageViewData.cart_discount_amount = cart
      ? MoneyModel.asUnsafeNumber(cart.cartDiscount.value)
      : undefined;
    pageViewData.cart_promo_code = promoCodes;

    const cartProducts = cart?.items
      ? this.getGTMCartEcommerceData(cart.items)
      : [];
    pageViewData = { ...pageViewData, ...cartProducts };

    return pageViewData;
  }

  /**
   * This populates the data for a given page. This provides initial data
   * including customer and cart data. It assembles everything needed for the events
   * from page data, to browser data, to the list of products on the page.
   * @param interactionDetails - The interaction details contain a page
   * and optionally products.
   * @returns The page interaction data which will then be used
   * to make a page_view action.
   */
  public async getGTMPageViewData(
    interactionDetails: IInteractionPageDetails
  ): Promise<IPageInteractionData> {
    const previousPageType: Nullable<string> = CookieService.tryGet(
      'deckersPreviousPage'
    )?.toString();

    // Assembles the base data.
    let pageViewData = await this.assembleGeneralPageData(interactionDetails);

    const { products, order, lines } = interactionDetails;

    const [currentUser] = await Promise.all([UserService.getCurrentUser()]);

    if (previousPageType) pageViewData.previous_page_name = previousPageType;

    if (currentUser instanceof AuthenticatedUserModel) {
      const customerData = this.assemblePageCustomerData(currentUser);

      // Merge customer data into the page view data.
      pageViewData = { ...pageViewData, ...customerData };
    }

    if (order) {
      const orderData: IPageOrderInteractionData =
        this.assemblePageOrderData(order);
      const customerData = this.assemblePageOrderCustomerData(order);

      const ecommerceData = await this.getGTMConfirmationEcommerceDataFromOrder(
        order.orderID.value,
        order.lines,
        order.totals.subtotal
      );

      pageViewData = {
        ...pageViewData,
        ...orderData,
        ...customerData,
        ...ecommerceData
      };
    }

    // Page load product data for the PDP, if there is more than one product
    // it provides page load data for the PLP.
    if (products && products.length > 0) {
      const [product] = products;
      const productModel = ProductModel.from(product);
      const ecommerceData = this.getGTMPageEcommerceData(productModel);
      const { detail } = ecommerceData.ecommerce as IEcommerceDetail;

      // Merge customer data into the page view data.
      pageViewData = {
        ...pageViewData,
        ...ecommerceData,
        ...detail
      };
    }

    // Page load product data for the order confirmation page, it uses line items.
    if (lines && lines.length > 0) {
      const products: Array<ProductLineItemModel> = lines.map((line) => {
        return ProductLineItemModel.from(line);
      });

      const ecommerceData = this.getGTMConfirmationEcommerceData(products);

      // Merge customer data into the page view data.
      pageViewData = {
        ...pageViewData,
        ...ecommerceData
      };
    }

    if (pageViewData.page_type) {
      CookieService.set(
        CookieModel.from({
          key: 'deckersPreviousPage',
          value: pageViewData.page_type
        })
      );
    }

    return pageViewData;
  }

  /**
   * Should turn an interaction event into a gtm event name.
   * @param interactionEvent - The original interaction event name given as an EventType.
   * @returns A GTM event type.
   * @throws If an invalid interaction event is given.
   */
  public getGTMEventName(interactionEvent: EventType): GTMEventType {
    const gtmEvent = this.eventTypeMap.get(interactionEvent);

    if (!gtmEvent) {
      throw new InvalidArgumentError(
        `GTMEvent does not exist for this UserInteractionEvent: ${interactionEvent}`
      );
    }

    return gtmEvent;
  }
}

export default new GTMDataHandler();
