import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { MoneyModel } from '@/services/models/Money';
import type { DTO, Nullable } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { isNullOrEmpty } from '@/utils/null-utils';
import { kebabCase } from '@/utils/string-utils';
import { IPrice, PriceModel } from '../../../Price';
import { IProduct, ProductModel } from '../../../Product';
import ICart from '../../ICart';
import LineItemModel from '../LineItemModel';
import LineItemType from '../LineItemType';
import type IProductLineItem from './IProductLineItem';

/**
 * Represents a Line Item that represents a Product.
 */
export default class ProductLineItemModel
  extends LineItemModel<DTO<IProductLineItem>>
  implements IProductLineItem
{
  /** @inheritDoc  */
  public override readonly type = LineItemType.Product;

  /** @inheritDoc  */
  public readonly pid?: string;

  /** @inheritDoc  */
  public override readonly upc: string;

  /** @inheritDoc  */
  public readonly primaryCategory: string;

  /** @inheritDoc  */
  public readonly styleNumber: string;

  /** @inheritDoc */
  public constructor(dto: DTO<IProductLineItem>) {
    super(dto);

    this.pid = dto.pid;
    this.upc = dto.upc;
    // style number currently doesn't exist in IProductLineItem
    // TODO: Add style number to IProductLineItem
    this.styleNumber = dto.styleNumber ?? dto.sku.split('-')[0];
    this.primaryCategory = dto.primaryCategory;
  }

  /**
   * Creates a line item from a product.
   *
   * @param product - The product to create the line item from.
   * @param uuid - The uuid for this item, it should come from the cart api.
   * @param cart - The cart the line item will belong to.
   * @param [quantity] - The quantity of this product to include in the line item.
   * @param [overridePrice] - A custom price to override the product's original price.
   *
   * @returns A new {@link ProductLineItemModel} created from the supplied product.
   * @throws An {@link InvalidArgumentError} if the supplied product is not a complete variant
   * or is missing required data.
   */
  public static fromProduct(
    product: IProduct,
    uuid: string,
    cart: ICart | string,
    quantity: number = 1,
    overridePrice: Nullable<IPrice> = undefined
  ): ProductLineItemModel {
    const {
      sku,
      name,
      primaryImage,
      price,
      isCompleteVariant,
      group,
      styleNumber,
      upc,
      taxClass
    } = product;

    if (!isCompleteVariant)
      throw new InvalidArgumentError(
        'Only products that are complete variants can be used to create line items.'
      );

    if (!price)
      throw new InvalidArgumentError(
        'The supplied product does not have a price.'
      );

    const cartID = typeof cart === 'string' ? cart : cart.uuid;
    const productModel = ProductModel.from(product);

    if (isNullOrEmpty(upc)) {
      throw new InvalidStateError(
        `The product: ${sku} has no UPC and therefore cannot be added to the cart.`
      );
    }

    return new ProductLineItemModel({
      type: LineItemType.Product,
      upc,
      uuid,
      cartID,
      sku,
      name,
      image: primaryImage,
      group,
      styleNumber,
      primaryCategory: productModel.primaryCategory ?? 'none',
      quantity,
      unitPrice: overridePrice ?? PriceModel.from(price).toDTO(),
      promotions: [],
      // the following values are calculated within the created instance
      subtotal: MoneyModel.fromAmount(0),
      netTotal: MoneyModel.fromAmount(0),
      total: MoneyModel.fromAmount(0),
      tax: MoneyModel.fromAmount(0),
      taxRate: 0,
      taxClass
    });
  }

  /** @inheritDoc */
  public override toDTO(): DTO<IProductLineItem> {
    return {
      ...super.toDTO(),

      pid: this.pid,
      styleNumber: this.styleNumber,
      primaryCategory: this.primaryCategory,
      upc: this.upc
    };
  }

  /**
   * The fully-qualified URL of this product - matches the static path.
   *
   * @example "https://sanuk.com/p/1113694-BLK-08"
   *
   * @returns URL of the product with the size.
   */
  public get url(): string {
    const { origin } = EnvironmentService.url;

    return `${origin}/p/${kebabCase(this.name)}/${this.sku}`;
  }
}
