import { ReviewsService } from '../../isomorphic/ReviewsService';
import { DTO } from '@/type-utils';
import Model from '../Model';

import { IReviews } from './IReviews';
import ReviewModel from './ReviewModel';

/**
 * {@link IPageable} `ReviewsModel`.
 *
 * Allows listing and paginating reviews for a product SKU.
 */
export default class ReviewsModel
  extends Model<DTO<IReviews>>
  implements IReviews
{
  /** @inheritdoc */
  public readonly productSKU: string;

  /** @inheritdoc */
  public readonly pages: Array<Array<ReviewModel>>;

  /** @inheritdoc */
  public readonly pageSize: number;

  /** @inheritdoc */
  public readonly allItems: Array<ReviewModel>;

  /** @inheritdoc */
  public readonly totalPages: number;

  /** @inheritdoc */
  public readonly totalItems: number;

  /** @inheritdoc */
  public readonly currentPage: number;

  /** @inheritdoc */
  public readonly isLoadingNewPage: boolean;

  /**
   * @inheritdoc
   *
   * @param reviews - A `DTO` of {@link IReviews}.
   */
  public constructor(reviews: DTO<IReviews>) {
    super(reviews);

    this.productSKU = reviews.productSKU;

    // Copy 2d array and hidrate DTO-s
    this.pages = reviews.pages.map((page) => {
      return page.map((review) => {
        return ReviewModel.from(review);
      });
    });

    // Flatten all pages into a 1d array
    this.allItems = this.pages.flat();
    this.pageSize = reviews.pageSize;
    this.totalPages = reviews.totalPages;
    this.totalItems = reviews.totalItems;
    this.currentPage = reviews.currentPage;
    this.isLoadingNewPage = reviews.isLoadingNewPage;
  }

  /** @inheritdoc */
  public get hasNext(): boolean {
    return this.currentPage < this.totalPages - 1;
  }

  /** @inheritdoc */
  public get hasPrevious(): boolean {
    // There's no previous page if there are no pages :)
    return this.currentPage > 0 && this.totalPages > 1;
  }

  /**
   * Create a `ReviewsModel` for a specific product.
   *
   * @param sku - Product SKU.
   * @returns A `ReviewsModel` which allows interacting with reviews for this product.
   */
  public static async forProduct(sku: string): Promise<ReviewsModel> {
    return ReviewsService.getReviewsForProduct(sku);
  }

  /** @inheritdoc */
  public async loadPage(pageNumber: number): Promise<Array<ReviewModel>> {
    // If the page is empty, it's either not loaded, or there is only
    // 1 pagination page and it's an empty one (0 item pagination).
    if (this.pages[pageNumber].length === 0 && this.totalItems > 0) {
      // Retrieve the `ReviewsModel` that is only loaded with the specific page
      const reviewsModel = await ReviewsService.retrieveReviews(
        this.productSKU,
        {
          page: pageNumber,
          perPage: this.pageSize
        }
      );

      this.pages[pageNumber] = reviewsModel.pages[pageNumber];
    }

    return this.pages[pageNumber];
  }

  /** @inheritdoc */
  public async nextPage(): Promise<Array<ReviewModel>> {
    return this.hasNext ? this.loadPage(this.currentPage + 1) : [];
  }

  /** @inheritdoc */
  public async previousPage(): Promise<Array<ReviewModel>> {
    return this.hasPrevious ? this.loadPage(this.currentPage - 1) : [];
  }

  /** @inheritdoc */
  public toDTO(): DTO<IReviews> {
    return {
      productSKU: this.productSKU,
      pages: this.pages.map((page) => {
        return page.map((item) => item.toDTO());
      }),
      // allItems[] can be reconstructed by flattening pages[][]
      // therefore it's ommited as an optimization
      allItems: [],
      pageSize: this.pageSize,
      totalPages: this.totalPages,
      totalItems: this.totalItems,
      currentPage: this.currentPage,
      hasNext: this.hasNext,
      hasPrevious: this.hasPrevious,
      isLoadingNewPage: this.isLoadingNewPage
    } as unknown as DTO<IReviews>;
  }
}
