import axios, { AxiosError } from 'axios';

import type { DTO, Nullable } from '@/type-utils';
import MemoryCache from '@/utils/MemoryCache';

// Import services
import { IPlatform, PlatformModel } from '@/services/models/Platform';
import { InvalidArgumentError, ResourceNotFoundError } from '@/utils/errors';
import { isNullOrEmpty } from '@/utils/null-utils';
import Service from '../../Service';

// Import business unit structs
import type { IInventory } from '../../models/Inventory';
import type { IPrice } from '../../models/Price';
import { IProduct, ProductModel } from '../../models/Product';
import type { IRatings } from '../../models/ReviewsModel';

import {
  VariationAttributeID,
  VariationAttributeType
} from '../../models/Product/variation-attributes';
import ServerProductService from '../../serverless/ServerProductService';
import LoggerService from '../LoggerService';

// Import Error types

// Import local resources
import { EnvironmentService } from '../EnvironmentService';
import InventoryService from '../InventoryService';
import ProductServiceMock from './ProductServiceMock';

/** Abstracts most product-centric interactions from third-party services. */
export class ProductService extends Service {
  private productCache: MemoryCache<Promise<DTO<IProduct>>> = new MemoryCache(
    60
  );

  private platformCache: MemoryCache<Promise<DTO<IPlatform>>> = new MemoryCache(
    60
  );

  private client = axios.create({
    baseURL: '/api/product'
  });

  /**
   * Retrieves a product DTO by its SKU.
   * @param sku - The product's Stock Keeping Unit.
   * @returns A Product DTO.
   */
  public async getProductDTO(sku: string): Promise<DTO<IProduct>> {
    if ((typeof window === "undefined"))
      return ServerProductService.getProductDTO(sku);

    // If the model cache already has a valid DTO built for this SKU, just return it.
    if (this.productCache.has(sku)) {
      return this.productCache.get(sku);
    }

    // If no DTO was found in cache for the requested SKU...
    // Get the product family from the server service

    try {
      /* eslint-disable jsdoc/require-jsdoc -- . */
      interface IProductFamilyResponse {
        mainDTO: DTO<IProduct>;
        family: Record<string, DTO<IProduct>>;
      }
      /* eslint-enable jsdoc/require-jsdoc */

      const productFamilyResponsePromise = this.client
        .get<IProductFamilyResponse>('family', {
          params: { sku }
        })
        .then(({ data }) => data);

      const mainDTOPromise = productFamilyResponsePromise.then(
        ({ mainDTO }) => mainDTO
      );

      this.productCache.add(sku, mainDTOPromise);

      const { mainDTO, family } = await productFamilyResponsePromise;

      // Add the entire family to cache (family also includes the main DTO)
      for (const [variantSKU, productDTO] of Object.entries(family)) {
        this.productCache.add(variantSKU, Promise.resolve(productDTO));
      }

      // And return the main DTO.
      return mainDTO;
    } catch (err) {
      // Check if the error was a request error.
      const axiosErr = err as AxiosError;

      // 404 = Model was not found.
      if (axiosErr.isAxiosError && axiosErr.response?.status === 404) {
        throw new ResourceNotFoundError(
          `No Model was found for the SKU "${sku}".`
        );
      }

      throw err;
    }
  }

  /**
   * Retrieves a product DTO by SKU, alongside the DTOs of all the other
   * products belonging to the same parent model (i.e. The product's family).
   *
   * @param sku - The main product's Stock Keeping Unit.
   *
   * @returns An array with the main product DTO as the first index and an object
   * with the product's family as the second index (with SKUs as the keys).
   */
  public async getProductDTOFamily(
    sku: string
  ): Promise<[DTO<IProduct>, Record<string, DTO<IProduct>>]> {
    if ((typeof window === "undefined"))
      return ServerProductService.getProductDTOFamily(sku);

    // First, get the main DTO.
    const mainDTO = await this.getProductDTO(sku);

    // Keep in mind that by getting the main product, the entire family
    // will be fetched and have DTOs made from it; meaning that the
    // entire family will be in the dtoCache after this first call.

    // Extract the SKUs of all the variants from the DTOs variation map.
    const variantSKUs = Object.values(mainDTO.variationMap).flatMap(
      ({ sku }) => sku
    );

    // Retrieve all of the variants concurrently.
    const family = await Promise.all(
      variantSKUs.map(async (newSKU) => this.getProductDTO(newSKU))
    );

    // Make an empty map...
    const map: Record<string, DTO<IProduct>> = {};

    // ...and index each fetched DTO in the family by its SKU.
    for (const dto of family) {
      map[dto.sku] = dto;
    }

    return [mainDTO, map];
  }

  /**
   * Retrieves a product by its SKU.
   * @param sku - The product's Stock Keeping Unit.
   * @returns A Product Model.
   */
  public async getProduct(sku: string): Promise<ProductModel> {
    return ProductModel.from(await this.getProductDTO(sku));
  }

  /**
   * Gets a product's price.
   * @param product - The product to get the price for.
   * @returns A Promise with the product's price.
   */
  public async getProductPrice(product: IProduct): Promise<IPrice> {
    LoggerService.warn(new Error('Not implemented, returning mock value.'));
    return Mock.getProductPrice(product);
  }

  /**
   * Gets a product's inventory.
   * @param product - The product to get the inventory for.
   * @returns A Promise with the product's inventory.
   */
  public async getProductInventory(product: IProduct): Promise<IInventory> {
    const { upc } = product;

    if (isNullOrEmpty(upc)) {
      throw new InvalidArgumentError(
        `Cannot get inventory for product with SKU "${product.sku}" since it doesn't have a UPC. Please make sure this is a complete variant.`
      );
    }

    return InventoryService.getProductInventory(upc);
  }

  /**
   * Gets a product's rating.
   * @param product - The product to get the rating for.
   * @returns A Promise with the product's rating.
   */
  public async getProductRating(product: IProduct): Promise<IRatings> {
    LoggerService.warn(new Error('Not implemented, returning mock value.'));
    return Mock.getProductRating(product);
  }

  /**
   * Given a set of attribute selections, construct a string that represents
   * the product with those selections in the {@link IProduct.variationMap variation map}.
   *
   * @param attributeSelection - The set of attribute selections that must be
   * met.
   *
   * @returns The key of the product that would meet the provided selections.
   * This key may or may not exist in the product's variation map.
   */
  private getVariationKey(attributeSelection: {
    [key in VariationAttributeType]?: VariationAttributeID;
  }): string {
    const { color, size, width } = attributeSelection;

    const attributeKeys = [];

    if (!isNullOrEmpty(color)) attributeKeys.push(color);
    if (!isNullOrEmpty(size)) attributeKeys.push(size);
    if (!isNullOrEmpty(width)) attributeKeys.push(width);

    return attributeKeys.join(':');
  }

  /**
   * Given a product and an attribute selection map, finds the product's variant that satisfies said attribute selection.
   *
   * @param product - The product to search variants for.
   * @param attributeSelection - The attribute selection the variant must satisfy.
   *
   * @returns The SKU of the product's variant that satisfies said attribute selection.
   */
  public getProductSKUBySelectedAttributes(
    product: IProduct,
    attributeSelection: {
      [key in VariationAttributeType]?: VariationAttributeID;
    }
  ): Nullable<string> {
    const { color, size, width } = attributeSelection;

    const variationKey =
      // If all attributes are deselected, use the base model.
      isNullOrEmpty(color) && isNullOrEmpty(size) && isNullOrEmpty(width)
        ? product.styleNumber
        : // Get the corresponding variation key otherwise.
          this.getVariationKey(attributeSelection);

    const { sku } = product.variationMap[variationKey] ?? {};

    return sku ?? null;
  }

  /**
   * Given a product and an attribute selection map, finds the UPC of the
   * product's variant that satisfies said attribute selection.
   *
   * @param product - The product to search variants for.
   * @param attributeSelection - The attribute selection the variant must
   * satisfy.
   *
   * @returns The UPC of the product's variant that satisfies said attribute
   * selection.
   */
  public getProductUPCBySelectedAttributes(
    product: IProduct,
    attributeSelection: {
      [key in VariationAttributeType]?: VariationAttributeID;
    }
  ): Nullable<string> {
    const { color, size, width } = attributeSelection;

    const variationKey =
      // If all attributes are deselected, use the base model.
      isNullOrEmpty(color) && isNullOrEmpty(size) && isNullOrEmpty(width)
        ? product.styleNumber
        : // Get the corresponding variation key otherwise.
          this.getVariationKey(attributeSelection);

    const { upc } = product.variationMap[variationKey] ?? {};

    return upc ?? null;
  }

  /**
   * Given a product and an attribute selection map, finds the online status of
   * the product's variant that satisfies said attribute selection.
   *
   * @param product - The product to search variants for.
   * @param attributeSelection - The attribute selection the variant must
   * satisfy.
   *
   * @returns The online status of the product's variant that satisfies said
   * attribute selection.
   */
  public getProductOnlineStatusBySelectedAttributes(
    product: IProduct,
    attributeSelection: {
      [key in VariationAttributeType]?: VariationAttributeID;
    }
  ): boolean {
    const { color, size, width } = attributeSelection;

    const variationKey =
      // If all attributes are deselected, use the base model.
      isNullOrEmpty(color) && isNullOrEmpty(size) && isNullOrEmpty(width)
        ? product.styleNumber
        : // Get the corresponding variation key otherwise.
          this.getVariationKey(attributeSelection);

    const { isOnline } = product.variationMap[variationKey] ?? {};

    return Boolean(isOnline);
  }

  /**
   * Imports prebuilt DTOs to the DTO Cache. Used to share server-fetched
   * DTOs with the client-side via page props.
   *
   * @param type - The string literal 'products'.
   * @param dtos - A record of product DTOs to import as values, and their SKUs as keys.
   */
  public importDTOsToCache(
    type: 'products',
    dtos: Record<string, DTO<IProduct>>
  ): void;

  /**
   * Imports prebuilt DTOs to the DTO Cache. Used to share server-fetched
   * DTOs with the client-side via page props.
   *
   * @param type - The string literal 'platforms'.
   * @param dtos - A record of platform DTOs to import as values, and their IDs as keys.
   */
  public importDTOsToCache(
    type: 'platforms',
    dtos: Record<string, DTO<IPlatform>>
  ): void;

  /**
   * Imports prebuilt DTOs to the DTO Cache. Used to share server-fetched
   * DTOs with the client-side via page props.
   *
   * @param type - The type of DTOs to import.
   * @param dtos - A record of DTOs to import as values, and some identifier as keys.
   */
  public importDTOsToCache(
    type: 'products' | 'platforms',
    dtos: Record<string, DTO<unknown>>
  ): void {
    if (type === 'products') {
      for (const [key, value] of Object.entries(dtos)) {
        const product = value as DTO<IProduct>;
        this.productCache.add(key, Promise.resolve(product));
      }
    } else if (type === 'platforms') {
      for (const [key, value] of Object.entries(dtos)) {
        const platform = value as DTO<IPlatform>;
        this.platformCache.add(key, Promise.resolve(platform));

        for (const product of platform.products) {
          this.productCache.add(
            product.sku,
            Promise.resolve(product as DTO<IProduct>)
          );
        }
      }
    }
  }

  /**
   * Gets a {@link IPlatform platform} by its ID.
   * @param platfromId - The ID of the platform to get.
   * @returns A {@link IPlatform platform}.
   * @example
   * const sequencePlatform = await ProductService.getPlatformById('Sequence');
   */
  public async getPlatformById(platfromId: string): Promise<PlatformModel> {
    const dto = await this.getPlatformDTOById(platfromId);
    return PlatformModel.from(dto);
  }

  /**
   * Retrieves a platform DTO by its ID.
   * @param platfromId - The platform's ID.
   * @returns A platform DTO.
   */
  private async getPlatformDTOById(
    platfromId: string
  ): Promise<DTO<IPlatform>> {
    if ((typeof window === "undefined")) {
      return ServerProductService.getPlatformById(platfromId);
    }

    if (this.platformCache.has(platfromId)) {
      return this.platformCache.get(platfromId);
    }

    const platformPromise = this.client
      .get<DTO<IPlatform>>(`platform/${platfromId}`)
      .then(({ data }) => data);

    // add the promise to the cache to prevent duplicate requests
    this.platformCache.add(platfromId, platformPromise);

    const platform = await platformPromise;

    // update products cache with most recent base products
    // Note: this does not update any sub-variant
    for (const product of platform.products) {
      this.productCache.add(
        product.sku,
        Promise.resolve(product as DTO<IProduct>)
      );
    }

    return platform;
  }
}

const _mock = new ProductServiceMock(ProductService);
export default ProductService.withMock(_mock);

// Initialize a standalone mock to selectively return mock data for unimplemented methods.
// Moved below the initialization.
export const Mock = _mock.getMock();
