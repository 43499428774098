/** Enumerates valid user types. */
enum UserType {
  /**
   * Represents customers that interact with the site without
   * having an {@link IAccount Account}.
   *
   * @see {@link IAnonymousUser}
   */
  Anonymous,

  /**
   * Represents customers that do have an {@link IAccount Account}.
   * @see {@link IAuthenticatedUser}
   */
  Authenticated,

  /**
   * Represents call center representatives; which have special privileges
   * unlike the types of users assigned to customers.
   */
  CallCenterRepresentative,

  /**
   * Represents retail associates; which have special privileges
   * unlike the types of users assigned to customers.
   */
  RetailAssociate
}

export default UserType;
