import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { RejectedCouponError } from './RejectedCouponError';

export const { CouponExpiredError } = errorFactory(
  'CouponExpiredError',
  RejectedCouponError
);

/** Error for an expired coupon. */
export type CouponExpiredError = InstanceTypeOf<typeof CouponExpiredError>;
