import type { DTO, Nullable } from '@/type-utils';
import Model from '../Model';
import type IProduct from '../Product/IProduct';
import type IProductVariationGroup from '../Product/IProductVariationGroup';
import type { ProductVariationGroupType } from '../Product/IProductVariationGroup';
import ProductModel from '../Product/ProductModel';
import ProductVariationGroupModel from '../Product/ProductVariationGroupModel';
import type IPlatform from './IPlatform';

/**
 * Represents a product {@link IPlatform platform}, e.g "Classic Short".
 */
class PlatformModel extends Model<DTO<IPlatform>> implements IPlatform {
  /** @inheritdoc */
  public readonly id: string;

  /** @inheritdoc */
  public readonly variations: Array<ProductVariationGroupModel>;

  /** @inheritdoc */
  public readonly products: Array<ProductModel>;

  /** @inheritdoc */
  public readonly variationUPCs: Array<string>;

  /**
   * Creates a new PlatformModel.
   * @param dto - The DTO to create the model from.
   */
  public constructor(dto: DTO<IPlatform> | IPlatform) {
    super(dto as DTO<IPlatform>);

    this.id = dto.id;

    this.variations = dto.variations.map((variation) =>
      ProductVariationGroupModel.from(variation)
    );

    this.products = dto.products.map((product) => ProductModel.from(product));

    this.variationUPCs = [...dto.variationUPCs];
  }

  /**
   * Retrieves the variation group with the given type.
   * @param type - The type of variation group to retrieve.
   * @returns The variation group with the given type, or `null` if no such variation group exists.
   * @example
   * const platform = PlatformModel.from(platformDTO);
   * const materialVariationGroup = platform.getVariationGroup('material');
   */
  public getVariationGroupByType(
    type: ProductVariationGroupType
  ): Nullable<ProductVariationGroupModel> {
    return this.variations.find((variation) => variation.type === type) ?? null;
  }

  /**
   * Checks if the platform contains the specified variation group.
   * @param variationGroup - The variation group to check for.
   * @returns `true` if the platform contains the variation group, otherwise `false`.
   */
  public hasVariationGroup(
    variationGroup: ProductVariationGroupType | IProductVariationGroup
  ): boolean {
    const variationGroupType =
      typeof variationGroup === 'string' ? variationGroup : variationGroup.type;

    return this.variations.some(
      (variation) => variation.type === variationGroupType
    );
  }

  /**
   * Checks if the platform "includes" the specified product.
   *
   * **Note:** This method does not imply a product variant can be found within {@link products},
   * since `products` only contains base products.
   *
   * @param product - The product or {@link IProduct.styleNumber styleNumber} to check for.
   * @returns `true` if the platform "includes" the product, otherwise `false`.
   */
  // TODO: A future enhancement could be to allow any SKU to be passed in, rather than just the style number.
  public hasProductVariant(product: string | IProduct): boolean {
    const styleNumber =
      typeof product === 'string' ? product : product.styleNumber;

    return this.products.some((p) => p.styleNumber === styleNumber);
  }

  /** @inheritdoc */
  public override toDTO(): DTO<IPlatform> {
    return {
      id: this.id,
      variations: this.variations.map(
        (variation) => variation.toDTO() as IProductVariationGroup
      ),
      products: this.products.map((product) => product.toDTO()),
      variationUPCs: this.variationUPCs
    };
  }
}

export default PlatformModel;
