/**
 * The group name that a product can be assigned. This can come from the
 * PIM or can be interpreted from the size string on a completed variant.
 */
export enum GroupName {
  Kids = 'kids',
  Men = 'men',
  Unisex = 'unisex',
  AllGender = 'all-gender',
  Women = 'women',
  Youth = 'youth'
}

export const GENDER_MAP: Record<string, GroupName> = {
  W: GroupName.Women,
  M: GroupName.Men,
  U: GroupName.Unisex,
  'M/W': GroupName.AllGender,
  K: GroupName.Kids,
  Y: GroupName.Youth
};
