'use client';

import type {
  ConfiguredLocaleString,
  ILocale
} from '@/constructs/LocaleSchema';
import I18NService from '@/services/isomorphic/I18NService';
import { maybe } from '@/utils/null-utils';
import { useParams } from 'next/navigation';

/**
 * A hook that gets the current lang locale from the useParams hook or
 * from a cached request. If used on the pages router
 * it should use the CurrentRequestService to get the locale.
 * @returns The current lang locale.
 * @throws An error if the langLocale cannot be constructed.
 */
export function useLocale(): ILocale {
  /**
   * A record of the current route's dynamic params.
   *
   * **Note**: In Pages Router, this will return `null` on the initial render. It
   * will be updated once the router is ready.
   */
  const params = maybe(useParams());

  const lang = params?.lang;
  if (typeof lang === 'string' && lang !== '') {
    // If the lang is in the params get the lang locale from the string.
    return I18NService.getLocaleFromString(lang as ConfiguredLocaleString);
  }

  try {
    // If the lang is not in the params, try to get the locale from normal means.
    // This will be true for pages router and in the app router.
    return I18NService.currentLocale;
  } catch (error) {
    throw new Error(
      'Could not get the lang locale from the params or the cached request.'
    );
  }
}
