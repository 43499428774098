import { ChainOfResponsibility } from '@/services/utils/chain-of-responsibility';
import Service from '../../Service';
import { EnvironmentService } from '../EnvironmentService';

import siteCached from '../../utils/siteCached';
import LoggerService from '../LoggerService';
import PersonalizationUserInteractionHandler from '../PersonalizationService/PersonalizationUserInteractionHandler';
import CoveoUserInteractionHandler from '../integrations/CoveoAnalyticsService/CoveoUserInteractionHandler';
import GTMUserInteractionHandler from '../integrations/GTMService/GTMUserInteractionHandler';
import { InteractionDetails } from './IInteractionDetails';

/**
 * A service for handling user interactions. These come from
 * other actions and can be passed to other.
 */
class UserInteractionService extends Service {
  /**
   * A {@link ChainOfResponsibility} with various user interaction related handlers.
   * @returns A {@link ChainOfResponsibility}.
   */
  @siteCached
  private get actionHandler(): ChainOfResponsibility<
    InteractionDetails,
    Promise<void>
  > {
    const cor = new ChainOfResponsibility<InteractionDetails, Promise<void>>();
    cor.addHandler(new CoveoUserInteractionHandler());
    cor.addHandler(new GTMUserInteractionHandler());
    cor.addHandler(new PersonalizationUserInteractionHandler());

    return cor;
  }

  /**
   * Makes an action, this method orchestrates the different ways
   * we will record interactions. This will perform different actions depending
   * on what 'action' is supplied in the interactionDetails.
   * @param interactionDetails - The interaction containing the information needed
   * to distribute to various event records.
   */
  public async makeAction(
    interactionDetails: InteractionDetails
  ): Promise<void> {
    // This keeps the events from being fired in storybook. It should
    // only fire on the client-side when in a real page context.
    if (!(typeof __isolatedContext__ !== "undefined") && interactionDetails.action) {
      try {
        await this.actionHandler.handle(interactionDetails, {
          // must be `false` as required by the API design
          mustHandle: false,
          // must be `true` to ensure all handlers have a
          // chance to process the event before returning
          runAllHandlersAsSideEffects: true
        });
      } catch (error) {
        /**
         * There should be no circumstances where analytics actually disrupts the user action.
         */
        LoggerService.error(error as Error);
      }
    }
  }
}

export default new UserInteractionService();
