import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { RejectedCouponError } from './RejectedCouponError';

export const { CouponLimitReachedError } = errorFactory(
  'CouponLimitReachedError',
  RejectedCouponError
);

/** Error for a coupon that's reached its use limit. */
export type CouponLimitReachedError = InstanceTypeOf<
  typeof CouponLimitReachedError
>;
