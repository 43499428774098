import { Constructor, DTO } from '@/type-utils';
import { mocked } from '@/configs';

import { MOCK_ACCOUNT } from '@/services/models/Account/mocks';
import Service from '../../Service';
import MockService, { MockState, ServiceMock } from '../MockService';
import { AccountModel, IAccount } from '../../models/Account';
import type { IAuthenticatedUserTokens } from '../../models/User/AuthenticatedUser';

import type { AccountService } from './AccountService';

const initialState = {};

/** Mock implementation of the {@link AccountService}. */
export default class AccountServiceMock extends ServiceMock<AccountService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): AccountService {
    return MockService.getMockOf(this.service) as unknown as AccountService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<AccountService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.AccountService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        updateAccount: async (account: IAccount): Promise<void> => {},

        getUserAccount: async (
          userID: string,
          tokens: IAuthenticatedUserTokens
        ): Promise<AccountModel> => {
          return AccountModel.from(MOCK_ACCOUNT);
        }
      },
      {},
      this.state
    );
  }
}
