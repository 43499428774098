import { DTO } from '@/type-utils';

import { timeStampableToDate } from '@/utils/time-utils';
import ISavedAddress from '../Address/ISavedAddress';
import Model from '../Model';
import { ProfileModel } from './Profile';
import { WalletModel } from './Wallet';

import type { IAccount } from '.';

/**
 * Represents a customer's Account: a collection of the customer's
 * saved information, such as payment methods, addresses and personal info.
 */
export default class AccountModel
  extends Model<DTO<IAccount>>
  implements IAccount
{
  /** @inheritdoc */
  public readonly id: string;

  /** @inheritdoc */
  public readonly profile: ProfileModel;

  /** @inheritdoc */
  public readonly wallet: WalletModel;

  /** @inheritdoc */
  public readonly savedAddresses: Array<ISavedAddress>;

  /** @inheritdoc */
  public readonly lastLoggedIn: Date;

  /** @inheritdoc */
  public readonly orderHistory: ReadonlyArray<{
    /** The order's unique identifier. */
    orderID: string;

    /** The date the order was placed on. */
    placeDate: Date;
  }>;

  /** @inheritdoc */
  public readonly activeCartID: string | null;

  /** @inheritdoc */
  public constructor(dto: DTO<IAccount>) {
    super(dto);

    this.id = dto.id;
    this.profile = ProfileModel.from(dto.profile);
    this.wallet = WalletModel.from(dto.wallet);
    this.savedAddresses = [...dto.savedAddresses];
    this.lastLoggedIn = new Date(dto.lastLoggedIn);
    this.activeCartID = dto.activeCartID;

    this.orderHistory = dto.orderHistory.map(({ orderID, placeDate }) => ({
      orderID,
      placeDate: timeStampableToDate(placeDate)
    }));
  }

  /** @inheritDoc */
  public toDTO(): DTO<IAccount> {
    return {
      id: this.id,
      profile: this.profile.toDTO(),
      wallet: this.wallet.toDTO(),
      savedAddresses: this.savedAddresses,
      lastLoggedIn: this.lastLoggedIn.toString(),

      orderHistory: this.orderHistory.map(({ orderID, placeDate }) => ({
        orderID,
        placeDate: placeDate.toISOString()
      })),

      activeCartID: this.activeCartID
    };
  }
}
