import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { NotImplementedError } = errorFactory('NotImplementedError', {
  mapsToHTTPError: 'NotImplementedError'
} as IHTTPErrorMetaData);

/** Generic Error to use when a feature is not yet implemented. */
export type NotImplementedError = InstanceTypeOf<typeof NotImplementedError>;
