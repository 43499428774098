/* eslint-disable no-console -- The logger mock uses `console.log` to log messages. */
import { mocked } from '@/configs';
import { Constructor } from '@/type-utils';
import Service from '../../Service';
import { SessionModel } from '../../models/Session';
import MockService, { MockState, ServiceMock } from '../MockService';
import type { SessionService } from './SessionService';

const initialState = {};

/**
 * Mock implementation of the SessionService class.
 */
export default class SessionServiceMock extends ServiceMock<SessionService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public get currentSession(): Promise<SessionModel> {
    return Promise.resolve(
      SessionModel.from({
        id: 'a41e9ab2-0c3e-44d0-ae5e-1e49dd0ab8c7',
        data: {
          isSecure: false,
          data: {}
        },
        secureData: null
      })
    );
  }

  /** @inheritdoc */
  public getMock(): SessionService {
    return MockService.getMockOf(this.service) as unknown as SessionService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<SessionService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    MockService.mockService(
      mocked.SessionService,
      service,
      {},
      {
        getCurrentSession: () => this.currentSession
      },
      this.state
    );
  }
}
