import { Country } from '@/constructs/Country';
import { DTO, Nullable } from '@/type-utils';

import { msgf } from '@/services/isomorphic/I18NService';

import { Brand } from '@/constructs/Brand';
import type { IShippingMethod } from '.';
import Model from '../Model';
import { MoneyModel } from '../Money';
import { checkout_shipping_businessDaysRange } from "@/lang/__generated__/ahnu/checkout_shipping_businessDaysRange";
import { checkout_shipping_businessDaysSingle } from "@/lang/__generated__/ahnu/checkout_shipping_businessDaysSingle";

/** Represents a shipping method. */
export default class ShippingMethodModel
  extends Model<DTO<IShippingMethod>>
  implements IShippingMethod
{
  /** @inheritdoc */
  public readonly id: string;

  /** @inheritdoc */
  public readonly uid: string;

  /** @inheritdoc */
  public readonly country: Country;

  /** @inheritdoc */
  public readonly brand: Brand;

  /** @inheritdoc */
  public readonly name: string;

  /** @inheritdoc */
  public readonly description: string;

  /** @inheritdoc */
  public readonly shippingCost: MoneyModel;

  /** @inheritdoc */
  public readonly cutoffTime: string;

  /** @inheritdoc */
  public readonly latestDeliveryDays?: Nullable<number>;

  /** @inheritdoc */
  public readonly earliestDeliveryDays?: Nullable<number>;

  /** @inheritdoc */
  public readonly tax: MoneyModel;

  private _taxRate: number;

  /** @inheritdoc */
  public constructor(shippingMethod: DTO<IShippingMethod>) {
    super(shippingMethod);

    this.id = shippingMethod.id;
    this.uid = shippingMethod.uid;
    this.country = shippingMethod.country;
    this.brand = shippingMethod.brand;
    this.name = shippingMethod.name;
    this.description = shippingMethod.description;

    this.shippingCost = MoneyModel.from(shippingMethod.shippingCost);

    this.cutoffTime = shippingMethod.cutoffTime;

    this.tax = MoneyModel.from(shippingMethod.tax);
    this._taxRate = shippingMethod.taxRate;

    // UNCOMMENT WHEN USING DATES INSTEAD OF DAYS IN DELIVERY RANGES

    // // Empty string is falsy,
    // // so checking if the following dates are nullish instead.
    // this.latestDeliveryDays = !isNullish(shippingMethod.latestDeliveryDays)
    //   ? timeStampableToDate(shippingMethod.latestDeliveryDays)
    //   : shippingMethod.latestDeliveryDays;

    // this.earliestDeliveryDays = !isNullish(shippingMethod.earliestDeliveryDays)
    //   ? timeStampableToDate(shippingMethod.earliestDeliveryDays)
    //   : shippingMethod.earliestDeliveryDays;

    // Empty string is falsy,
    // so checking if the following dates are nullish instead.
    this.latestDeliveryDays = shippingMethod.latestDeliveryDays;
    this.earliestDeliveryDays = shippingMethod.earliestDeliveryDays;
  }

  /** @returns A formatted delivery date for this shipping method. */
  public get formattedDeliveryDate(): Nullable<string> {
    if (
      typeof this.latestDeliveryDays !== 'number' ||
      !Number.isFinite(this.latestDeliveryDays)
    ) {
      return null;
    }

    // UNCOMMENT WHEN USING DATES INSTEAD OF DAYS IN DELIVERY RANGES

    // return formatDeliveryDate(
    //   this.latestDeliveryDays,
    //   this.earliestDeliveryDays
    // );

    if (
      typeof this.earliestDeliveryDays === 'number' &&
      Number.isFinite(this.earliestDeliveryDays)
    ) {
      return msgf(checkout_shipping_businessDaysRange, {
        minBusinessDays: this.earliestDeliveryDays,
        maxBusinessDays: this.latestDeliveryDays
      });
    }

    return msgf(checkout_shipping_businessDaysSingle, {
      businessDays: this.latestDeliveryDays
    });
  }

  /** @inheritdoc */
  public get taxRate(): number {
    return this._taxRate;
  }

  /** @inheritdoc */
  public override update(dto: Partial<DTO<IShippingMethod>>): void {
    if (dto.tax) {
      this.tax.update(dto.tax);
    }

    if (dto.taxRate !== undefined && Number.isFinite(dto.taxRate)) {
      this._taxRate = dto.taxRate;
    }
  }

  /** @inheritdoc */
  public toDTO(): DTO<IShippingMethod> {
    return {
      id: this.id,
      uid: this.uid,
      country: this.country,
      brand: this.brand,
      name: this.name,
      description: this.description,
      shippingCost: this.shippingCost.toDTO(),
      cutoffTime: this.cutoffTime,
      tax: this.tax.toDTO(),
      taxRate: this.taxRate,

      latestDeliveryDays: this.latestDeliveryDays,
      earliestDeliveryDays: this.earliestDeliveryDays
    };
  }
}
