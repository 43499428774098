/**
 * Represents a tax class for a product, or in other words,
 * the category of the product for tax purposes.
 *
 * Generally, these values will correspond to `taxCodes` in AvaTax.
 * @see {@link https://taxcode.avatax.avalara.com/ Avalara Tax Codes Search}
 */
enum TaxClass {
  ShoesAndLaces,
  ShoeInserts,
  ChildrenClothing,
  ShoeInsoles
}

export default TaxClass;
