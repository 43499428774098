import { USState } from '@/constructs/provinces/US/USState';
import { Country } from '../../../isomorphic/I18NService';
import IAddressWithPhoneNumber from '../IAddressWithPhoneNumber';

export default {
  addressLine1: '123 Leroux st.',
  city: 'Flagstaff',
  stateProvince: USState.AZ,
  country: 'US' as Country,
  zipPostalCode: '86001',
  firstName: 'first',
  lastName: 'last',
  phoneNumber: '+14325551212'
} as IAddressWithPhoneNumber;
