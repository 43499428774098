import { mocked } from '@/configs';
import Currency from '@/constructs/Currency';
import type { PlatformModel } from '@/services/models/Platform';
import type { IPrice } from '@/services/models/Price';
import type { IRatings } from '@/services/models/ReviewsModel';
import type { Constructor, DTO, Nullable } from '@/type-utils';
import { waitPromise } from '@/utils/async-utils';
import { ResourceNotFoundError } from '@/utils/errors';
import type { IInventory } from '../../models/Inventory';
import { IProduct, ProductModel } from '../../models/Product';

import {
  VariationAttributeID,
  VariationAttributeType
} from '../../models/Product/variation-attributes';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { ProductService } from './ProductService';
import { asyncMockProductsImport } from './mocks/asyncMockProductsImport';

const initialState = {};

/**
 * Mock implementation of the ProductService class.
 */
class ProductServiceMock extends ServiceMock<ProductService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): ProductService {
    return MockService.getMockOf(this.service) as ProductService;
  }

  /** @inheritdoc */
  public constructor(private service: Constructor<ProductService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<ProductService>
  ): void {
    const mockEnabled: boolean = mocked.ProductService;
    MockService.mockService(
      mockEnabled,
      service,
      {
        getProduct: async (sku: string): Promise<ProductModel> => {
          const mockData = await asyncMockProductsImport;
          const dto = mockData[sku] as DTO<IProduct>;

          if (dto?.sku === sku) {
            return ProductModel.from(dto);
          }

          throw new ResourceNotFoundError(
            `No product with SKU "${sku}" was found.`
          );
        },

        getProductDTO: async (sku: string): Promise<DTO<IProduct>> => {
          const mockData = await asyncMockProductsImport;
          const dto = mockData[sku];

          if (dto) {
            return dto;
          }

          throw new ResourceNotFoundError(
            `No product with SKU "${sku}" was found.`
          );
        },

        getProductDTOFamily: async (
          sku: string
        ): Promise<[DTO<IProduct>, Record<string, DTO<IProduct>>]> => {
          const mockData = await asyncMockProductsImport;
          const mainDTO = mockData[sku];

          const modelID = sku.split('-')[0];
          const family: Record<string, DTO<IProduct>> = {};

          for (const k of Object.keys(mockData)) {
            if (k.startsWith(modelID)) {
              family[k] = mockData[k as keyof typeof mockData];
            }
          }

          if (mainDTO) {
            return [mainDTO, family];
          }

          throw new ResourceNotFoundError(
            `No product with SKU "${sku}" was found.`
          );
        },

        getProductPrice: (product: IProduct): Promise<IPrice> => {
          return Promise.resolve({
            currentPrice: {
              amount: '1',
              currency: Currency.USD
            },
            retailPrice: {
              amount: '1',
              currency: Currency.USD
            },
            isDiscounted: true
          });
        },

        getProductInventory: async (product: IProduct): Promise<IInventory> => {
          return {
            upc: '197634237862',
            distributionCenterCodes: ['US1'],
            inventoryStatus: {
              US: {
                allocation: 17,
                allocationTimestamp: '2023-02-08T02:02:35.37Z',
                upcomingAllocation: 0,
                inStockDateTime: '2020-09-03',
                perpetual: false
              }
            }
          };
        },

        getProductRating: async (product: IProduct): Promise<IRatings> => {
          return waitPromise<IRatings>(
            { averageRating: 3.5, numberOfRatings: 18 },
            100
          );
        },

        // New Methods.
        getProductSKUBySelectedAttributes: (
          product: IProduct,
          attributeSelection: {
            [key in VariationAttributeType]?: VariationAttributeID;
          }
        ): Nullable<string> => {
          const { color, size, width } = attributeSelection;

          const attributeKeys = [];

          if (color) attributeKeys.push(color);
          if (size) attributeKeys.push(size);
          if (width) attributeKeys.push(width);

          // If all attributes were deselected, return the base model.
          if (attributeKeys.length <= 0) return product.styleNumber;

          const productKey = attributeKeys.join(':');

          const { sku } = product.variationMap[productKey] ?? {};

          return sku ?? product.styleNumber;
        },

        getProductUPCBySelectedAttributes: (
          product: IProduct,
          attributeSelection: {
            [key in VariationAttributeType]?: VariationAttributeID;
          }
        ): Nullable<string> => {
          const { color, size, width } = attributeSelection;

          const attributeKeys = [];

          if (color) attributeKeys.push(color);
          if (size) attributeKeys.push(size);
          if (width) attributeKeys.push(width);

          const productKey = attributeKeys.join(':');

          const { upc } = product.variationMap[productKey] ?? {};

          return upc ?? null;
        },

        getProductOnlineStatusBySelectedAttributes: (
          product: IProduct,
          attributeSelection: {
            [key in VariationAttributeType]?: VariationAttributeID;
          }
        ): boolean => {
          const { color, size, width } = attributeSelection;

          const attributeKeys = [];

          if (color) attributeKeys.push(color);
          if (size) attributeKeys.push(size);
          if (width) attributeKeys.push(width);

          const productKey = attributeKeys.join(':');

          const { isOnline } = product.variationMap[productKey] ?? {};

          return Boolean(isOnline);
        },

        importDTOsToCache: (
          type: string,
          dtos: Record<string, DTO<unknown>>
        ): void => {
          // do nothing
        },

        getPlatformById: async (id: string): Promise<PlatformModel> => {
          throw new Error('Method not implemented.');
        }
      },
      {},
      this.state
    );
  }
}

export default ProductServiceMock;
