import MOCK_SAVED_ADDRESS from '../../Address/mocks/MOCK_SAVED_ADDRESS';
import { IAccount } from '../IAccount';
import MOCK_PROFILE from '../Profile/mocks/MOCK_PROFILE';
import MOCK_WALLET from '../Wallet/mocks/MOCK_WALLET';

export default {
  id: 'HKDEAQVCBPLYWM2023',
  profile: MOCK_PROFILE,
  wallet: MOCK_WALLET,
  savedAddresses: [MOCK_SAVED_ADDRESS],
  lastLoggedIn: 'Mon Apr 17 2023 18:02:50 GMT-0600 (Central Standard Time)',
  orderHistory: [
    {
      orderID: 'AUUS1FBHYD9HVN',
      placeDate: '2024-01-15T17:16:06.899Z'
    },
    {
      orderID: 'AUUS1F3F1V3192',
      placeDate: '2024-01-15T17:16:26.691Z'
    },
    {
      orderID: 'AUUS1FXA6D248N',
      placeDate: '2024-01-15T17:11:54.009Z'
    }
  ],
  activeCartID: null
} as IAccount;
