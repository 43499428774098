import type { IHandler } from '@/services/utils/chain-of-responsibility';
import type { IIntermediateCalculationState, ITotals } from '..';
import TaxService from '../../TaxService';

/**
 * A handler for calculating the tax for a cart.
 */
export default class CalculateCartTaxHandler
  implements IHandler<IIntermediateCalculationState, Promise<ITotals>>
{
  /**
   * Calculates the tax for a cart and updates `totals`.
   *
   * @todo
   * **Note**: Currently, this implementation relies on the `TaxService` to calculate the whole
   * tax for the cart in a single step. This is not ideal, because it means that the tax calculation
   * is not incremental. In the future, we should refactor this to update the tax incrementally, the same
   * as the other handlers.
   *
   * @param requestData - The request data to pass to process.
   * @param next - A function to pass the "request" to the next handler in some chain.
   * @returns A promise resolving to an object of various cart totals.
   */
  public async handle(
    requestData: IIntermediateCalculationState,
    next: (requestData: IIntermediateCalculationState) => Promise<ITotals>
  ): Promise<ITotals> {
    const { cartModel, totals } = requestData;

    const cartTax = await TaxService.getCartTax({
      ...cartModel.toDTO(),

      // update with current totals
      linesDiscount: totals.linesDiscount.toDTO(),
      cartDiscount: totals.cartDiscount.toDTO(),
      discount: totals.discount.toDTO(),
      shippingCost: totals.shippingCost.toDTO(),
      tax: totals.tax.total,
      total: totals.total.toDTO()
    });

    // The tax total is already rounded to the nearest valid currency amount.
    // See the note above on why we "set" the tax rather than add it.
    totals.tax = cartTax;
    totals.total.addAmount(cartTax.total);

    return next(requestData);
  }
}
