import { mocked } from '@/configs';
import type { Constructor, DTO } from '@/type-utils';

import { CartTaxModel } from '@/services/models/Cart/Tax';
import type { ICart } from '../../models/Cart';
import { MoneyModel } from '../../models/Money';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { TaxService } from './TaxService';

const initialState = {};

/** Mock implementation of the {@link TaxService}. */
export default class TaxServiceMock extends ServiceMock<TaxService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): TaxService {
    return MockService.getMockOf(this.service) as TaxService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<TaxService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Constructor<TaxService>): void {
    const mockEnabled: boolean = mocked.TaxService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getCartTax: async (cart: DTO<ICart>) => {
          const rate = 0.08;
          const lines = cart.items.map((item) => ({
            uuid: item.uuid,
            tax: MoneyModel.from(item.netTotal).multiplyBy(rate),
            rate
          }));
          if (cart.selectedShippingMethod) {
            lines.push({
              uuid: cart.selectedShippingMethod.uid,
              tax: MoneyModel.from(
                cart.selectedShippingMethod.shippingCost
              ).multiplyBy(rate),
              rate
            });
          }

          return CartTaxModel.from({
            uuid: cart.uuid,
            total: MoneyModel.from(cart.total).multiplyBy(rate),
            lines
          });
        }
      },
      {},
      this.state
    );
  }
}
