import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { RejectedCouponError } from './RejectedCouponError';

export const { CouponAlreadyAppliedError } = errorFactory(
  'CouponAlreadyAppliedError',
  RejectedCouponError
);

/** Error for an coupon that's not applicable to a cart. */
export type CouponAlreadyAppliedError = InstanceTypeOf<
  typeof CouponAlreadyAppliedError
>;
