import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { GiftCardAlreadyAppliedError } = errorFactory(
  'GiftCardAlreadyAppliedError'
);

/** When the gift card is already applied this error is thrown. */
export type GiftCardAlreadyAppliedError = InstanceTypeOf<
  typeof GiftCardAlreadyAppliedError
>;
