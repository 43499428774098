import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '../error-utils';

export const { RequestError } = errorFactory('RequestError');

/**
 * Generic Error to use when a request is not executed successfully. This error should
 * only be used for throwing when _making_ a request, and _not_ when _handling_ a request.
 */
export type RequestError = InstanceTypeOf<typeof RequestError>;
