import { DTO } from '@/type-utils';
import { ReviewsService } from '../../../isomorphic/ReviewsService';
import Model from '../../Model';

import {
  IProductQuestionAnswer,
  ProductQuestionAnswerModel
} from '../ProductQuestionAnswerModel';
import { IProductQuestion } from './IProductQuestion';

/**
 * Represents a single question.
 */
export default class ProductQuestionModel
  extends Model<DTO<IProductQuestion>>
  implements IProductQuestion
{
  /** @inheritdoc */
  public id: number;

  /** @inheritdoc */
  public content: string;

  /** @inheritdoc */
  public author: string;

  /** @inheritdoc */
  public createdAt: Date;

  /** @inheritdoc */
  public answers: Array<IProductQuestionAnswer>;

  /**
   * @inheritdoc
   *
   * @param question - `DTO` of an question.
   */
  public constructor(question: DTO<IProductQuestion>) {
    super(question);

    this.id = question.id;
    this.content = question.content;
    this.author = question.author;
    this.createdAt = new Date(question.createdAt);

    this.answers = question.answers.map((answer) => {
      return ProductQuestionAnswerModel.from(answer);
    });
  }

  /**
   * Create an answer as the store owner.
   * The answer might take some time to appear publicly.
   * This method just commits the answer to the system, therefore
   * the local copy of the `anwers` array is not updated either.
   *
   * @param answer - Answer string.
   * @param isPrivate - Is the answer private?
   * @returns The answer object.
   */
  public async createAnswer(
    answer: string,
    isPrivate: boolean = false
  ): Promise<void> {
    await ReviewsService.createAnswer(this.id, answer, isPrivate);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IProductQuestion> {
    return {
      id: this.id,
      content: this.content,
      author: this.author,
      createdAt: this.createdAt.toISOString(),

      answers: this.answers.map((answer) => {
        return ProductQuestionAnswerModel.from(answer).toDTO();
      })
    } as unknown as DTO<IProductQuestion>;
  }
}
