/**
 * Maps our PageType values to the DYPageType values.
 */
export enum DYPageType {
  HomePage = 'HOMEPAGE',
  Category = 'CATEGORY',
  Product = 'PRODUCT',
  Cart = 'CART',
  Other = 'OTHER'
}
