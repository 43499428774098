import { StructuredDataInput } from '@/services/isomorphic/integrations/StructuredDataService';
import { DTO, Nullable } from '@/type-utils';
import ConfigurationService from '../../isomorphic/ConfigurationService';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';
import { Robots } from './Robots';
import Model from '../Model';
import IPage, { PageType } from './IPage';
import type { IProductListMetaData } from './IProductListMetaData';
/**
 * @inheritDoc
 */
export default class PageModel extends Model<DTO<IPage>> {
  /** @inheritDoc */
  public readonly url: string;

  /** @inheritDoc */
  public readonly pageType: PageType;

  /** @inheritDoc */
  public readonly title: string;

  /** @inheritDoc */
  public readonly description: string;

  /** @inheritDoc */
  public readonly productListMetadata: Nullable<IProductListMetaData>;

  /** @inheritDoc */
  public readonly contentSlug: Nullable<string>;

  /**
   *  The canonical URL for the page, which represents the single authoritative location for the page's content.
   *  - A value of `null` or `undefined` indicates that there is no canonical URL for the page.
   */
  public readonly canonicalURL: Nullable<URL>;

  /** @inheritDoc */
  public readonly pageMetaData: Nullable<{
    robots: Array<Robots>;
    ldjson?: string;
  }>;

  /**
   * Builds a Page Model from a page representation.
   * @param page - A page Representation.
   */
  public constructor(page: DTO<IPage>) {
    super(page);
    this.url = page.url;
    this.pageType = page.pageType;

    // Page Metadata
    const metadataConfig = ConfigurationService.getConfig('metadata');
    this.title = page.title ?? metadataConfig.getSetting('home.title').value;
    this.description =
      page.description ?? metadataConfig.getSetting('home.description').value;
    if (page.productListMetadata)
      this.productListMetadata = page.productListMetadata;
    if (page.contentSlug) this.contentSlug = page.contentSlug;

    /** If the canonicalURL is not an empty string, null, or undefined. */
    if (page.canonicalURL) {
      this.canonicalURL = new URL(page.canonicalURL);
    } else if (page.canonicalURL === undefined) {
      /**
       * Per the {@link IPage.canonicalURL} documentation, handle the
       * case that the page should use some sensible default.
       */
      this.canonicalURL = this.getDefaultCanonicalURL();
    } else {
      /** The canonicalURL is an empty string or null. */
      this.canonicalURL = null;
    }

    this.pageMetaData = {
      ...page.pageMetaData
    } as Nullable<{
      robots: Array<Robots>;
      ldjson?: string;
    }>;
  }

  /**
   * Creates a DTO representation of this Model.
   * @returns A DTO representation of this Page Model.
   */
  public toDTO(): DTO<IPage> {
    return {
      pageType: this.pageType,
      title: this.title,
      description: this.description,
      productListMetadata: this.productListMetadata,
      canonicalURL: this.canonicalURL?.href
    } as DTO<IPage>;
  }

  /**
   * Returns a sensible, default value for this page's canonical URL.
   * @returns The default canonical URL.
   */
  private getDefaultCanonicalURL(): URL {
    const { host, pathname } = EnvironmentService.url;
    return new URL(`https://${host}${pathname}`);
  }
}
