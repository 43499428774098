/**
 * @file This file exports a {@link PromiseLike} object that resolves to mock product data.
 */

import type { DTO } from '@/type-utils';
import type { IProduct } from '../../../models/Product';
import { EnvironmentService } from '../../EnvironmentService';

/**
 * A {@link PromiseLike} object that resolves to mock product data.
 *
 * This object avoids including the mock data in the main app chunk,
 * and instead imports it lazily and asynchronously.
 *
 * @example
 * const mockData = await asyncMockProductsImport;
 */
export const asyncMockProductsImport = {
  /**
   * Calling `then` or `await` on this object will return the mock product data.
   *
   * We avoid using a native promise because they evaluate eagerly, but we don't want to
   * load the mock data until it's actually needed.
   *
   * @param resolve - The callback to resolve the promise.
   */
  then: async (resolve) => {
    // remove mock from production bundle
    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      resolve?.({});
      return;
    }

    // We need to import this dynamically to avoid including it in the initial bundle.
    // For some reason, the `isProd` static optimization doesn't automatically remove it
    // from the production server bundle.
    const { MOCK_PRODUCTS } = await import(
      /* webpackChunkName: "product-mock" */
      /* webpackExports: "MOCK_PRODUCTS" */
      './MOCK_PRODUCT'
    );
    resolve?.(MOCK_PRODUCTS);
  }
} as PromiseLike<Record<string, DTO<IProduct>>>;
