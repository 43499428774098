import type { IHandler } from '@/services/utils/chain-of-responsibility';
import type { IIntermediateCalculationState, ITotals } from '..';

/**
 * A handler for returning the result of the cart calculation.
 * This should always be the final handler in the chain.
 */
export default class CalculateCartReturnHandler
  implements IHandler<IIntermediateCalculationState, Promise<ITotals>>
{
  /**
   * Returns the result of the cart calculation and updates `totals`.
   * @param requestData - The request data to pass to process.
   * @param next - A function to pass the "request" to the next handler in some chain.
   * @returns A promise resolving to the result of processing the request.
   */
  public async handle(
    { totals }: IIntermediateCalculationState,
    next: (requestData: IIntermediateCalculationState) => Promise<ITotals>
  ): Promise<ITotals> {
    // Note: Since all handlers in this chain sum properly rounded amounts,
    // the final total should also be properly rounded. Hence, we can just
    // return the totals as-is.
    return totals;
  }
}
