import { DTO } from '@/type-utils';

import Model from '../../Model';

import { MoneyModel } from '../../Money';
import ILineTax from './ILineTax';

/**
 * The taxation data model holds taxation data for the order object.
 */
export default class LineTaxModel
  extends Model<DTO<ILineTax>>
  implements ILineTax
{
  /**
   * @inheritdoc
   */
  public readonly uuid: string;
  /**
   * @inheritdoc
   */
  public readonly tax: MoneyModel;
  /**
   * @inheritdoc
   */
  public readonly rate: number;

  /**
   * The taxation data model holds taxation data for the order
   * object.
   * @param dto - The taxation object.
   */
  public constructor(dto: DTO<ILineTax>) {
    super(dto);

    this.uuid = dto.uuid;
    this.tax = MoneyModel.from(dto.tax);
    this.rate = dto.rate;
  }

  /** @inheritdoc */
  public toDTO(): DTO<ILineTax> {
    return {
      uuid: this.uuid,
      tax: this.tax.toDTO(),
      rate: this.rate
    };
  }
}
