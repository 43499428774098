import { action, computed, makeObservable, observable } from 'mobx';

import { DTO, Nullable } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import ConfigurationService from '../../isomorphic/ConfigurationService';
import Model from '../Model';
import { IMoney, MoneyModel } from '../Money';
import { IGiftCard } from './IGiftCard';

/** Provides a way to manipulate Money. */
export default class GiftCardModel
  extends Model<DTO<IGiftCard>>
  implements IGiftCard
{
  private _giftCardNumber: string;
  private _balance: MoneyModel;
  private _cvd: string;
  @observable private _amountApplied: MoneyModel;

  /** @inheritdoc */
  public readonly id: string;

  /**
   * Builds a Gift Card model from a certificate representation.
   * @param certificate - A gift card DTO.
   */
  public constructor(certificate: DTO<IGiftCard>) {
    super(certificate);

    this.id = certificate.id;
    this._giftCardNumber = certificate.giftCardNumber ?? '123';
    this._balance = certificate.balance
      ? MoneyModel.from(certificate.balance)
      : MoneyModel.fromAmount(0);
    this._cvd = certificate.cvd ?? '123';
    this._amountApplied = certificate.amountApplied
      ? MoneyModel.from(certificate.amountApplied)
      : MoneyModel.fromAmount(0);

    makeObservable(this);
  }

  /** @inheritdoc */
  public get giftCardNumber(): string {
    return this._giftCardNumber;
  }

  /** @inheritdoc */
  public get balance(): MoneyModel {
    return this._balance;
  }

  /** @inheritdoc */
  public get cvd(): string {
    return this._cvd;
  }

  /** @inheritdoc */
  @computed public get amountApplied(): MoneyModel {
    return this._amountApplied;
  }

  /**
   * Returns the masked card number for display after applying the card.
   * @returns Either a string with masked characters or null.
   */
  public get maskedGiftCardNumber(): Nullable<string> {
    let maskedCardNumber: Nullable<string> = null;

    if (this._giftCardNumber) {
      const numberMasked = ConfigurationService.getConfig(
        'giftCard'
      ).getSetting('numberOfMaskedCharacters').value;

      maskedCardNumber =
        '*'.repeat(numberMasked) + this._giftCardNumber.substring(numberMasked);
    }

    return maskedCardNumber;
  }

  /**
   * Resets the applied amount so that it can be recalculated by the
   * cart model.
   */
  public resetAppliedAmount(): void {
    this._amountApplied = MoneyModel.fromAmount(0);
  }

  /**
   * Apply an amount of the card, cannot exceed the balance
   * of the card.
   * @param amount - The amount being applied from the
   * card balance.
   * @throws If the amount exceeds the balance.
   */
  @action public applyAmount(amount: number | IMoney): void {
    this._amountApplied.addAmount(amount);

    if (this._amountApplied.isGreaterThan(this._balance)) {
      throw new InvalidArgumentError(
        `Cannot apply more than the available amount.`
      );
    }
  }

  /** @inheritDoc */
  public toDTO(): DTO<IGiftCard> {
    return {
      giftCardNumber: this._giftCardNumber,
      cvd: this._cvd,
      balance: this._balance.toDTO(),
      amountApplied: this._amountApplied.toDTO(),
      id: this.id
    } as DTO<IGiftCard>;
  }
}
