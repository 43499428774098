import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { PersonalizationError } from './PersonalizationError';

export const { PersonalizationConfigurationError } = errorFactory(
  'PersonalizationConfigurationError',
  PersonalizationError
);

/** An error that occurs when a personalization configuration is invalid. */
export type PersonalizationConfigurationError = InstanceTypeOf<
  typeof PersonalizationConfigurationError
>;
