import { DTO } from '@/type-utils';

import Model from '../../Model';

import { MoneyModel } from '../../Money';
import ICartTax from './ICartTax';
import LineTaxModel from './LineTaxModel';

/**
 * The taxation data model holds taxation data for the order object.
 */
export default class CartTaxModel
  extends Model<DTO<ICartTax>>
  implements ICartTax
{
  /**
   * @inheritdoc
   */
  public readonly uuid: string;
  /**
   * @inheritdoc
   */
  public readonly total: MoneyModel;
  /**
   * @inheritdoc
   */
  public readonly lines: Array<LineTaxModel>;

  /**
   * The taxation data model holds taxation data for the order
   * object.
   * @param dto - The taxation object.
   */
  public constructor(dto: DTO<ICartTax>) {
    super(dto);

    this.uuid = dto.uuid;
    this.total = MoneyModel.from(dto.total);
    this.lines = dto.lines.map((line) => LineTaxModel.from(line));
  }

  /** @inheritdoc */
  public toDTO(): DTO<ICartTax> {
    return {
      uuid: this.uuid,
      total: this.total.toDTO(),
      lines: this.lines.map((line) => line.toDTO())
    };
  }
}
