import { Constructor } from '@/type-utils';

import { mocked } from '@/configs';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';

import MockService, { MockState, ServiceMock } from '../MockService';

import { msg } from '../I18NService';

import {
  IInventory,
  InventoryModel,
  ProductAvailabilityState
} from '../../models/Inventory';

import type { InventoryService } from './InventoryService';
import { product_details_inStock } from "@/lang/__generated__/ahnu/product_details_inStock";
import { product_details_backorder } from "@/lang/__generated__/ahnu/product_details_backorder";
import { product_details_preorder } from "@/lang/__generated__/ahnu/product_details_preorder";
import { product_details_outOfStock } from "@/lang/__generated__/ahnu/product_details_outOfStock";
import { product_details_unavailable } from "@/lang/__generated__/ahnu/product_details_unavailable";

const initialState = {};

const mockInventory: IInventory = {
  upc: '197634237862',
  distributionCenterCodes: ['US1'],
  inventoryStatus: {
    US: {
      allocation: 236,
      allocationTimestamp: '2023-05-16T10:04:05.302Z',
      upcomingAllocation: 0,
      inStockDateTime: '2020-09-03',
      perpetual: false
    }
  }
};

/**
 * Mock implementation of the InventoryService class.
 */
class InventoryServiceMock extends ServiceMock<InventoryService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): InventoryService {
    return MockService.getMockOf(this.service) as unknown as InventoryService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<InventoryService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<InventoryService>
  ): void {
    const mockEnabled: boolean = mocked.InventoryService;
    MockService.mockService(
      mockEnabled,
      service,
      {
        getProductInventory: async (upc: string): Promise<InventoryModel> => {
          return InventoryModel.from(mockInventory);
        },

        getAvailabilityIntlMessage: (
          availabilityState: ProductAvailabilityState
        ): string => {
          switch (availabilityState) {
            case ProductAvailabilityState.InStock:
              return msg(product_details_inStock);

            case ProductAvailabilityState.Backorder:
              return msg(product_details_backorder);

            case ProductAvailabilityState.Preorder:
              return msg(product_details_preorder);

            case ProductAvailabilityState.OutOfStock:
              return msg(product_details_outOfStock);

            case ProductAvailabilityState.Unavailable:
              return msg(product_details_unavailable);

            default:
              throw new InvalidArgumentError(
                `Cannot get Availability Text for invalid state "${availabilityState}".`
              );
          }
        },

        bulkGetProductInventory: async () => {
          return Promise.resolve({
            '197634237862': InventoryModel.from(mockInventory),
            '197634237770': InventoryModel.from(mockInventory)
          });
        },

        bulkGetStaleProductInventory: () => {
          return {
            '197634237862': InventoryModel.from(mockInventory),
            '197634237770': InventoryModel.from(mockInventory)
          };
        }
      },
      {},
      this.state
    );
  }
}

export default InventoryServiceMock;
