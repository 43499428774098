import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import type { ChainOfResponsibility } from './ChainOfResponsibility';

export const { EndOfChainError } = errorFactory('EndOfChainError');

/**
 * An error that occurs when {@link ChainOfResponsibility.handle} reaches
 * the end of its handler chain without fully handling the request data.
 */
export type EndOfChainError = InstanceTypeOf<typeof EndOfChainError>;
