import crypto from 'node:crypto';

import Service from '../../../Service';

import { ProductLineItemModel } from '../../../models/Cart/LineItem';
import { ProductModel } from '../../../models/Product';
import type { InteractionDetails } from '../../UserInteractionService';

import GTMDataHandler from './GTMDataHandler';
import { IEventInteractionData } from './IEventInteractionData';

import GTMServiceMock from './GTMServiceMock';

/**
 * A service for handling specifically user interactions related to the GTM
 * dataLayer. Generic UserInteractions are delegated to the GTMService to handle dataLayer
 * events as a side effect.
 */
export class GTMService extends Service {
  /**
   * This method formats data based on 'action' type and builds the event object
   * to be used to execute the event. It knows what type of InteractionDetails it has
   * by their 'action' property.
   * @param interactionDetails - The interaction details types are
   * differentiated by their 'action' and devided into various buckets.
   * @returns The event data that will be used to execute the event, now
   * formatted.
   */
  private async formatInteraction(
    interactionDetails: InteractionDetails
  ): Promise<IEventInteractionData> {
    const { action } = interactionDetails;

    let gtmFormattedData = { event: GTMDataHandler.getGTMEventName(action) };
    let additionalData = {};

    switch (action) {
      case 'page_view': {
        additionalData =
          await GTMDataHandler.getGTMPageViewData(interactionDetails);
        break;
      }
      case 'product:add': {
        const productModel = ProductModel.from(interactionDetails.product);
        const analyticsProduct =
          GTMDataHandler.getGTMAddToCartEcommerceData(productModel);
        additionalData = { ...analyticsProduct };
        break;
      }
      case 'product:remove': {
        const lineItemModel = ProductLineItemModel.from(
          interactionDetails.lineItem
        );
        const analyticsLineItem =
          GTMDataHandler.getGTMRemoveFromCartEcommerceData(lineItemModel);
        additionalData = { ...analyticsLineItem };
        break;
      }
      case 'navigation:link': {
        const analyticsLink = {
          link_name: interactionDetails.link.linkName,
          link_category: interactionDetails.link.linkCategory,
          link_section: interactionDetails.link.linkSection
        };
        additionalData = { ...analyticsLink };
        break;
      }
      case 'form:input': {
        const analyticsForm = {
          form_name: interactionDetails.formName,
          form_field_name: interactionDetails.formFieldName
        };
        additionalData = { ...analyticsForm };
        break;
      }
      case 'signup:success': {
        const emailInfo = {
          customer_hmail: crypto
            .createHash('sha256')
            .update(interactionDetails.customer_email)
            .digest('hex')
        };
        additionalData = { ...emailInfo };
        break;
      }
      case 'product:updateVariation': {
        const analyticsSelectedProduct = {
          product_id: interactionDetails.product.sku,
          product_upc: interactionDetails.product.upc,
          color: interactionDetails.product.selectedAttributes.color,
          size: interactionDetails.product.selectedAttributes.size
        };
        additionalData = { ...analyticsSelectedProduct };
        break;
      }
      default:
        // Don't add additional data if action does not need it.
        break;
    }

    gtmFormattedData = { ...gtmFormattedData, ...additionalData };

    return gtmFormattedData;
  }

  /**
   * Makes the GTM action from the UserInteractionService. The ultimate shape of the
   * parameters is an array that contains a string, a possible second string and then
   * a third item that is either an object or string.
   * @param interactionDetails - The interactionDetails that holds the data necessary to record
   * various events.
   */
  public async makeGTMAction(
    interactionDetails: InteractionDetails
  ): Promise<void> {
    const gtmInteraction = await this.formatInteraction(interactionDetails);

    if (!window?.dataLayer)
      throw new Error('GTM dataLayer changes should occur on the client.');

    if (gtmInteraction.ecommerce) {
      /**
       * This clears the ecommerce object if a new ecommerce object is being added.
       * @see https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#details
       */
      window?.dataLayer?.push({ ecommerce: null });
    }

    window?.dataLayer?.push(gtmInteraction);
  }
}

export default GTMService.withMock(
  new GTMServiceMock(GTMService)
) as unknown as GTMService;
