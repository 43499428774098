import Model from '../../Model';
import { DTO } from '@/type-utils';

import { IWallet } from './IWallet';
import { IPaymentMethod } from '../PaymentMethod';

/** Represents a Wallet: a collection of a customer's {@link IPaymentMethod payment methods}. */

/**
 *
 */
export default class WalletModel
  extends Model<DTO<IWallet>>
  implements IWallet
{
  private _accountID: string;
  private _paymentMethods: Array<IPaymentMethod>;

  /** @inheritdoc */
  public constructor(dto: DTO<IWallet>) {
    super(dto);

    this._accountID = dto.accountID;
    this._paymentMethods = [...dto.paymentMethods];
  }

  /** @inheritdoc */
  public get accountID(): string {
    return this._accountID;
  }

  /** @inheritdoc */
  public get paymentMethods(): ReadonlyArray<IPaymentMethod> {
    return this._paymentMethods;
  }

  /** @inheritDoc */
  public toDTO(): DTO<IWallet> {
    return {
      accountID: this.accountID,
      paymentMethods: this.paymentMethods
    };
  }
}
