import type { DTO } from '@/type-utils';
import { ServerCodeInClientError } from '@/utils/errors';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';

import Model from '../Model';
import type ISession from './ISession';
import SessionDataModel from './SessionDataModel';

/** Represents a session and allows interaction with the data stored in it. */
export default class SessionModel
  extends Model<DTO<ISession>>
  implements ISession
{
  private _secureData: SessionDataModel;
  private _data: SessionDataModel;
  private _id: string;

  /** @inheritdoc */
  public get data(): SessionDataModel {
    return this._data;
  }

  /** @inheritdoc */
  public get secureData(): SessionDataModel {
    if (!(typeof window === "undefined")) {
      throw new ServerCodeInClientError(
        'Secure data can only be accessed on the server.'
      );
    }

    return this._secureData;
  }

  /** @inheritdoc */
  public get id(): string {
    return this._id;
  }

  /** @inheritdoc */
  public constructor(session: DTO<ISession>) {
    super(session);

    this._id = session.id;

    this._data = session.data
      ? SessionDataModel.from(session.data)
      : new SessionDataModel({ isSecure: false, data: {} });

    this._secureData = session.secureData
      ? SessionDataModel.from(session.secureData)
      : new SessionDataModel({ isSecure: true, data: {} });
  }

  /** @inheritdoc */
  public override update(session: DTO<ISession>): void {
    this._data.update(session.data ?? { isSecure: false, data: {} });

    if ((typeof window === "undefined")) {
      this._secureData.update(
        session.secureData ?? { isSecure: true, data: {} }
      );
    }
  }

  /** @inheritdoc */
  public toDTO(): DTO<ISession> {
    return {
      id: this.id,
      data: this.data.toDTO(),

      // Secure data is not included because DTOs could end up on the client side.
      secureData: null
    };
  }
}
