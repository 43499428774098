/**
 * Represents the type of account event.
 */
export enum FraudAccountEventType {
  'Login' = 'Login',
  'Signup' = 'Signup',
  'Refresh' = 'Refresh',
  'Logout' = 'Logout',
  'LoginFailure' = 'LoginFailure',
  'Update' = 'Update',
  'PasswordReset' = 'PasswordReset',
  'PasswordResetRequest' = 'PasswordResetRequest',
  'EmailConfirmation' = 'EmailConfirmation'
}
