import axios, { AxiosInstance } from 'axios';

import type { DTO } from '@/type-utils';
import Service from '../../Service';
import { CartTaxModel } from '../../models/Cart/Tax';
import ServerTaxService from '../../serverless/ServerTaxService';

import type { ICart } from '../../models/Cart';
import { MoneyModel } from '../../models/Money';
import siteCached from '../../utils/siteCached';
import CSRFTokenService from '../CSRFTokenService';
import { EnvironmentService } from '../EnvironmentService';
import TaxServiceMock from './TaxServiceMock';

/** Handles operations related to tax calculation. */
export class TaxService extends Service {
  /**
   * An AxiosInstance for hitting our `tax/*` API endpoints.
   * @returns An AxiosInstance.
   */
  @siteCached
  private get client(): AxiosInstance {
    return axios.create({
      baseURL: '/api/tax'
    });
  }

  /**
   * Calculates the tax to be applied to a Cart.
   *
   * @param cart - The cart to calculate tax for.
   * @returns The calculated tax in {@link MoneyModel} form.
   */
  public async getCartTax(cart: DTO<ICart>): Promise<CartTaxModel> {
    if ((typeof window === "undefined")) {
      const cartTaxDTO = await ServerTaxService.getCartTax(cart);
      return CartTaxModel.from(cartTaxDTO);
    }

    const csrfHeaders = CSRFTokenService.getHeaders();

    const { data } = await this.client.post<DTO<CartTaxModel>>('', cart, {
      headers: { ...csrfHeaders }
    });

    return CartTaxModel.from(data);
  }
}

export default TaxService.withMock(
  new TaxServiceMock(TaxService)
) as unknown as TaxService;
