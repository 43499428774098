import { Country } from '@/constructs/Country';
import { Site } from '@/constructs/Site';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import I18NService from '@/services/isomorphic/I18NService';
import { IHandler } from './IHandler';

/**
 * Represents an {@link IHandler} that runs only when the current {@link Site} and {@link Country}
 * match those it was registered with.
 */
export default class SiteLocaleHandler<T, ReturnValue = void>
  implements IHandler<T, ReturnValue>
{
  /**
   * Creates a new {@link SiteLocaleHandler}.
   * @param site - The {@link Site} to scope this given handler to.
   * @param country - The {@link Country} to scope this handler to.
   * @param handler - The underlying {@link IHandler} to invoke.
   */
  public constructor(
    private site: Site,
    private country: Country | 'default',
    private handler: IHandler<T, ReturnValue>
  ) {}

  /**
   * Processes the given `requestData` only if the {@link Brand} and {@link Country} match.
   * @param requestData - The request data to pass to the underlying handler.
   * @param next - A function to pass the "request" to the next handler in some chain.
   * @returns The value returned by processing the data.
   */
  public handle(
    requestData: T,
    next: (requestData: T) => ReturnValue
  ): ReturnValue {
    const { country } = I18NService.currentLocale;
      const site = (process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase());
    if ([country, 'default'].includes(this.country) && this.site === site) {
      return this.handler.handle(requestData, next);
    }
    return next(requestData);
  }
}
