import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';

export const { CustomerSessionClosureFailedError } = errorFactory(
  'CustomerSessionClosureFailedError',
  {
    mapsToHTTPError: 'ConflictError'
  } as IHTTPErrorMetaData
);

/** Error to use when a call to close a Talon.one customer session fails. */
export type CustomerSessionClosureFailedError = InstanceTypeOf<
  typeof CustomerSessionClosureFailedError
>;
