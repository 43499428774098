/**
 * @see https://docs.talon.one/docs/dev/integration-api/api-effects#interpreting-the-different-effect-types
 */
enum EffectType {
  // Coupon effects
  RejectCoupon = 'rejectCoupon',
  AcceptCoupon = 'acceptCoupon',
  CouponCreated = 'couponCreated',
  RollbackCoupon = 'rollbackCoupon',
  ReserveCoupon = 'reserveCoupon',

  // Discount effects
  SetDiscount = 'setDiscount',
  SetDiscountPerItem = 'setDiscountPerItem',
  AddFreeItem = 'addFreeItem',
  SetDiscountPerAdditionalCost = 'setDiscountPerAdditionalCost',
  SetDiscountPerAdditionalCostPerItem = 'setDiscountPerAdditionalCostPerItem',
  RollbackDiscount = 'rollbackDiscount',

  // Giveaway effects
  AwardGiveaway = 'AwardGiveaway',
  WillAwardGiveaway = 'WillAwardGiveaway',

  // Loyalty effects
  AddLoyaltyPoints = 'addLoyaltyPoints',
  DeductLoyaltyPoints = 'deductLoyaltyPoints',
  RollbackAddedLoyaltyPoints = 'rollbackAddedLoyaltyPoints',
  RollbackDeductedLoyaltyPoints = 'rollbackDeductedLoyaltyPoints',

  // Referral effects
  ReferralCreated = 'referralCreated',
  RejectReferral = 'rejectReferral',
  AcceptReferral = 'acceptReferral',
  RedeemReferral = 'redeemReferral',
  RollbackReferral = 'rollbackReferral',

  // Other effects
  UpdateAttribute = 'updateAttribute',
  TriggerWebhook = 'triggerWebhook',
  ShowNotification = 'showNotification'
}

export default EffectType;
