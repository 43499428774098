import Service from '../../../Service';
import { mocked } from '@/configs';
import { Constructor } from '@/type-utils';
import MockService, { MockState, ServiceMock } from '../../MockService';
import type { CoveoAnalyticsServiceType } from './CoveoAnalyticsService';

const initialState = {};

/**
 * Mock implementation of the GTMService class. This shouldn't do anything when mocked.
 */
export default class CoveoAnalyticsServiceMock extends ServiceMock<CoveoAnalyticsServiceType> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): CoveoAnalyticsServiceType {
    return MockService.getMockOf(
      this.service
    ) as unknown as CoveoAnalyticsServiceType;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<CoveoAnalyticsServiceType>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.CoveoAnalyticsService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        // When mocked this shouldn't do anything at all.
        clickEvent: () => {},
        searchEvent: () => {}
      },
      {},
      this.state
    );
  }
}
