import { mocked } from '@/configs';
import type { Constructor } from '@/type-utils';
import MockService, { MockState, ServiceMock } from '../MockService';
import type { PersonalizationService } from './PersonalizationService';
import {
  experienceToDecisionsMap,
  type DecisionIDOf,
  type DecisionsFor,
  type ExperienceID,
  type IDecision
} from './campaigns';

const initialState = {};

/** @inheritdoc */
const getRandomDecision = async <T extends ExperienceID>(
  experienceID: T
): Promise<IDecision<T, DecisionIDOf<T>>> => {
  const validDecisions = experienceToDecisionsMap[experienceID];
  if (!validDecisions) throw new Error('Invalid experience ID');

  // choose random decision
  const decisionID = validDecisions[
    Math.floor(Math.random() * validDecisions.length)
  ] as DecisionIDOf<T>;

  return {
    decisionID,
    experienceID
  } as IDecision<T, DecisionIDOf<T>>;
};

/**
 * Mock implementation of the PersonalizationService class.
 */
class PersonalizationServiceMock extends ServiceMock<PersonalizationService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): PersonalizationService {
    return MockService.getMockOf(
      this.service
    ) as unknown as PersonalizationService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<PersonalizationService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<PersonalizationService>
  ): void {
    const mockEnabled: boolean = mocked.PersonalizationService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getExperienceForCurrentUser: async <T extends ExperienceID>(
          experienceID: T
        ) => {
          return getRandomDecision(experienceID);
        },
        getExperiencesForCurrentUser: async (experienceIDs) => {
          const decisionPromises = experienceIDs.map(async (experienceID) => {
            const result = await getRandomDecision(experienceID).then(
              (decision) => [decision, null] as const,
              (error) => [null, error] as const
            );
            return [experienceID, result] as const;
          });

          const decisions = await Promise.all(decisionPromises);
          return Object.fromEntries(decisions) as DecisionsFor<
            typeof experienceIDs
          >;
        }
      },
      {},
      this.state
    );
  }
}

export default PersonalizationServiceMock;
