import { DTO } from '@/type-utils';
import { ReviewsService } from '../../../isomorphic/ReviewsService';
import Model from '../../Model';

import { IProductQuestions } from './IProductQuestions';
import { ProductQuestionModel } from '../ProductQuestionModel';

/**
 * Paginable `ProductQuestionsModel`.
 *
 * Allows listing and paginating questions for a product SKU.
 */
export default class ProductQuestionsModel
  extends Model<DTO<IProductQuestions>>
  implements IProductQuestions
{
  /** @inheritdoc */
  public readonly productSKU: string;

  /** @inheritdoc */
  public readonly pages: Array<Array<ProductQuestionModel>>;

  /** @inheritdoc */
  public readonly pageSize: number;

  /** @inheritdoc */
  public readonly allItems: Array<ProductQuestionModel>;

  /** @inheritdoc */
  public readonly totalPages: number;

  /** @inheritdoc */
  public readonly totalItems: number;

  /** @inheritdoc */
  public readonly currentPage: number;

  /** @inheritdoc */
  public readonly isLoadingNewPage: boolean;

  /**
   * @inheritdoc
   *
   * @param reviews - A `DTO` of {@link IProductQuestions}.
   */
  public constructor(questions: DTO<IProductQuestions>) {
    super(questions);

    this.productSKU = questions.productSKU;

    // Copy 2d array and hidrate DTO-s
    this.pages = questions.pages.map((page) => {
      return page.map((question) => {
        return ProductQuestionModel.from(question);
      });
    });

    // Flatten all pages into a 1d array
    this.allItems = this.pages.flat();
    this.pageSize = questions.pageSize;
    this.totalPages = questions.totalPages;
    this.totalItems = questions.totalItems;
    this.currentPage = questions.currentPage;
    this.isLoadingNewPage = questions.isLoadingNewPage;
  }

  /** @inheritdoc */
  public get hasNext(): boolean {
    return this.currentPage < this.totalPages - 1;
  }

  /** @inheritdoc */
  public get hasPrevious(): boolean {
    // There's no previous page if there are no pages :)
    return this.currentPage > 0 && this.totalPages > 1;
  }

  /**
   * Create a `ProductQuestionsModel` for a specific product.
   *
   * @param sku - Product SKU.
   * @returns A `ProductQuestionsModel` which allows interacting with questions for this product.
   */
  public static async forProduct(sku: string): Promise<ProductQuestionsModel> {
    return ReviewsService.getQNAForProduct(sku);
  }

  /** @inheritdoc */
  public async loadPage(
    pageNumber: number
  ): Promise<Array<ProductQuestionModel>> {
    // This model is not actually paginating using requests.
    // All questions are pre-loaded.
    return this.pages[pageNumber];
  }

  /** @inheritdoc */
  public async nextPage(): Promise<Array<ProductQuestionModel>> {
    if (!this.hasNext) {
      return [];
    }

    return this.loadPage(this.currentPage + 1);
  }

  /** @inheritdoc */
  public async previousPage(): Promise<Array<ProductQuestionModel>> {
    if (!this.hasPrevious) {
      return [];
    }

    return this.loadPage(this.currentPage - 1);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IProductQuestions> {
    return {
      productSKU: this.productSKU,
      // Turn pages back into DTO-s
      pages: this.pages.map((page) => {
        return page.map((item) => item.toDTO());
      }),
      // allItems[] can be reconstructed by flattening pages[][]
      // therefore it's ommited as an optimization
      allItems: [],
      pageSize: this.pageSize,
      totalPages: this.totalPages,
      totalItems: this.totalItems,
      currentPage: this.currentPage,
      hasNext: this.hasNext,
      hasPrevious: this.hasPrevious,
      isLoadingNewPage: this.isLoadingNewPage
    } as unknown as DTO<IProductQuestions>;
  }
}
