import { kebabCase } from '@/utils/string-utils';
import { computed, makeObservable, observable } from 'mobx';

import { DTO, Nullable } from '@/type-utils';
import { removeUndefined } from '@/utils/object-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';

import LoggerService from '@/services/isomorphic/LoggerService';
import { isPromiseLike } from '@/utils/async-utils';
import { InvalidStateError, ResourceNotFoundError } from '@/utils/errors';
import { invokeImmediately } from '@/utils/function-utils';
import LazyValue from '@/utils/LazyValue';

import { isNullish, isNullOrEmpty } from '@/utils/null-utils';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';
import InventoryService from '../../isomorphic/InventoryService';
import ProductService from '../../isomorphic/ProductService';
import { ReviewsService } from '../../isomorphic/ReviewsService';

import {
  IInventory,
  InventoryModel,
  ProductAvailabilityState
} from '../Inventory';
import type { IMedia } from '../Media';
import type { IImage } from '../Media/Image';
import Model from '../Model';
import { PriceModel } from '../Price';
import type { IRatings, IReviews } from '../ReviewsModel';
import type { ISizeChart } from '../SizeChart';

import type { IBreadcrumb } from '../Breadcrumb';

import type IProduct from './IProduct';
import type { IProductDetails } from './IProductDetails';
import ProductType from './ProductType';

import { IPlatform, PlatformModel } from '../Platform';
import TaxClass from '../TaxClass';
import { GroupName } from './GroupName';
import { IProductVariationMap } from './IProductVariationMap';
import {
  IVariationAttribute,
  VariationAttributeID,
  VariationAttributeType,
  VariationAttributeTypeFromType
} from './variation-attributes';

/** ProductModel represents a Product within the app. */
class ProductModel<T extends ProductType = ProductType>
  extends Model<DTO<IProduct<T>>>
  implements IProduct<T>
{
  private _images: ReadonlyArray<IImage>;
  private _media: ReadonlyArray<IMedia>;

  private _availableAttributeTypes: ReadonlyArray<VariationAttributeType>;
  private _variationAttributes: {
    [type in VariationAttributeID]: IVariationAttribute;
  };

  private _selectableAttributes: {
    [type in VariationAttributeType]?: ReadonlyArray<VariationAttributeID>;
  };

  private _colorCodes: ReadonlyArray<string>;
  private readonly _defaultColor: string;
  private _colorVariants: ReadonlyArray<string>;
  private _selectedAttributes: {
    [key in VariationAttributeType]?: VariationAttributeID;
  };

  private _unselectedAttributeTypes: ReadonlyArray<VariationAttributeType>;

  private _variationMap: IProductVariationMap;

  @observable private _inventory: StaleWhileRevalidate<
    Nullable<InventoryModel>
  >;

  private _ratings: LazyValue<StaleWhileRevalidate<Nullable<IRatings>>>;

  private _cachedPlatforms: LazyValue<Promise<Array<PlatformModel>>>;

  /** @inheritdoc */
  public readonly sku: string;

  /** @inheritdoc */
  public readonly upc?: Nullable<string>;

  /** @inheritdoc */
  public readonly styleNumber: string;

  /** @inheritdoc */
  public readonly name: string;

  /** @inheritdoc */
  public readonly isBaseProduct: boolean;

  /** @inheritdoc */
  public readonly isCompleteVariant: boolean;

  /** @inheritdoc */
  public readonly price: Nullable<PriceModel>;

  /** @inheritdoc */
  public readonly type: T;

  /** @inheritdoc */
  public readonly reviews: Nullable<IReviews>;

  /** @inheritdoc */
  public readonly isVariation: boolean;

  /** @inheritdoc */
  public readonly details: IProductDetails;

  /** @inheritdoc */
  public readonly sizeChart: Nullable<ISizeChart>;

  /** @inheritdoc */
  public readonly contentTag: Nullable<string>;

  /** @inheritdoc */
  public readonly gender: Nullable<string>;

  /** @inheritdoc */
  public readonly group: Nullable<string>;

  /** Media Properties. */

  /** @inheritdoc */
  public readonly primaryImage: IImage;

  /** @inheritdoc */
  public readonly secondaryImage: IImage;

  /** @inheritdoc */
  public readonly swatchImage: Nullable<IImage>;

  /** @inheritdoc */
  public readonly categories: ReadonlyArray<string>;

  /** @inheritdoc */
  public readonly platforms: ReadonlyArray<string>;

  /** @inheritdoc */
  public readonly taxClass: TaxClass;

  /** @inheritdoc */
  public readonly isOnline: boolean;

  /** @inheritdoc */
  public readonly transientErrors: Nullable<Array<string>>;

  /**
   * Gets the group and size for a complete product.
   * @returns The group and and size.
   * @throws If the product is not complete.
   */
  public get groupSize(): { group: Nullable<GroupName>; size: string } {
    if (!this.isCompleteVariant) {
      throw new InvalidStateError(
        'Cannot get the group size of the product if it is not complete.'
      );
    }

    // NOTE: Group and "gender" are being used interchangeably here. Consider
    // using `this.gender` instead for size display purposes.
    let group: GroupName | null = null;

    if (
      !isNullOrEmpty(this.group) &&
      Object.values(GroupName).includes(this.group as GroupName)
    ) {
      group = this.group as GroupName;
    }

    if (group == null) {
      throw new InvalidStateError(
        "Cannot get the group size of the product: The product doesn't have a" +
          ' valid group attribute'
      );
    }

    const sizeAttribute = this.getSelectedAttributeByType(
      VariationAttributeType.Size
    );

    /**
     * Split size name to return a full string including gender and size.
     * The `sizeName` is a string that contains an abbreviation
     * of the size of the product, for example: `W-12`, `M-7`.
     * This is a fallback if the product object has no group.
     */
    const size = sizeAttribute?.name;

    if (isNullOrEmpty(size)) {
      throw new InvalidStateError(
        "Cannot get the group size of the product: The product doesn't have a" +
          ' size attribute'
      );
    }

    return { group, size };
  }

  /**
   * The URL of this product.
   *
   * @example "https://sanuk.com/p/1113694-BLK"
   *
   * @returns - URL of the product without the size.
   */
  public get url(): string {
    return `/p/${kebabCase(this.name)}/${this.styleNumber}${
      this.attributeParams ? `?${this.attributeParams}` : ''
    }`;
  }

  /**
   * Path to the product as a string array.
   * Useful to avoid manipulating url to access parts of the path.
   * @example "https://sanuk.com/p/soft-top-comfort/11112312" -> ['p','soft-top-comfort','11112312']
   *
   * @returns - Array of strings representing the path to the product page.
   */
  public get paths(): Array<string> {
    return this.url.split('/').filter((path) => path);
  }

  /**
   * The fully-qualified URL of this product - matches the static path.
   *
   * @example "https://sanuk.com/p/1113694-BLK-08"
   *
   * @returns URL of the product with the size.
   */
  public get fullUrl(): string {
    const { origin } = EnvironmentService.url;

    return `${origin}/p/${kebabCase(this.name)}/${this.sku}`;
  }

  /**
   * Determines the product's current {@link ProductAvailabilityState availability state}
   * from its inventory.
   *
   * @returns A {@link ProductAvailabilityState} value.
   */
  @computed public get availabilityState(): ProductAvailabilityState {
    if (!this.isCompleteVariant) {
      return ProductAvailabilityState.Unknown;
    }

    if (this.inventory.pending) {
      return ProductAvailabilityState.Pending;
    }

    const inventoryModel = this.inventory.value;

    if (!inventoryModel) {
      return ProductAvailabilityState.Unavailable;
    }

    return inventoryModel.availabilityState;
  }

  /**
   * Determines the product's current {@link ProductAvailabilityState availability state}
   * from its inventory, and returns the Intl ID of the
   * string that describes it.
   *
   * @returns An Intl ID, or `null` if the inventory data is still being retrieved.
   */
  @computed public get availabilityStateText(): Nullable<string> {
    const state = this.availabilityState;

    if (state === ProductAvailabilityState.Pending) return null;
    if (state === ProductAvailabilityState.Unknown) return null;

    return InventoryService.getAvailabilityIntlMessage(state);
  }

  /**
   * The primary category of the product. Should be a string value that
   * represents only the category.
   *
   * @returns The final string from the primaryCategoryChain.
   */
  public get primaryCategory(): Nullable<string> {
    if (isNullOrEmpty(this.primaryCategoryChain)) return null;

    const categoryArray = this.primaryCategoryChain.split('>');
    return categoryArray[categoryArray.length - 1].trim();
  }

  /**
   * The primary category of the product. Should be a string that holds the category
   * and all parents. This comes from the Salsify data.
   * @returns The first category in the categories array or null.
   * @example `womens > womens footwear > new arrivals`
   */
  public get primaryCategoryChain(): Nullable<string> {
    if (this.categories[0]) {
      return this.categories[0];
    }

    return null;
  }

  /**
   * Gets the first upc on this style number.
   *
   * Represents the UPC to be used in the event that the current product
   * is not a full variation with its own UPC. This can be useful for third-parties
   * that always require a UPC and use it for the purposes of determining details
   * such as the product's target gender and so forth. This should not be used in
   * place of the actual UPC when it is available.
   * @returns The first UPC in the variation list.
   */
  public get fallbackUPC(): string {
    // This data will eventually come from the PIM and the hard coded value
    // coming from the model parser is representative of that, but we need a upc
    // that represents the style number before that will be available.
    return this.variationUPCs[0];
  }

  /**
   * An array of breadcrumbs objects that can supply the breadcrumb component.
   *
   * @returns A breadcrumbs array for use on the PDP.
   */
  public get breadcrumbs(): ReadonlyArray<IBreadcrumb> {
    const breadcrumbs = [];
    if (!isNullOrEmpty(this.primaryCategoryChain)) {
      let linkString = '';
      // Split the arrow separated category eg: Womens > Slippers
      this.primaryCategoryChain.split('>').forEach((category) => {
        // Push a new breadcrumb for each category.
        breadcrumbs.push({
          displayValue: category.replace(/(-)/g, ' '),
          link: `/c/${linkString}${category}`
        });
        // Add to the link string so that the link links to that breadcrumb item.
        linkString += category + '/';
      });
    }

    breadcrumbs.push({
      displayValue: this.name,
      link: this.url
    });

    return breadcrumbs;
  }

  /** @inheritdoc */
  @computed public get inventory(): StaleWhileRevalidate<
    Nullable<InventoryModel>
  > {
    return this._inventory;
  }

  /** @inheritdoc */
  public get ratings(): StaleWhileRevalidate<Nullable<IRatings>> {
    return this._ratings.value;
  }

  /** @inheritdoc */
  public get images(): ReadonlyArray<IImage> {
    return this._images;
  }

  /** @inheritdoc */
  private set images(value: ReadonlyArray<IImage>) {
    this._images = value;
  }

  /** @inheritdoc */
  public get media(): ReadonlyArray<IMedia> {
    return this._media;
  }

  /** @inheritdoc */
  private set media(value: ReadonlyArray<IMedia>) {
    this._media = value;
  }

  /** Variation Properties. */

  /** @inheritdoc */
  public get availableAttributeTypes(): ReadonlyArray<VariationAttributeType> {
    return this._availableAttributeTypes;
  }

  /** @inheritdoc */
  private set availableAttributeTypes(
    value: ReadonlyArray<VariationAttributeType>
  ) {
    this._availableAttributeTypes = value;
  }

  /** @inheritdoc */
  public get variationAttributes(): Record<
    VariationAttributeID,
    IVariationAttribute
  > {
    return this._variationAttributes;
  }

  /** @inheritdoc */
  private set variationAttributes(
    value: Record<VariationAttributeID, IVariationAttribute>
  ) {
    this._variationAttributes = value;
  }

  /** @inheritdoc */
  public get colorCodes(): ReadonlyArray<string> {
    return this._colorCodes;
  }

  /** @inheritdoc */
  public get colorVariants(): ReadonlyArray<string> {
    return this._colorVariants;
  }

  /** @inheritdoc */
  private set colorVariants(value: ReadonlyArray<string>) {
    this._colorVariants = value;
  }

  /** @inheritdoc */
  public get selectableAttributes(): {
    [type in VariationAttributeType]?: ReadonlyArray<VariationAttributeID>;
  } {
    return this._selectableAttributes;
  }

  /** @inheritdoc */
  private set selectableAttributes(value: {
    [type in VariationAttributeType]?: ReadonlyArray<VariationAttributeID>;
  }) {
    this._selectableAttributes = value;
  }

  /** @inheritdoc */
  public get selectedAttributes(): {
    [key in VariationAttributeType]?: VariationAttributeID;
  } {
    return this._selectedAttributes;
  }

  /** @inheritdoc */
  private set selectedAttributes(value: {
    [key in VariationAttributeType]?: VariationAttributeID;
  }) {
    this._selectedAttributes = value;
  }

  /** @inheritdoc */
  public get unselectedAttributeTypes(): ReadonlyArray<VariationAttributeType> {
    return this._unselectedAttributeTypes;
  }

  /** @inheritdoc */
  private set unselectedAttributeTypes(
    value: ReadonlyArray<VariationAttributeType>
  ) {
    this._unselectedAttributeTypes = value;
  }

  /** @inheritdoc */
  public get variationMap(): IProductVariationMap {
    return this._variationMap;
  }

  /** @inheritdoc */
  private set variationMap(value: IProductVariationMap) {
    this._variationMap = value;
  }

  /** @inheritdoc */
  public get description(): Array<string> {
    return (
      this.details.descriptionLong ??
      (!isNullOrEmpty(this.details.descriptionShort)
        ? [this.details.descriptionShort]
        : invokeImmediately(() => {
            LoggerService.warn(`Product ${this.sku} has no description.`);
            return [];
          }))
    );
  }

  /**
   * Gets the default color code out of the color variations.
   * @returns A color code designated in the pim or falls back to the first
   * variant listed.
   */
  public get defaultColor(): string {
    const defaultColor = this.colorCodes[0];
    const designatedColorVariation = this.colorCodes.find((code) => {
      return code === this._defaultColor;
    });

    return designatedColorVariation ?? defaultColor;
  }

  /**
   * A list with the SKUs of all variations of this product.
   * @returns A list with the SKUs of all variations of this product.
   */
  public get variationSKUs(): ReadonlyArray<string> {
    const upcList: Array<string> = [];

    for (const { upc } of Object.values(this.variationMap)) {
      if (!isNullOrEmpty(upc)) {
        upcList.push(upc);
      }
    }

    return upcList;
  }

  /**
   * A list with the UPCs of all complete variations of this product.
   * @returns A list with the UPCs of all complete variations of this product.
   */
  public get variationUPCs(): ReadonlyArray<string> {
    const upcList: Array<string> = [];

    for (const { upc } of Object.values(this.variationMap)) {
      if (!isNullOrEmpty(upc)) {
        upcList.push(upc);
      }
    }

    return upcList;
  }

  /**
   * Builds a Product Model from any product representation.
   * @param dto - A product representation.
   */
  public constructor(dto: IProduct | DTO<IProduct>) {
    super(dto as DTO<IProduct<T>>);

    this.sku = dto.sku;
    this.upc = dto.upc;
    this.styleNumber = dto.styleNumber;
    this.name = dto.name;
    this.details = dto.details;
    this.group = dto.group;
    this.gender = dto.gender;
    this.isBaseProduct = dto.isBaseProduct;
    this.isCompleteVariant = dto.isCompleteVariant;
    if (dto.price) this.price = PriceModel.from(dto.price);
    this.type = dto.type as T;
    this.reviews = dto.reviews;
    this.isVariation = dto.isVariation;
    this.sizeChart = dto.sizeChart;
    this.categories = dto.categories;
    this.platforms = dto.platforms;

    // Content Properties
    this.contentTag = dto.contentTag;

    // Image Properties.
    this.primaryImage = dto.primaryImage;
    this.secondaryImage = dto.secondaryImage;
    this.swatchImage = dto.swatchImage;
    this._images = dto.images;
    this._media = dto.media;

    // Variation Properties.
    this._availableAttributeTypes = dto.availableAttributeTypes;
    this._variationAttributes = dto.variationAttributes;
    this._selectableAttributes = dto.selectableAttributes;
    this._colorCodes = dto.colorCodes;
    this._defaultColor = dto.defaultColor;
    this._colorVariants = dto.colorVariants;
    this._selectedAttributes = dto.selectedAttributes;
    this._unselectedAttributeTypes = dto.unselectedAttributeTypes;
    this._variationMap = dto.variationMap;

    this._ratings = new LazyValue(
      () =>
        new StaleWhileRevalidate(
          null,
          ReviewsService.getRatingForProduct(dto.styleNumber).catch(() => null)
        )
    );

    this._cachedPlatforms = new LazyValue(async () =>
      Promise.all(
        this.platforms.map(async (platform) =>
          ProductService.getPlatformById(platform)
        )
      )
    );

    this.taxClass = dto.taxClass;
    this.isOnline = dto.isOnline ?? false;

    // Transient Errors
    this.transientErrors = dto.transientErrors
      ? Array.from(dto.transientErrors)
      : null;

    // Inventory Properties
    const staleInventory =
      dto.inventory && !isPromiseLike(dto.inventory)
        ? InventoryModel.from(dto.inventory)
        : null;

    // store the stale inventory
    this._inventory = new StaleWhileRevalidate(staleInventory);

    // Inventory can only be fetched for complete variants
    if (this.isCompleteVariant) {
      this._inventory = new StaleWhileRevalidate(staleInventory, async () => {
        if (isNullOrEmpty(dto.upc)) {
          throw new InvalidStateError(
            `Product with SKU "${dto.sku}" was marked as a complete variant, but is missing a UPC.`
          );
        }

        // We must check for `inBrowser` here since we only want to perform this
        // operation client-side. However, to avoid hydration mismatches, on the
        // server we return a dummy promise that resolves to the stale inventory.
        return (typeof window !== "undefined")
          ? InventoryService.getProductInventory(dto.upc)
          : Promise.resolve(staleInventory);
      });
    }

    makeObservable(this);
  }

  /**
   * Get the currently selected attribute based on an attribute type, if
   * none selected returns null.
   * @param attributeType - A given variation attribute type.
   * @returns A variation attribute of a given type or null.
   *
   * @example
   *
   * ```ts
   * const attr = model.getSelectedAttributeByType(VariationAttributeType.Color);
   * console.log(JSON.stringify(attr));
   *
   * // Output:
   * ```
   * ```json
   * "color-OLV": {
   *    "type": "color",
   *    "name": "OLIVE",
   *    "value": "OLV",
   *    "hexValue": "#77755E",
   *    "primaryImage": {
   *      "uuid": "1113694-OLV-1",
   *      "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_1.png",
   *      "alt": "Yoga Gora"
   *    },
   *    "secondaryImage": {
   *      "uuid": "1113694-OLV-2",
   *      "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_2.png",
   *      "alt": "Yoga Gora"
   *    },
   *    "swatchImage": {
   *      "uuid": "1113694-OLV-S",
   *      "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_1.png",
   *      "alt": "Yoga Gora"
   *    }
   * },
   * ```
   */
  public getSelectedAttributeByType<Type extends VariationAttributeType>(
    attributeType: Type
  ): Nullable<VariationAttributeTypeFromType<Type>> {
    const attributeId: `${VariationAttributeType}-${string}` | undefined =
      this.selectedAttributes[attributeType];

    if (isNullOrEmpty(attributeId)) return null;

    return this.variationAttributes[
      attributeId
    ] as VariationAttributeTypeFromType<Type>;
  }

  /**
   * Returns an array of variation attributes on this product, if valid, based on a given valid UrlSearchParameter.
   * @param searchString - Either string representing a URLSearch param, or URLSearch param object.
   * @returns - Array representing valid selected attributes.
   */
  public getSelectedAttributeBySearchParam(
    searchString: string | URLSearchParams
  ): Array<IVariationAttribute> {
    const params = Array.from(new URLSearchParams(searchString).entries());
    /**
     * Checks if property exists on variationAttributes for this product. Then, returns selected attributes (if valid).
     * Fails gracefully so that invalid options are skipped and don't appear as a selected attribute.
     */
    const validAttributes = Array.from(params).reduce(
      (
        acc: Array<IVariationAttribute>,
        [_, value]
      ): Array<IVariationAttribute> => {
        if (Object.hasOwn(this.variationAttributes, value)) {
          return [
            ...acc,
            this.variationAttributes[value as VariationAttributeID]
          ];
        }
        return acc;
      },
      []
    );

    return validAttributes;
  }

  /**
   * Returns /p/ url with category path, if current location is category path (i.e. starts with /c/). Otherwise, return this models's url.
   * @param pathname - The pathname of the current location or url to check against category path.
   * @returns - The url with category path or this model's url with primary category chain.
   */
  public getUrlWithCategory(pathname?: string): string {
    // Retrieve paths to send to product page from a category page.
    let href: string = '';
    // Should short circuit to return this model's url if not on a category page.
    if (!isNullOrEmpty(pathname)) {
      // Split pathname into array of strings and filter out empty strings.
      const paths = pathname.split('/').filter((path) => path);
      // Assumption: All and Only Category paths will start with /c/
      const isCategoryPage = paths[0] === 'c';
      if (isCategoryPage) {
        /**
         * Example:
         * - this.paths = ['p', 'soft-top-comfort', '11112312']
         * - paths = ['c', 'womens', 'womens footwear', 'new arrivals']
         * - hrefArray = ['/p', 'womens', 'womens footwear', 'new arrivals', 'soft-top-comfort', '11112312?color=BLK&size=8']
         * - return hrefArray.join('/').
         */
        href = ['/p', ...paths.slice(1), ...this.paths.slice(1)].join('/');
        return href;
      }
    }
    const categories = this.primaryCategoryChain?.split('>') ?? [];
    /**
     * Example:
     * - this.paths = ['p', 'soft-top-comfort', '11112312']
     * - categories = ['womens', 'womens footwear', 'new arrivals']
     * - href = ['/p', 'womens', 'womens footwear', 'new arrivals', 'soft-top-comfort', '11112312?color=BLK&size=8']
     * - return href.
     */
    href = ['/p', ...categories, ...this.paths.slice(1)].join('/');
    return href;
  }

  /**
   * Checks if the specified attribute type is selected or not.
   * @param attributeType - The {@link VariationAttributeType} to check.
   * @returns `true` if the specified attribute type is selected.
   */
  public isAttributeTypeSelected(
    attributeType: VariationAttributeType
  ): boolean {
    return !isNullish(this.selectedAttributes[attributeType]);
  }

  /**
   * Provides all "available" variations of a product for a given product variation attribute.
   * @param attributeType - The production variation attribute to filter by.
   * @returns An array of all applicable product variations for a given attribute.
   *
   * @example
   *
   * ```ts
   * const attrs = model.getAvailableAttributesByType(VariationAttributeType.Color);
   * console.log(JSON.stringify(attrs));
   *
   * // Output:
   * ```
   * ```json
   * [
   *    "color-OLV": {
   *       "type": "color",
   *       "name": "OLIVE",
   *       "value": "OLV",
   *       "hexValue": "#77755E",
   *       "primaryImage": {
   *         "uuid": "1113694-OLV-1",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_1.png",
   *         "alt": "Yoga Gora"
   *       },
   *       "secondaryImage": {
   *         "uuid": "1113694-OLV-2",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_2.png",
   *         "alt": "Yoga Gora"
   *       },
   *       "swatchImage": {
   *         "uuid": "1113694-OLV-S",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_1.png",
   *         "alt": "Yoga Gora"
   *       }
   *    },
   *    "color-BLK": {
   *       "type": "color",
   *       "name": "BLACK",
   *       "value": "BLK",
   *       "hexValue": "#111111",
   *       "primaryImage": {
   *         "uuid": "1113694-BLK-1",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-BLK_1.png",
   *         "alt": "Yoga Gora"
   *       },
   *       "secondaryImage": {
   *         "uuid": "1113694-BLK-2",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-BLK_2.png",
   *         "alt": "Yoga Gora"
   *       },
   *       "swatchImage": {
   *         "uuid": "1113694-BLK-S",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-BLK_1.png",
   *         "alt": "Yoga Gora"
   *       }
   *    }
   * ]
   * ```
   */
  public getAvailableAttributesByType<Type extends VariationAttributeType>(
    attributeType: Type
  ): ReadonlyArray<VariationAttributeTypeFromType<Type>> {
    const allAttributes = Object.values(this.variationAttributes) as Array<
      VariationAttributeTypeFromType<Type>
    >;

    return (
      allAttributes
        // Filter the attributes by their type
        .filter(({ type }) => type === attributeType)
        // Sort the attributes by their position.
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
    );
  }

  /**
   * Provides all "selectable" variation attributes of a product for a given attribute type.
   * @param attribute - The production variation attribute to filter by.
   * @returns An array of all applicable product variations for a given attribute.
   *
   * @example
   *
   * ```ts
   * const selectableAttrs = model.getSelectableAttributesByType(VariationAttributeType.Color);
   * console.log(JSON.stringify(selectableAttrs));
   *
   * // Output:
   * ```
   * ```json
   * [
   *    "color-OLV": {
   *       "type": "color",
   *       "name": "OLIVE",
   *       "value": "OLV",
   *       "hexValue": "#77755E",
   *       "primaryImage": {
   *         "uuid": "1113694-OLV-1",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_1.png",
   *         "alt": "Yoga Gora"
   *       },
   *       "secondaryImage": {
   *         "uuid": "1113694-OLV-2",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_2.png",
   *         "alt": "Yoga Gora"
   *       },
   *       "swatchImage": {
   *         "uuid": "1113694-OLV-S",
   *         "src": "https://dms.deckers.com/sanuk/.../1113694-OLV_1.png",
   *         "alt": "Yoga Gora"
   *       }
   *    }
   * ]
   * ```
   */
  public getSelectableAttributesByType<Type extends VariationAttributeType>(
    attribute: Type
  ): ReadonlyArray<VariationAttributeTypeFromType<Type>> {
    const attributeIDs = this.selectableAttributes[attribute];

    if (!attributeIDs) return [];

    return attributeIDs.map(
      (id) =>
        this.variationAttributes[id] as VariationAttributeTypeFromType<Type>
    );
  }

  /**
   * Returns the variant SKU that results when selecting a given attribute.
   *
   * @param attribute - The product variation attribute to select for this product.
   * @returns The SKU of the product with the variant attribute selected.
   *
   * @example ```ts
   * const masterProduct: IProduct = ProductModel.from(product);
   *
   * if (clickedSize) {
   *    const variantSKU = masterProduct.selectAttributeSKU(SizeVariationAttribute);
   *    router.push(`/p/${variantSKU}`);
   * }
   * ```
   *
   * @throws A {@link ResourceNotFoundError} when selecting the specified attribute does
   * not point to a valid variant.
   */
  public selectAttributeSKU(
    attribute: IVariationAttribute | Array<IVariationAttribute>
  ): [string, didSelect: boolean] {
    // Initialize the flag that will indicate if attributes were changed to
    // be able to accommodate the customer's last attribute selection.
    let attributesChanged = false;

    // Build the attribute ID with the type and value, but reduce attribute array if multiple present.
    let newAttributes: {
      [type in VariationAttributeType]?: VariationAttributeID;
    };

    if (Array.isArray(attribute)) {
      // Reduces several selected attributes into a single object if several attrs are passed in as array.
      // Example: [{ type: 'color', value: 'BLK' }, { type: 'size', value: '8' }] => { color: 'color-BLK', size: 'size-8' }
      newAttributes = attribute.reduce((acc, attr) => {
        return {
          ...acc,
          [attr.type]: `${attr.type}-${attr.value}`
        };
      }, {});
    } else {
      newAttributes = {
        [attribute.type]: `${attribute.type}-${attribute.value}`
      }; // Just return an object based on a single value.
    }
    // previously selected attributes + new attribute with the type as its key
    const attributes: {
      [type in VariationAttributeType]?: VariationAttributeID;
    } = {
      ...this.selectedAttributes,
      ...newAttributes
    };

    // Get the SKU of the product variation that satisfies the specified attributes.
    let newSKU = ProductService.getProductSKUBySelectedAttributes(
      this,
      attributes
    );

    // If there's no new SKU (meaning that no variation satisfies the attribute selection)...
    if (isNullOrEmpty(newSKU)) {
      // Set the changed flag to true...
      attributesChanged = true;

      // Make a new attribute selection by including ONLY the last selected attribute.
      // This means that all previously selected attributes have been deselected.
      // Try to find the SKU of the variation that satisfies this new selection.
      newSKU = ProductService.getProductSKUBySelectedAttributes(
        this,
        newAttributes
      );

      // If there is no SKU this time, something's wrong.
      if (isNullOrEmpty(newSKU))
        throw new ResourceNotFoundError(
          'There is no product that satisfies the specified attribute.'
        );
    }

    // Return both the SKU of the new variation and the attributesChanged flag.
    return [newSKU, attributesChanged];
  }

  /**
   * Returns the variant that results when selecting a given attribute.
   *
   * @param attribute - The product variation attribute to select for this product.
   *
   * @returns
   * The product variant that results when the attribute is selected, along with
   * a flag that indicates if another attribute was overridden to accommodate the
   * selection.
   *
   * @example
   * const masterProduct: IProduct = ProductModel.from(product);
   *
   * if (clickedSize) {
   *    const [variantProduct, attributesChanged] = masterProduct.selectAttribute(SizeVariationAttribute);
   *    router.push(`/p/${variantProduct.sku}`);
   * }
   */
  public async selectAttribute(
    attribute: IVariationAttribute | Array<IVariationAttribute>
  ): Promise<[ProductModel, boolean]> {
    const [newSKU, attributesChanged] = this.selectAttributeSKU(attribute);

    // Return both a ProductModel for the new variation and the attributesChanged flag.
    return [await ProductService.getProduct(newSKU), attributesChanged];
  }

  /**
   * Returns the variant that results when _deselecting_ a given attribute.
   *
   * @param attribute - The variation attribute to deselect for this product.
   *
   * @returns
   * The product variant with the attribute removed and a flag to indicate if any
   * other attribute was overridden.
   *
   * @example
   * const masterProduct: IProduct = ProductModel.from(product);
   *
   * if (clickedSize && sizeAlreadySelected) {
   *    const [variantProduct, attributesChanged] = masterProduct.deselectAttribute(SizeVariationAttribute);
   *    router.push(`/p/${variantProduct.sku}`);
   * }
   */
  public async deselectAttribute(
    attribute: IVariationAttribute | Array<IVariationAttribute>
  ): Promise<[ProductModel, boolean]> {
    // Start with the existing selected attributes:
    const attributes: {
      [key in VariationAttributeType]?: VariationAttributeID;
    } = {
      ...this.selectedAttributes
    };

    // If the attribute passed in as param is not already an array, make it one for the below forEach loop:
    const attributesToCheck = Array.isArray(attribute)
      ? attribute
      : [attribute];

    // Remove each attribute in param from the selected attributes.
    attributesToCheck.forEach((attr) => {
      if (this.getSelectedAttributeByType(attr.type)?.value === attr.value) {
        // Only deselect if the given attribute is the one selected.
        delete attributes[attr.type];
      }
    });

    const newSKU = await ProductService.getProductSKUBySelectedAttributes(
      this,
      attributes
    );

    if (isNullOrEmpty(newSKU)) {
      throw new InvalidStateError(
        `No SKU was found in model "${
          this.styleNumber
        }" for the following attribute selection: ${JSON.stringify(attributes)}`
      );
    }

    return [
      ProductModel.from(await ProductService.getProductDTO(newSKU)),
      false
    ];
  }

  /**
   * Returns selected attributes as URLSearchParams, with key of attribute as param key and value as search value.
   * @returns - Stringified URL search params.
   */
  public get attributeParams(): string {
    return new URLSearchParams(this.selectedAttributes).toString();
  }

  /**
   * Either {@link selectAttribute selects} or {@link deselectAttribute deselects} a variation attribute depending on its state.
   * Returns the resulting product variant.
   *
   * @param attribute - The product variation attribute to toggle for this product.
   * @returns The product variant with the attribute toggled.
   *
   * @example ```ts
   * const masterProduct: IProduct = ProductModel.from(product);
   *
   * if (clickedSize) {
   *    const [variantProduct, attributesChanged] = masterProduct.toggleAttributeSelection(SizeVariationAttribute);
   *    router.push(`/p/${variantProduct.sku}`);
   * }
   * ```
   */
  public async toggleAttributeSelection(
    attribute: IVariationAttribute | Array<IVariationAttribute>
  ): Promise<[ProductModel, boolean]> {
    const newAttributes: {
      [type in VariationAttributeType]?: VariationAttributeID;
    } = Array.isArray(attribute)
      ? attribute.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.type]: `${curr.type}-${curr.value}`
          };
        }, {})
      : { [attribute.type]: `${attribute.type}-${attribute.value}` };

    const attributesToDelete = Array.isArray(attribute)
      ? attribute.filter(
          (attr) =>
            this.selectedAttributes[attr.type] === newAttributes[attr.type]
        )
      : [attribute].filter(
          (attr) =>
            this.selectedAttributes[attr.type] === newAttributes[attr.type]
        );

    if (attributesToDelete.length) {
      this.deselectAttribute(attributesToDelete);
    }

    return this.selectAttribute(attribute);
  }

  /**
   * Gets the {@link IPlatform platforms} for this product.
   * @returns A promise that resolves to an array of platforms.
   */
  public async getPlatforms(): Promise<Array<PlatformModel>> {
    return this._cachedPlatforms.value;
  }

  /**
   * Refreshes the inventory data for this product.
   * @returns A promise that resolves to the updated inventory data.
   */
  public async refreshInventory(): Promise<Nullable<InventoryModel>> {
    const { isCompleteVariant, upc, sku } = this;
    if (!isCompleteVariant || isNullOrEmpty(upc))
      throw new InvalidStateError(
        `Product with SKU "${sku}" was marked as a complete variant, but is missing a UPC.`
      );

    this._inventory = new StaleWhileRevalidate(
      this._inventory.value,
      async () => InventoryService.getProductInventory(upc)
    );

    return Promise.resolve(this._inventory);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IProduct<T>> {
    const dto: DTO<IProduct<ProductType>> = {
      sku: this.sku,
      upc: this.upc,
      styleNumber: this.styleNumber,
      name: this.name,
      gender: this.gender,
      group: this.group,
      isBaseProduct: this.isBaseProduct,
      isCompleteVariant: this.isCompleteVariant,
      price: this.price?.toDTO(),
      type: this.type,
      reviews: this.reviews,
      isVariation: this.isVariation,
      details: this.details,
      sizeChart: this.sizeChart,
      contentTag: this.contentTag,
      categories: this.categories,
      platforms: this.platforms,
      taxClass: this.taxClass,
      inventory: this.inventory.value?.toDTO() as Nullable<IInventory>,
      // Image Properties.
      primaryImage: this.primaryImage,
      secondaryImage: this.secondaryImage,
      swatchImage: this.swatchImage,
      images: this.images,
      media: this.media,
      // Variation Properties.
      availableAttributeTypes: this.availableAttributeTypes,
      variationAttributes: this.variationAttributes,
      selectableAttributes: this.selectableAttributes,
      colorVariants: this.colorVariants,
      selectedAttributes: this.selectedAttributes,
      unselectedAttributeTypes: this.unselectedAttributeTypes,
      variationMap: this.variationMap,
      isOnline: this.isOnline,
      colorCodes: this.colorCodes,
      defaultColor: this.defaultColor,
      transientErrors: this.transientErrors
    };

    return removeUndefined(dto) as DTO<IProduct<T>>;
  }
}

export default ProductModel;
