import axios from 'axios';

import { DTO } from '@/type-utils';

import Service from '../../Service';
import { ISession, SessionModel } from '../../models/Session';
import ServerSessionService from '../../serverless/ServerSessionService';

import { EnvironmentService } from '../EnvironmentService';
import SessionServiceMock from './SessionServiceMock';

/**
 * Isomorphic abstraction service for session-related operations.
 * For the server-side service, see {@link ServerSessionService}.
 */
export class SessionService extends Service {
  private client = axios.create({
    baseURL: '/api/session'
  });

  /**
   * Retrieves the current session for this request.
   * If the session doesn't exist in the local memory cache, retrieves it from the
   * persistent session storage.
   *
   * @returns A session model representing the current session.
   */
  public get currentSession(): Promise<SessionModel> {
    return (async () => {
      if ((typeof window === "undefined")) {
        return ServerSessionService.currentSession;
      }

      const res = await this.client.get<DTO<ISession>>('');

      // NOTE: This is not optimal.
      // Axios doesn't seem to like the nested stringified JSONs in the response.
      // The automatic parsing omits data. So as a stop-gap fix, take the raw
      // response and parse it with `JSON.parse`.

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- See above.
      const rawResponse = res.request!.response;
      const dto = JSON.parse(rawResponse) as DTO<ISession>;

      return SessionModel.from(dto);
    })();
  }
}

export default SessionService.withMock(
  new SessionServiceMock(SessionService)
) as unknown as SessionService;
