import { IHandler } from '@/services/utils/chain-of-responsibility';
import siteCached from '@/services/utils/siteCached';
import ConfigurationService from '../../ConfigurationService';
import {
  EventType,
  InteractionDetails
} from '../../UserInteractionService/IInteractionDetails';
import GTMService from './GTMService';

/** An {@link IHandler} for GTM analytics. */
export default class GTMUserInteractionHandler
  implements IHandler<InteractionDetails, Promise<void>>
{
  /**
   * Whether GTM is enabled.
   * @returns A boolean.
   */
  @siteCached
  private get isEnabled(): boolean {
    return ConfigurationService.getConfig('tagManagement').getSetting(
      'enableGTM'
    ).value;
  }

  /** @inheritdoc */
  public async handle(
    requestData: InteractionDetails,
    next: (requestData: InteractionDetails) => Promise<void>
  ): Promise<void> {
    if (this.isEnabled) {
      switch (requestData.action) {
        case EventType.ShippingStart:
        case EventType.ShippingError:
        case EventType.ShippingSubmit:
        case EventType.ShippingSuccess:
        case EventType.BillingStart:
        case EventType.BillingError:
        case EventType.BillingSubmit:
        case EventType.BillingSuccess:
        case EventType.OrderPlace:
        case EventType.OrderError:
        case EventType.OrderSuccess:
        case EventType.PageView:
        case EventType.ProductAdd:
        case EventType.ProductUpdate:
        case EventType.ProductUpdateVariation:
        case EventType.ProductSizeChart:
        case EventType.ProductRemove:
        case EventType.QuickviewShow:
        case EventType.SearchShowMore:
        case EventType.SearchSort:
        case EventType.NavigationLink:
        case EventType.UserSignout:
        case EventType.LoginError:
        case EventType.LoginRegister:
        case EventType.LoginSubmit:
        case EventType.LoginSuccess:
        case EventType.PasswordEdit:
        case EventType.ProfileEdit:
        case EventType.SignupError:
        case EventType.SignupSuccess:
        case EventType.FormInput:
        case EventType.FormSubmit:
          await GTMService.makeGTMAction(requestData);
          return undefined;
        default:
          return next(requestData);
      }
    }
    return next(requestData);
  }
}
