import axios, { type AxiosInstance } from 'axios';
import Service, { ServiceMethodArgs, ServiceResponse } from '../../Service';
import ServerFraudService, {
  type ServerFraudServiceType
} from '../../serverless/ServerFraudService';
import type {
  IFraudMetadata,
  IFraudService
} from '../../serverless/ServerFraudService/schemas';
import { EnvironmentService } from '../EnvironmentService';
import FraudServiceMock from './FraudServiceMock';

/**
 * An isomorphic implementation of the IFraudService facade.
 */
export class FraudService
  extends Service
  implements IFraudService<IFraudMetadata>
{
  /**
   * An AxiosInstance for hitting our `fraud/*` API endpoints.
   * @returns An AxiosInstance.
   */
  private client: AxiosInstance = axios.create({ baseURL: '/api/fraud' });

  /** @inheritdoc */
  public async validateOrder(
    params: ServiceMethodArgs<ServerFraudServiceType, 'validateOrder'>
  ): Promise<ServiceResponse<ServerFraudServiceType, 'validateOrder'>> {
    if ((typeof window === "undefined")) {
      return ServerFraudService.validateOrder(params);
    }

    const response = await this.client.post<
      ServiceResponse<ServerFraudServiceType, 'validateOrder'>
    >('/order/validate', params);
    return response.data;
  }

  /** @inheritdoc */
  public async sendOrderStatusEvent(
    params: ServiceMethodArgs<ServerFraudServiceType, 'sendOrderStatusEvent'>
  ): Promise<ServiceResponse<ServerFraudServiceType, 'sendOrderStatusEvent'>> {
    if ((typeof window === "undefined")) {
      ServerFraudService.sendOrderStatusEvent(params);
      return;
    }

    await this.client.post<
      ServiceResponse<ServerFraudServiceType, 'sendOrderStatusEvent'>
    >('/order/status', params);
  }

  /** @inheritdoc */
  public async sendAccountEvent(
    params: ServiceMethodArgs<ServerFraudServiceType, 'sendAccountEvent'>
  ): Promise<ServiceResponse<ServerFraudServiceType, 'sendAccountEvent'>> {
    if ((typeof window === "undefined")) {
      ServerFraudService.sendAccountEvent(params);
      return;
    }

    await this.client.post<
      ServiceResponse<ServerFraudServiceType, 'sendAccountEvent'>
    >('/account', params);
  }
}

export default FraudService.withMock(
  new FraudServiceMock(FraudService)
) as FraudService;
