import type { InstanceTypeOf } from '@/type-utils';
import type { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { ResourceNotFoundError } from '@/utils/errors';

export const { CookieNotFoundError } = errorFactory(
  'CookieNotFoundError',
  ResourceNotFoundError,
  {
    mapsToHTTPError: 'NotFoundError'
  } as IHTTPErrorMetaData
);

/** Error to use when an expected cookie is not found. */
export type CookieNotFoundError = InstanceTypeOf<typeof CookieNotFoundError>;
