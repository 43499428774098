import { mocked } from '@/configs';

import { Constructor, DTO } from '@/type-utils';

import type { CartModel, ICart } from '../../models/Cart';
import type { ILineItem } from '../../models/Cart/LineItem';
import {
  ICartPromotionUpdates,
  PromotionTarget
} from '../../models/Cart/Promotion';
import { ILineItemPromotion } from '../../models/Cart/Promotion/LineItem';
import { IMoney } from '../../models/Money';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { PromotionsService } from './PromotionsService';

const initialState = {};

/**
 * Mock implementation of the PromotionsService class.
 */
class PromotionsServiceMock extends ServiceMock<PromotionsService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): PromotionsService {
    return MockService.getMockOf(this.service) as unknown as PromotionsService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<PromotionsService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<PromotionsService>
  ): void {
    const mockEnabled: boolean = mocked.PromotionsService;

    const cartPromotionUpdates: ICartPromotionUpdates = {
      coupons: [
        {
          code: 'ZIGGY5'
        }
      ],
      cartPromotions: [],
      lineItemPromotions: {
        '1013816-HMST-06.5': [],
        '1116734-BLK-07': [
          {
            id: '0',
            campaignID: '0',
            name: '5 off Ziggy',
            target: PromotionTarget.LineItem,
            type: 0,
            label: '$5 off total when adding at least one Ziggy (code ZIGGY5)',
            visible: true,
            apply: 'once',
            mode: 0,
            value: 2.5
          } as ILineItemPromotion,
          {
            id: '0',
            campaignID: '0',
            name: '5 off Ziggy',
            target: PromotionTarget.LineItem,
            type: 0,
            label: '$5 off total when adding at least one Ziggy (code ZIGGY5)',
            visible: true,
            apply: 'once',
            mode: 0,
            value: 2.5
          } as ILineItemPromotion
        ]
      }
    };

    MockService.mockService(
      mockEnabled,
      service,
      {
        getCartPromotions: async (
          cart: DTO<ICart>
        ): Promise<ICartPromotionUpdates> => {
          return cartPromotionUpdates;
        },

        applyCoupon: async (
          cart: CartModel,
          couponCode: string
        ): Promise<ICartPromotionUpdates> => {
          return cartPromotionUpdates;
        },

        removeCoupon: async (
          cart: CartModel,
          couponCode: string
        ): Promise<ICartPromotionUpdates> => {
          return cartPromotionUpdates;
        },

        applyPromotionUpdatesToCartDTO: (
          updates: ICartPromotionUpdates,
          dto: DTO<ICart>
        ): DTO<ICart> => {
          return {
            uuid: '9be7e5f8-b2b5-4a7f-bd8b-c169a070e84b',
            ownerID: '3e130a60-3c0c-4852-a23f-77884f22bae9',
            promotions: [],
            items: [
              {
                uuid: '73e755a9-44f0-4318-aab6-b166ae0e6a68',
                cartID: '9be7e5f8-b2b5-4a7f-bd8b-c169a070e84b',
                type: 'Product',
                sku: '1013816-HMST-06.5',
                name: 'Pair O Dice',
                image: {
                  uuid: '1013816-HMST-1',
                  src: 'https://dms.deckers.com/sanuk/image/upload/v1672944962/transparent/1013816-HMST_6.png',
                  alt: 'Pair O Dice',
                  aspectRatio: 1.2523481527864746
                },
                quantity: 1,
                unitPrice: {
                  retailPrice: {
                    amount: '45',
                    currency: 'USD'
                  } as IMoney,
                  isDiscounted: false
                },
                group: 'women'
              } as DTO<ILineItem>,
              {
                uuid: '915e6fee-2005-4d0e-8073-650d3428969c',
                cartID: '9be7e5f8-b2b5-4a7f-bd8b-c169a070e84b',
                type: 'Product',
                sku: '1116734-BLK-07',
                name: 'Ziggy',
                image: {
                  uuid: '1116734-BLK-1',
                  src: 'https://dms.deckers.com/sanuk/image/upload/v1631596027/transparent/1116734-BLK_1.png',
                  alt: 'Ziggy',
                  aspectRatio: 1.2629931120851596
                },
                quantity: 2,
                unitPrice: {
                  retailPrice: {
                    amount: '35',
                    currency: 'USD'
                  } as IMoney,
                  isDiscounted: false
                },
                group: null
              } as DTO<ILineItem>
            ],
            coupons: [
              {
                code: 'ZIGGY5',
                id: '1'
              }
            ],
            tax: {
              amount: '0',
              currency: 'USD'
            } as IMoney,
            cartDiscount: {
              amount: '0',
              currency: 'USD'
            } as IMoney,
            linesDiscount: {
              amount: '0',
              currency: 'USD'
            } as IMoney,
            discount: {
              amount: '0',
              currency: 'USD'
            } as IMoney,
            subtotal: {
              amount: '115',
              currency: 'USD'
            } as IMoney,
            total: {
              amount: '110',
              currency: 'USD'
            } as IMoney,
            shippingCost: {
              amount: '0',
              currency: 'USD'
            } as IMoney,
            shipToAddress: null,
            selectedShippingMethod: null,
            giftCards: []
          };
        }
      },
      {},
      this.state
    );
  }
}

export default PromotionsServiceMock;
