'use client';

import { setupPreviews } from '@previewjs/plugin-react/setup';
import { FunctionComponent } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Circle } from './ButtonDecorations/Circle';
import { Spinner } from '../Spinner';
import { useInteraction } from '../../../hooks/useInteraction';

import { ButtonSize, ButtonVariant, IButtonProps } from './IButtonProps';

import S from './styles.base.module.scss';

/**
 * Get the core styling from the variant string.
 * @param variant - String that represents a button styling type.
 * @returns Returns a className from the styling import.
 */
const getStylingFromVariant = (variant: ButtonVariant): string => {
  switch (variant) {
    case 'primary':
      return S.primary;
    case 'secondary':
      return S.secondary;
    case 'tertiary':
      return S.tertiary;
    case 'danger':
      return S.danger;
    case 'link':
      return S.link;
    case 'decorative-link':
      return S.decorativeLink;
    // TODO: if button is circle variant, include the circle svg. This should be moved to a separate component that handles unique button variants.
    case 'hand-drawn-circle':
      return S.handDrawnCircle;
    case 'text':
      return S.text;
    case 'transparent-light':
      return S.transparentLight;
    case 'transparent-dark':
      return S.transparentDark;
    default:
      return '';
  }
};

/**
 * Get the styling for size.
 * @param size - String that represents a button size.
 * @returns Returns a className from the styling import.
 */
const getStylingFromSize = (size: ButtonSize): string => {
  switch (size) {
    case 'sm':
      return S.sizeSm;
    case 'md':
      return S.sizeMd;
    case 'lg':
      return S.sizeLg;
    default:
      return '';
  }
};

/**
 * `Button` is used to create configurable on-brand buttons throughout the site.
 */
export const Button: FunctionComponent<IButtonProps> = ({
  id,
  actionId,
  className,
  variant = 'primary',
  submit,
  fullWidth,
  title = '',
  ariaLabel,
  ariaExpanded,
  role,
  size = 'md',
  disabled = false,
  hover = false,
  loading = false,
  onClick,
  onKeyPress,
  style,
  interactionDetails,
  datatestID,
  children
}) => {
  const { onClickEvent, onKeyPressEvent } = useInteraction(
    onClick,
    onKeyPress,
    interactionDetails
  );

  return (
    <button
      id={id}
      className={classes(
        className,
        getStylingFromVariant(variant),
        getStylingFromSize(size),
        fullWidth ? S.fullWidth : '',
        disabled ? S.disabled : '',
        hover ? S.hover : '',
        loading ? S.loading : ''
      )}
      onClick={onClickEvent}
      onKeyPress={onKeyPressEvent}
      style={style}
      title={title}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      role={role}
      type={submit ? 'submit' : 'button'}
      disabled={disabled || loading}
      data-actionid={actionId}
      data-testid={datatestID}
    >
      {loading ? (
        <Spinner className={S.spinner} />
      ) : (
        <>
          {children}
          {
            // TODO: if button is circle variant, include the circle svg. This should be moved to a separate component that handles unique button variants.
            variant === 'hand-drawn-circle' && <Circle />
          }
        </>
      )}
    </button>
  );
};

export default Button;

export * from './IButtonProps';

/** @see https://previewjs.com/docs/features/custom-previews */
setupPreviews(Button, () => ({
  default: {
    children: 'Example Text'
  } as IButtonProps
}));
