import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { CartQuantityExceededError } from './CartQuantityExceededError';

export const { CartTotalQuantityExceededError } = errorFactory(
  'CartTotalQuantityExceededError',
  CartQuantityExceededError
);

/** Error to use when the total number of items in the cart has exceeded its configured value. */
export type CartTotalQuantityExceededError = InstanceTypeOf<
  typeof CartTotalQuantityExceededError
>;
