import { IHandler } from '@/services/utils/chain-of-responsibility';
import siteCached from '@/services/utils/siteCached';
import ConfigurationService from '../../ConfigurationService';
import {
  EventType,
  InteractionDetails
} from '../../UserInteractionService/IInteractionDetails';
import CoveoAnalyticsService from './CoveoAnalyticsService';

/** An {@link IHandler} for Coveo search analytics. */
export default class CoveoUserInteractionHandler
  implements IHandler<InteractionDetails, Promise<void>>
{
  /**
   * Whether search analytics are enabled.
   * @returns A boolean.
   */
  @siteCached
  private get isEnabled(): boolean {
    return ConfigurationService.getConfig('search').getSetting(
      'enableSearchAnalytics'
    ).value;
  }

  /** @inheritdoc */
  public async handle(
    requestData: InteractionDetails,
    next: (requestData: InteractionDetails) => Promise<void>
  ): Promise<void> {
    if (this.isEnabled) {
      switch (requestData.action) {
        case EventType.TileClick:
          // The coveo click event, is triggered when the user clicks a product tile.
          await CoveoAnalyticsService.clickEvent(requestData);
          return undefined;
        case EventType.SearchComplete:
          // The coveo search event used when the user submits a search.
          await CoveoAnalyticsService.searchEvent(requestData);
          return undefined;
        default:
          return next(requestData);
      }
    }
    return next(requestData);
  }
}
