import type { RoundingMode } from 'big.js';

/**
 * Represents a rounding mode, which indicates how to round a number at
 * some decimal place value.
 *
 * The identifier names were chosen based on standard terminology and to
 * avoid mathematical ambiguity.
 * @see {@link https://en.wikipedia.org/wiki/Rounding#Rounding_to_integer Rounding To Integer on Wikipedia}.
 *
 * Although not all rounding modes are defined in this enum, more could be added in
 * the future. The current values are based on the {@link RoundingMode} enum from `big.js`.
 * As a result, do not rely on the numeric values of the enum members, as they may change.
 */
enum Round {
  /**
   * Rounds towards zero. Also known as "truncation".
   * @example
   * '1.567' -> '1.56'
   * '1.564' -> '1.56'
   * '-1.564' -> '-1.56'
   * '-1.567' -> '-1.56'
   */
  TowardZero = 0,

  /**
   * Rounds towards nearest neighbor. If equidistant, rounds away from zero.
   * @example
   * '1.567' -> '1.57'
   * '1.565' -> '1.57'
   * '1.564' -> '1.56'
   * '-1.564' -> '-1.56'
   * '-1.565' -> '-1.57'
   */
  HalfAwayFromZero = 1,

  /**
   * Rounds towards nearest neighbor. If equidistant, rounds towards even neighbor.
   * Also known as "banker's rounding".
   * @example
   * '1.567' -> '1.57'
   * '1.564' -> '1.56'
   * '1.565' -> '1.56'
   * '1.555' -> '1.56'
   * '-1.555' -> '1.56'
   */
  HalfToEven = 2,

  /**
   * Rounds away from zero.
   * @example
   * '1.567' -> '1.57'
   * '1.564' -> '1.57'
   * '-1.564' -> '-1.57'
   */
  AwayFromZero = 3
}

export default Round;
