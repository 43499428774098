import axios from 'axios';

import { DTO, type Nullable } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import MemoryCache from '@/utils/MemoryCache';
import { TimeScale } from '@/utils/time-utils';

import { exhaustiveGuard } from '@/utils/function-utils';
import LazyValue from '@/utils/LazyValue';
import { maybe } from '@/utils/null-utils';
import {
  IInventory,
  InventoryModel,
  ProductAvailabilityState
} from '../../models/Inventory';

import ServerInventoryService, {
  type UPCToInventoryRecord
} from '../../serverless/ServerInventoryService';
import Service from '../../Service';

import type { UPCToInventoryModelRecord } from '.';
import ConfigurationService from '../ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';
import { msg } from '../I18NService';
import InventoryServiceMock from './InventoryServiceMock';
import { product_details_inStock } from "@/lang/__generated__/ahnu/product_details_inStock";
import { product_details_backorder } from "@/lang/__generated__/ahnu/product_details_backorder";
import { product_details_outOfStock } from "@/lang/__generated__/ahnu/product_details_outOfStock";
import { product_details_preorder } from "@/lang/__generated__/ahnu/product_details_preorder";
import { product_details_unavailable } from "@/lang/__generated__/ahnu/product_details_unavailable";

/**
 * Abstracts inventory-related operations.
 *
 * @see {@link ServerInventoryService} for the server-only service.
 */
export class InventoryService extends Service {
  private client = axios.create({ baseURL: '/api/inventory' });

  // Use lazy values here to prevent "Could not determine the locale" errors.
  private _inventoryCache = new LazyValue(() => {
    const inventoryConfig = ConfigurationService.getConfig('inventory');

    return new MemoryCache<Promise<Nullable<InventoryModel>>>(
      inventoryConfig.getSetting('isomorphicInventoryCacheTTL').value,
      TimeScale.Seconds
    );
  });

  private _staleInventoryCache = new LazyValue(() => {
    const inventoryConfig = ConfigurationService.getConfig('inventory');

    return new MemoryCache<InventoryModel>(
      inventoryConfig.getSetting('isomorphicStaleInventoryCacheTTL').value,
      TimeScale.Seconds
    );
  });

  /**
   * A memory cache for inventory data. The cache is shared between single and
   * bulk inventory GET calls.
   *
   * On bulk calls, the service will only attempt to retrieve any inventory
   * records that were not previously cached.
   *
   * @returns A {@link MemoryCache} instance.
   */
  private get inventoryCache(): MemoryCache<Promise<Nullable<InventoryModel>>> {
    return this._inventoryCache.value;
  }

  /**
   * A secondary, longer-lived cache for inventory data. Useful for storing
   * inventory data that is known to be stale without disrupting the primary
   * cache.
   *
   * @returns A {@link MemoryCache} instance.
   */
  private get staleInventoryCache(): MemoryCache<InventoryModel> {
    return this._staleInventoryCache.value;
  }

  /**
   * Retrieve inventory for a product.
   *
   * @param upc - The product's {@link IProduct.upc UPC}.
   * @returns An {@link InventoryModel}.
   */
  public async getProductInventory(upc: string): Promise<InventoryModel> {
    // Return cached value if available.
    if (this.inventoryCache.has(upc)) {
      const cachedModel = await this.inventoryCache.get(upc);
      if (cachedModel) return cachedModel;
      // otherwise, continue to fetch the data below
    }

    if ((typeof window === "undefined")) {
      const dto = await ServerInventoryService.getProductInventory(upc);
      return new InventoryModel(dto);
    }

    const inventoryModelPromise = this.client
      .get<DTO<IInventory>>(`/${upc}`)
      .then(({ data }) => new InventoryModel(data));

    this.inventoryCache.add(upc, inventoryModelPromise);

    const model = await inventoryModelPromise;

    return model;
  }

  /**
   * Retrieve inventory for multiple products.
   *
   * @param upcList - The {@link IProduct.upc UPCs} of the products to fetch inventory for.
   *
   * @returns A map with the passed UPCs as keys and their respective inventory
   * records (in {@link InventoryModel} form) as values.
   */
  public async bulkGetProductInventory(
    ...upcList: ReadonlyArray<string>
  ): Promise<UPCToInventoryModelRecord> {
    // Return cached values if available.
    const cachedUPCs = [];
    const nonCachedUPCs = [];

    for (const upc of upcList) {
      if (this.inventoryCache.has(upc)) {
        cachedUPCs.push(upc);
      } else {
        nonCachedUPCs.push(upc);
      }
    }

    const cachedUPCInventoryTuple = await Promise.all(
      cachedUPCs.map(
        async (upc) => [upc, await this.inventoryCache.get(upc)] as const
      )
    );

    const cachedModels = Object.fromEntries(cachedUPCInventoryTuple);

    if (nonCachedUPCs.length === 0) {
      return cachedModels;
    }

    if ((typeof window === "undefined")) {
      const fetchedData = await ServerInventoryService.bulkGetProductInventory(
        ...nonCachedUPCs
      );

      const fetchedModels = Object.fromEntries(
        Object.entries(fetchedData).map(([upc, maybeDTO]) => [
          upc,
          maybeDTO ? InventoryModel.from(maybeDTO) : maybeDTO
        ])
      );

      return {
        ...cachedModels,
        ...fetchedModels
      };
    }

    const upcToInventoryRecordPromise = this.client
      .get<UPCToInventoryRecord>(`/${nonCachedUPCs.join(',')}`)
      .then(({ data }) => data);

    // Add promises to cache for non-cached UPCs to prevent duplicate requests.
    for (const upc of nonCachedUPCs) {
      this.inventoryCache.add(
        upc,
        upcToInventoryRecordPromise.then(({ [upc]: maybeDTO }) => {
          if (!maybeDTO) return maybeDTO;
          return InventoryModel.from(maybeDTO);
        })
      );
    }

    const upcToInventoryRecord = await upcToInventoryRecordPromise;

    const fetchedModels = Object.fromEntries(
      Object.entries(upcToInventoryRecord).map(([upc, dto]) => {
        if (!dto) return [upc, dto];
        const model = InventoryModel.from(dto);
        return [upc, model];
      })
    );

    return {
      ...cachedModels,
      ...fetchedModels
    };
  }

  /**
   * Imports inventory data to the stale inventory cache. Used to share
   * server-fetched inventory data with the client-side via page props.
   *
   * @param staleInventory - The stale inventory data to import. Must be a
   * record of product UPCs as keys and {@link IInventory} DTOs as values.
   */
  public importStaleInventoryToCache(
    staleInventory: UPCToInventoryRecord
  ): void {
    for (const upc of Object.keys(staleInventory)) {
      const dto = maybe(staleInventory[upc]);
      if (!dto) continue;

      this.staleInventoryCache.add(upc, InventoryModel.from(dto));
    }
  }

  /**
   * Synchronously retrieves _stale_ inventory data for a product.
   *
   * @param upc - The product's {@link IProduct.upc UPC}.
   * @returns An {@link InventoryModel}.
   */
  public getStaleProductInventory(upc: string): InventoryModel | null {
    if (this.staleInventoryCache.has(upc)) {
      return this.staleInventoryCache.get(upc);
    }

    return null;
  }

  /**
   * Synchronously retrieves _stale_ inventory data for multiple products.
   *
   * @param upcList - The {@link IProduct.upc UPCs} of the products to fetch inventory for.
   *
   * @returns A map with the passed UPCs as keys and their respective inventory
   * records (in {@link InventoryModel} form) as values.
   */
  public bulkGetStaleProductInventory(
    ...upcList: Array<string>
  ): UPCToInventoryModelRecord {
    const staleInventoryMap: UPCToInventoryModelRecord = {};

    for (const upc of upcList) {
      const staleInventoryOrNull = this.getStaleProductInventory(upc);
      staleInventoryMap[upc] = staleInventoryOrNull;
    }

    return staleInventoryMap;
  }

  /**
   * Given an {@link ProductAvailabilityState availability state value}, retrieves the internationalized
   * message that describes it in a UI-friendly way.
   *
   * @param availabilityState - Availability state.
   * @returns An string with the description message.
   *
   * @throws An {@link InvalidArgumentError} if the provided availability state is invalid.
   */
  // TODO: consider removing as this is no longer used in practice
  public getAvailabilityIntlMessage(
    availabilityState: Exclude<
      ProductAvailabilityState,
      ProductAvailabilityState.Unknown | ProductAvailabilityState.Pending
    >
  ): string {
    const inventoryConfig = ConfigurationService.getConfig('inventory');

    const allowBackorder = inventoryConfig.getSetting('allowBackorder').value;
    const allowPreorder = inventoryConfig.getSetting('allowPreorder').value;

    switch (availabilityState) {
      case ProductAvailabilityState.InStock:
        return msg(product_details_inStock);

      case ProductAvailabilityState.Backorder:
        return allowBackorder
          ? msg(product_details_backorder)
          : msg(product_details_outOfStock);

      case ProductAvailabilityState.Preorder:
        return allowPreorder
          ? msg(product_details_preorder)
          : msg(product_details_outOfStock);

      case ProductAvailabilityState.OutOfStock:
        return msg(product_details_outOfStock);

      case ProductAvailabilityState.Unavailable:
        return msg(product_details_unavailable);

      default:
        return exhaustiveGuard(
          availabilityState,
          new InvalidArgumentError(
            `Cannot get Availability Text for invalid state "${availabilityState}".`
          )
        );
    }
  }
}

export default InventoryService.withMock(
  new InventoryServiceMock(InventoryService)
);
