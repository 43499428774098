import { DTO } from '@/type-utils';
import { ReviewsService } from '../../../isomorphic/ReviewsService';
import Model from '../../Model';
import { IProductQuestionAnswer } from './IProductQuestionAnswer';

/**
 * Represents a single answer to a question.
 */
export default class ProductQuestionAnswerModel
  extends Model<DTO<IProductQuestionAnswer>>
  implements IProductQuestionAnswer
{
  /** @inheritdoc */
  public readonly id: number;

  /** @inheritdoc */
  public readonly content: string;

  /** @inheritdoc */
  public readonly isOwner: boolean;

  /** @inheritdoc */
  public readonly author: string;

  /** @inheritdoc */
  public readonly createdAt: Date;

  /** @inheritdoc */
  private _votesUp: number;

  /** @inheritdoc */
  private _votesDown: number;

  /** @inheritdoc */
  public get votesUp(): number {
    return this._votesUp;
  }

  /** @inheritdoc */
  public set votesUp(n: number) {
    this._votesUp = n;
  }

  /** @inheritdoc */
  public get votesDown(): number {
    return this._votesDown;
  }

  /** @inheritdoc */
  public set votesDown(n: number) {
    this._votesDown = n;
  }

  /**
   * @inheritdoc
   *
   * @param answer - `DTO` of an answer.
   */
  public constructor(answer: DTO<IProductQuestionAnswer>) {
    super(answer);

    this.id = answer.id;
    this.content = answer.content;
    this.isOwner = answer.isOwner;
    this.author = answer.author;
    this._votesUp = answer.votesUp;
    this._votesDown = answer.votesDown;
    this.createdAt = new Date(answer.createdAt);
  }

  /**
   * Upvote the answer.
   * Returns the number of new upvotes, however only by incrementing
   * the local number. If there is another concurrent user voting
   * on this answer, the count will be out of sync until a refresh occurs.
   *
   * @returns New inaccurate number of upvotes.
   */
  public async upvote(): Promise<number> {
    await ReviewsService.voteOnAnswer(this.id, 'up');
    this.votesUp += 1;
    return this.votesUp;
  }

  /**
   * Undo an upvote on the answer.
   * See {@link upvote} for details on voting inaccuracy.
   *
   * @returns New inaccurate number of upvotes.
   */
  public async undoUpvote(): Promise<number> {
    await ReviewsService.voteOnAnswer(this.id, 'up', true);
    this.votesUp -= 1;
    return this.votesUp;
  }

  /**
   * Downvote the answer.
   * See {@link upvote} for details on voting inaccuracy.
   *
   * @returns New inaccurate number of downvotes.
   */
  public async downvote(): Promise<number> {
    await ReviewsService.voteOnAnswer(this.id, 'down');
    this.votesDown += 1;
    return this.votesDown;
  }

  /**
   * Undo a downvote on the answer.
   * See {@link upvote} for details on voting inaccuracy.
   *
   * @returns New inaccurate number of downvotes.
   */
  public async undoDownvote(): Promise<number> {
    await ReviewsService.voteOnAnswer(this.id, 'down', true);
    this.votesDown -= 1;
    return this.votesDown;
  }

  /** @inheritdoc */
  public toDTO(): DTO<IProductQuestionAnswer> {
    return {
      id: this.id,
      content: this.content,
      isOwner: this.isOwner,
      author: this.author,
      votesUp: this.votesUp,
      votesDown: this.votesDown,
      createdAt: this.createdAt.toISOString()
    };
  }
}
