import MOCK_ACCOUNT from '../../Account/mocks/MOCK_ACCOUNT';
import type { IAuthenticatedUser } from '../AuthenticatedUser';
import UserType from '../UserType';

export default {
  type: UserType.Authenticated,
  accountID: 'HKDEAQVCBPLYWM2023',
  uuid: 'dead9a63-0573-4712-93df-b9545627420d',
  email: 'user.mcuserface@deckers.com',
  tokens: {
    accessToken:
      'eyJraWQiOiJaNnFmeFRuaStNNkJ6c1dka0lPTlF5eWVkU3ZjSzAyNzUrbGpZWTgya0RRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkZWFkOWE2My0wNTczLTQ3MTItOTNkZi1iOTU0NTYyNzQyMGQiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl8wTTViY1ZVcjciLCJjbGllbnRfaWQiOiI2MjQ3MmZ1MzBuZG8xMHVjZnBpbmJibHZlOSIsIm9yaWdpbl9qdGkiOiJkOGQ4ZTEwNC1kY2NkLTQwNzctODM5Mi0wZmZhMGI5ZDc2YTYiLCJldmVudF9pZCI6ImJhYmFkNDI5LTFiNGMtNDExZC05M2Q5LThkNjNkYjNhMWM1NSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2ODI1NjAxMDMsImV4cCI6MTY4MjY0NjUwMywiaWF0IjoxNjgyNTYwMTAzLCJqdGkiOiJiM2JjZTY1NS0xNGRmLTRlMzItYmY5Ni1kZmRiOGI1MWE1YzIiLCJ1c2VybmFtZSI6ImRlYWQ5YTYzLTA1NzMtNDcxMi05M2RmLWI5NTQ1NjI3NDIwZCJ9.Ks7K_zLX_t2ID2I6T8hjeQpBMFg4ULneRmZcHl-Q1XB7A_eOg-ya4BqVx_a1phOZcARv6jdWAKAgUJ8JzryHd6EGpOS5PuLerBZRXn-LQbhYhHqSEtw_7tfmxBrDK6vPbZYZVdgLTXuEwhWiuykNAeODtYHe18qGcptA1cO-duRDfS9OdmZKEwlHSLkbNY_afQmhpB-05-inu2OtZY446AggeJTK1jJrRBJDa10-1peKEV5BEisuJg4O4aGXJoObwbyXDMammH9zkip8TyfdmVx4Pgwf658BJmtNbGt2-V2rCItGWDZa1oWw-oCwKuNsqa0k1r3l1moAgha2aSFliA',
    idToken:
      'eyJraWQiOiJ4UUdISHJ1c284RFlHR1h0VjNEWmFpb3REYVRGZUdkV2xLaU5XMERZcE9VPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkZWFkOWE2My0wNTczLTQ3MTItOTNkZi1iOTU0NTYyNzQyMGQiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfME01YmNWVXI3IiwiY29nbml0bzp1c2VybmFtZSI6ImRlYWQ5YTYzLTA1NzMtNDcxMi05M2RmLWI5NTQ1NjI3NDIwZCIsIm9yaWdpbl9qdGkiOiJkOGQ4ZTEwNC1kY2NkLTQwNzctODM5Mi0wZmZhMGI5ZDc2YTYiLCJhdWQiOiI2MjQ3MmZ1MzBuZG8xMHVjZnBpbmJibHZlOSIsImV2ZW50X2lkIjoiYmFiYWQ0MjktMWI0Yy00MTFkLTkzZDktOGQ2M2RiM2ExYzU1IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2ODI1NjAxMDMsIm5hbWUiOiJDYXJsb3MgVmVsw6FzcXVleiIsImV4cCI6MTY4MjY0NjUwMywiaWF0IjoxNjgyNTYwMTAzLCJqdGkiOiI4ZWEyOTA2MS04YzAyLTRhMDMtOWU4NS00ZDE2ODk2N2RmZjUiLCJlbWFpbCI6ImNhcmxvcy52ZWxhc3F1ZXpAZGVja2Vycy5jb20ifQ.NN_KCdPUKYtkbCni7siqoztlITGUCCfz5aiyGyUkPn6mMBgky9PsU0cnHTwbIYyzYgUD5Cu84Xz6YQiv0UNuq8lVhoRN1D55LQcTKN69-aY-e5lA1JLmFk3lGyftZG9oXperMEzQEPFHyaSr2ekN4FcoC_PLyjiKDFUJkqCF-Wd9Sq98WE7e44U4SqaZMrRTbuiF6USGkFSzKAdtXUF_qFeUN-VhVBDf1UCEPf_IupqzDM85Z2yPKlmxiIfpkfP8v5NrLpGX7DNAg_QM0cDMOaEwZtkHE2qR2_DmJ_Dv2ZihZvkp8NTIjsh6mS0oRlyfwWZBAX48LB44EqdilwdGRQ',
    refreshToken:
      'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.VvrWIjnyuw97IQ1sbcsLXzjyYnGnh6GvkZgpHVEd10NWXi1P_ULFLhDLdmWUWBTfDfZRXN1Kw8O-ZEcRCjazZv5pqjeWC4kx5066HHwPehQSSCYiHGv_WiKa3iqhhrvKSRHfKmw5e9HAQAMhWFksVn_DzIebgd-SONMQlN_XBGKFRS-PNi0k8U_vRM0qHtl1YBbz4MyKPA6idEfKnbL5pm27SbDWCPVJcTVYDRov7dXd8Zf-jd_mACWT0-Hy3j1sf7jBru15KCHvwb3majGjEcLS386uKS7fb9I7WjijQ-IAI3dmUEYvs8eayTK-ipKe6BvVlRJkfNBSePqoloaS_g.rTfuNUWrowjejmLm.YfuCr0JsYf0RTCqViZxPGyRK6pHR76eTZG27qnUv-apajwtO9qU0SADlw_YskgccmB37-swZt6eqYP-zOqiHG18F0_VgeujJYjAAnHQsPI3ysWxcA6CcMnw9venG1tzDOTCU6Exzu8a_ikNEyaA_GW6b1lO26XpuMOn3-u-Hub7ddiz-PwmLwCjg2mxIUOLaM9kK-Vnl4gZEPKYhF3Vsq53lhBAu1HBDvZfAcrV67iOxeyv2b-9K5q3eRq5QCyCbcjiVewQYMxajgoXKiMS7mUUhafXEJWLPxDOofc66M20TzFEeYSlIEr7g1PQ6V_2y2nOoCM44PzJUuALwUV8do8OchU3LAlCbOP57qinvz9pY4GbjAwgrvDcOJBvmpFGKXIU3S9pyA403X80EoZCdLH8SYT5ZQ5YAvfWb1G3yeMwRGC9h-JE_BWqZE97Hc-09LDQh7GBGb1X1ZOKM-F2lWnNlID7frBtVTiKFXMYk_vKxS2TC2CTmiMKX-Ikj5RWHgoIdXAQeB7bApu4DBJu6LMqZ0JH4gNwvuFLeV2NqrKUnQesWwPzkkw3x_sq6kV2Z-4bNQ8aKtpsBAsVV0zEhVSmBVkqIc4R1DYeZbimPtrwUak-lzV2Fi-meyXyBvVBj93bgOZhgzY6xOZrm63TtL_QFfa9WvWavi-tF42Hs3KDpQzeMQKpKWj_nOjBwGJZNn3mOLJ0bXqrymdyWOks8uvJiNcGx6RYGpIfI81NwcuuJ0ObyoKJvbeRY9JJWPVY8dzi1dqwsIak9VKXBldVcPRn9OWa6nDQYfCGVHcO6SgMJR7M0jGpSY5BiUWM3-ixqfTLMdw1sKzUBnoY_l0bk8P_4sbevPdl9jcqm3_cnuCFJ4-5mRipRcb3drgn_smo_ESG_ImjMiIp_znMHhdUNcIyFOlSd0v9DqpU4VN-W9cF_iRo8L9HDaMr5Y_bjERFZCoePnUAIFgsjIkQjakLwI5HzmX-rOfCzp-LND3EDzkf9o7M97w5JJcOPtB_VBgXMcRtScF8fh9ocQq5Sk96dhPSE9z0CXLYmB_yrabcFP3F0gQ98xx-UuyQG0L4A7ipq5kOv0OK0wHLRt9HVVWSjh9ERTuiB-jBzYF0G9u9pOl_qpPKMywK1VSjOZYCYzrWsm4lK28tIum92ElIW8bn_XcwYN3vs9Hl50iDwBbRRbfZCy33iobhAvmG6JFJQYr0E0qy9PkdAvsBtAIaKuM0vyl7Y8V5neAq3yj43QcJ1d4SnmeaJDy90SO9uGzJvpNV1H9pGL5KnwLly7uomrcX04ot6yiLe0UQZ3FeRyBm4r0AcHNPu5FyppQzalVPpKQ.A9uSFVebkYZZY-t971kd_Q'
  },
  account: MOCK_ACCOUNT
} as IAuthenticatedUser;
